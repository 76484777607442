import { Injectable } from '@angular/core';
import { ExternalCaller } from '../../models/client';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Injectable({
  providedIn: 'root'
})
export class AppModeService {

  public externalCaller: ExternalCaller = ExternalCaller.None;

  constructor(
    private inAppBrowser: InAppBrowser
  ) { }

  public isCalledFromExternal(externalCaller: ExternalCaller) {
    this.externalCaller = externalCaller;
  }

  public callExternal(externalCaller: ExternalCaller) {
    switch(externalCaller) {
        case ExternalCaller.ShopIQ:
            this.inAppBrowser.create('aioremoteneo://', '_system');
            return;
        
        default:
            return;
    }
  }

}
