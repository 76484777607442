import { Injectable } from '@angular/core';
import { AppNotification, ChatNotification, TaskNotification } from "models/client";
import { Observable, of, Subject, from, race } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { DeviceActionNotification, PingDeviceActionNotification } from '../../../models/client/notification/device-action-notification';
import { DeviceActionTypes, NotificationTypes } from '../../../models/server/Enums';
import { PlatformService } from '../../ui/device/platform.service';
import { PushService } from './push-service';
import { InfoNotification } from '../../../models/client/notification/info-notification';
import {MissionNotification} from '../../../models/client/notification/mission-notification';

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class NativePushService implements PushService {
  private readonly pushOptions: PhonegapPluginPush.InitOptions = {
    android: {
      sound: true
    },
    ios: {
      alert: true,
      badge: true,
      sound: true
    },
    windows: {}
  };

  private pushObject: PhonegapPluginPush.PushNotification = null;
  private onNotificationSubject: Subject<AppNotification> = new Subject();
  private onErrorSubject: Subject<Error> = new Subject();

  constructor(
    private platformService: PlatformService
  ) { }

  public onNotification(): Observable<AppNotification> {
    return this.onNotificationSubject.asObservable();
  }
  public onError(): Observable<Error> {
    return this.onErrorSubject.asObservable();
  }
  public async clearAll() {
    if (!this.pushObject)
      return;

    try {
      await this.pushObject.setApplicationIconBadgeNumber(() => { }, () => { }, 1);
      await this.pushObject.setApplicationIconBadgeNumber(() => { }, () => { }, 0);
      await this.pushObject.clearAllNotifications(() => { }, () => { });
    } catch (error) {
      console.error(error);
    }
  }

  public async register(): Promise<string> {

    (<any>document).push = this;

    if (await this.isPresent() == false) {
      console.error("PUSH not present");
      return null;
    }

    if (this.platformService.isIos()) {
      // the following direct call is not working and loads for ever: await this.diagnostic.requestRemoteNotificationsAuthorization(['alert'], true);

      const diagnosticPlugin: any = (<any>cordova.plugins).diagnostic;
      await new Promise<string>((resolve) =>
        new Promise<string>((resolve, reject) => diagnosticPlugin.requestRemoteNotificationsAuthorization(resolve, reject, ['alert', 'sound'], true))
          .then((status) => resolve(status))
          .catch((error) => this.resolveFalseError(error, resolve))
      );
    }

    const pushObject = await this.init();

    if (pushObject != null) {
      const pushRegistrationSuccessEvent = from(new Promise<PhonegapPluginPush.RegistrationEventResponse>(r => pushObject.on('registration', r)));
      const pushRegistrationErrorEvent = from(new Promise<Error>(r => pushObject.on('error', (e) => r(e))));

      const pushRegistrationEvent = await race(pushRegistrationSuccessEvent, pushRegistrationErrorEvent).pipe(
        timeout(15 * 1000),
        catchError(error => {
          console.error(error);
          return of(null);
        })
      ).toPromise();

      if (pushRegistrationEvent != null && (<PhonegapPluginPush.RegistrationEventResponse>pushRegistrationEvent) != null) {
        const result = <PhonegapPluginPush.RegistrationEventResponse>pushRegistrationEvent;
        this.pushObject = pushObject;
        this.registerEvents();

        console.log("PUSH registered:", result);
        return result.registrationId;
      }
    }

    return null;
  }

  public async isAvailable(): Promise<boolean> {

    return new Promise<boolean>((resolve) =>
      PushNotification.hasPermission(
        (push) => resolve(push.isEnabled),
        () => resolve(false)
      )
    );
  }

  public async isPresent(): Promise<boolean> {
    return of(this.platformService.isNative()).toPromise();
  }

  private registerEvents() {
    if (this.pushObject == null) {
      return;
    }

    this.pushObject.on('notification', pushNotification => {
      try {
        const appNotification = this.createAppNotification(pushNotification);
        console.log("PUSH: ", pushNotification, appNotification);

        if(appNotification) {
          this.onNotificationSubject.next(appNotification);
        }
      } catch(error) {
        console.error("Error while creating app notification", pushNotification, error);
      }
      this.pushObject.finish();
    });

    this.pushObject.on('error', error => {
      console.error("PUSH: ", error)
      this.onErrorSubject.next(error)
    });
  }

  private createAppNotification(pushNotification: PhonegapPluginPush.NotificationEventResponse): AppNotification {
    const type = <NotificationTypes>+pushNotification.additionalData?.type;
    const title = pushNotification.title;
    const msg = pushNotification.message;
    const foreground = pushNotification.additionalData?.foreground === true;

    switch (type) {
      case NotificationTypes.TaskState:
        return new TaskNotification(title, msg, foreground, +pushNotification.additionalData.taskId);

      case NotificationTypes.ChatMessage:
        return new ChatNotification(title, msg, foreground, +pushNotification.additionalData.chatId);

      case NotificationTypes.PushedInfos:
        return new InfoNotification(title, msg, foreground, +pushNotification.additionalData.infoId);

      case NotificationTypes.PushedStartedMission:
        return new MissionNotification(title, msg, foreground, +pushNotification.additionalData.missionId);

      case NotificationTypes.DeviceAction:
        if (+pushNotification.additionalData.action == DeviceActionTypes.Ping) {
          return new PingDeviceActionNotification(title, msg, foreground, +pushNotification.additionalData.action, +pushNotification.additionalData.pingId);
        } else {
          return new DeviceActionNotification(title, msg, foreground, +pushNotification.additionalData.action);
        }

      default:
        return null;
    }
  }


  private async init(): Promise<PhonegapPluginPush.PushNotification> {
    return of(PushNotification.init(this.pushOptions)).pipe(
      catchError(error => {
        console.error(error);
        return of(null);
      })
    ).toPromise();
  }

  private resolveFalseError(error, resolve) {
    console.error(error);
    resolve(false);
  }
}
