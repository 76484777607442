import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FailedChunkErrorHandler implements ErrorHandler {

    constructor(
        protected translate: TranslateService
    ) { }

    handleError(error: any): void {
        if (error && error.message && error.message.indexOf("ChunkLoadError") > -1) {
            console.error("Updated files detected!");

            this.translate.get('ALERT.UPDATEDETECTED').subscribe(msg => {
                if(confirm(msg)) {
                    window.location.reload();
                }
            });
        }
    }
}