//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum Seeds { 
	Base = 0, 
	Semco = 1
}
export enum SeedSequence { 
	Init = 0, 
	InitTechAdmin = 1, 
	InitCorporateIdentitySetting = 2, 
	AddGeneralSettingDefaultLanguage = 3, 
	InitTaskJobStatusGenre = 4, 
	MigrateOldAppUser = 5, 
	InitDashboardRole = 6, 
	InitChatManagerRole = 7, 
	InitInfoManagerRole = 8
}
