//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { IStereotypedDto } from './Stereotyping/Stereotypes';
import { TicketInMissionDto } from './Tickets';
import { ContactSimpleDto } from './MasterData/Contact';
import { TicketSimpleListDto } from './Tickets';
import { StereotypeSimpleListDto } from './Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from './Stereotyping/FilledCustomValues';
import { ValidationDto } from '../Validations';
import { FilledCustomValueDto } from './Stereotyping/FilledCustomValues';
import { LinkDto } from './Links';
import { AttachmentForTechDto } from './Attachments';
import {ResourceSimpleDto, ResourceSimpleListDto} from './Resources';

export class ChangeStateOfMissionDto
{
	@Column() public missionId: number;
	@Column() public wantedState: number;
}
export class MissionCompleteDto implements IIdentityData
{
	@Column() @Filterable() public missionId: number;
	@Column() @Filterable() public title: string;
	@Column(true) @Filterable() public description: string;
	@Column() @Filterable() @DateColumn() public actualStart: string;
	@Column() @Filterable() @DateColumn() public actualEnd: string;
	@Column() @Filterable() @DateColumn() public plannedStart: string;
	@Column() @Filterable() @DateColumn() public plannedEnd: string;
	@Column() @Filterable() public state: number;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public solutionContact: ContactSimpleDto;
	@Column() public sourceTicket: TicketSimpleListDto;
	@Column() @Filterable() public solutionId: number;
	@Column() @Filterable() public ticketId: number;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
}
export class MissionDto extends ValidationDto implements IIdentityData, IStereotypedDto
{
	@Column() public missionId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public offsetByTemplate: string;
	@Column() @DateColumn() public actualStart: string;
	@Column() @DateColumn() public actualEnd: string;
	@Column() @DateColumn() public plannedEnd: string;
	@Column() @DateColumn() public plannedStart: string;
	@Column() @DateColumn() public lastEmailSend: string;
	@Column() public state: number;
	@Column() public type: number;
	@Column() public solutionContact: ContactSimpleDto;
	@Column() public solutionId: number;
	@Column() public tenantId: number;
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public rowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
	@Column() public links: LinkDto[];
	@Column() public attachments: AttachmentForTechDto[];
	@Column() public reportId: number;
	@Column() public resource: ResourceSimpleListDto;
}
export class MissionSimpleDto implements IIdentityData
{
	@Column() @Filterable() public missionId: number;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tenantId: number;
}
export class MissionSimpleListDto implements IIdentityData
{
	@Column() @Filterable() public missionId: number;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tenantId: number;
}
export class MissionByTicketDto extends MissionDto implements IIdentityData, IStereotypedDto
{
	@Column() public ticket: TicketInMissionDto;
}
