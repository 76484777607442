//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum SemcoStereotypes { 
	Wartung = 0, 
	Hygiene = 1, 
	Stoerung = 2, 
	Lieferadresse = 3, 
	Rechnungsadresse = 4, 
	Firma = 5, 
	Lieferant = 6, 
	Hersteller = 7, 
	Filiale = 8, 
	Verwaltung = 9, 
	Mitarbeiter = 10, 
	Techniker = 11, 
	SemcoAdminstrator = 12
}
