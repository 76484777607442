import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Identity } from '../../../models/client';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { InfoListDto } from '../../../models/server/DataTransferObject/Objects/Infos';
import { FilterOperations, FilterOperators, FilterTypes, Orders } from '../../../models/server/Enums';
import { PageableService } from '../base/pageable.service';
import { HttpApiBaseService } from '../http/http-api-base.service';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';

const SERVICE_ENDPOINT = 'v2/info';

@Injectable({
  providedIn: 'root'
})
export class InfoService extends PageableService<InfoListDto> {
  private unreadFiltered = false;
  protected search: string = null;

  constructor(
    protected http: HttpLocationApiService,
  ) {
    super(http, SERVICE_ENDPOINT);
  }

  public setSearch(search: string) {
    if (search && search.trim().length > 0) {
      this.endpoint = `${SERVICE_ENDPOINT}/search`;
      this.search = search.trim();
    } else {
      this.endpoint = SERVICE_ENDPOINT;
      this.search = null;
    }
  }

  async markAsRead(infoId: number, identity: Identity): Promise<boolean> {
    return this.http.addIdentity(identity).post(`${SERVICE_ENDPOINT}/${infoId}/contact/read`, null).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    ).toPromise();
  }

  protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null): HttpParams {
    let params = super.createHttpParams(pageNumber, pageSize, sortingParams);

    if (this.unreadFiltered) {
      params = params.set('operation', FilterOperations.Exclude.toString());
    }
    
    if (this.search && this.search.trim().length > 0) {
      params = params.set('q', this.search);
    }

    return params
      .set('cols', 'Content')
      .set('sortField', 'DateTime')
      .set('sort', Orders.Descending.toString());
  }

  public async filterUnread(identity: Identity, filtered: boolean) {
    this.unreadFiltered = filtered;
    this.http = this.http.addIdentity(identity) as HttpApiBaseService as HttpLocationApiService;

    const filters = [{
      property: 'requestRead',
      type: FilterTypes.DataTransferObject,
      operator: FilterOperators.Equal,
      value: 'true'
    }];

    this.endpoint = `v2/me/info`;
    this.addFilters(...filters);
  }
}
