import { Component, OnInit } from '@angular/core';
import { TaskJobStateReasonSetDto } from '../../models/server/DataTransferObject/Objects/TaskJobs';
import { ModalService } from '../../services/ui/modal.service';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-task-cancel',
  templateUrl: './task-cancel.page.html',
  styleUrls: ['./task-cancel.page.scss'],
})
export class TaskCancelPage implements OnInit {

  public data: TaskJobStateReasonSetDto = new TaskJobStateReasonSetDto();
  public reasonId: number = null;

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
  ) { }

  ngOnInit() {
    this.data = this.navParams.get('set') || new TaskJobStateReasonSetDto();
    this.data.reasons = this.data.reasons || [];

    this.data.reasons = this.data.reasons.sort(function (a, b) {
      if (a.content < b.content) { return -1; }
      if (a.content > b.content) { return 1; }
      return 0;
    });
  }

  clear() {
    this.data = new TaskJobStateReasonSetDto();
  }

  exit() {
    this.clear();
    this.modalService.dismissModal(null);
  }

  done() {
    this.modalService.dismissModal(this.reasonId);
  }
}
