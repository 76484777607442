//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { ValidationDto } from '../../Validations';
import { StateSimpleDto } from '../StateMachines';
import { IIdentityData } from '../../Objects';
import { EscalationRulesInEventListDto } from './Escalations';
import { StateSimpleListDto } from '../StateMachines';

export class FollowUpTicketDto extends ValidationDto
{
	@Column() public state: StateSimpleDto;
	@Column() @DateColumn() public triggersAt: string;
}
export class TicketEventCompleteDto implements IIdentityData
{
	@Column() @Filterable() public ticketEventId: number;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public ticketId: number;
	@Column() public tenantId: number;
	@Column() @DateColumn() public triggersAt: string;
	@Column() public ruleId: number;
	@Column() public ruleToExpress: EscalationRulesInEventListDto;
	@Column() public followUpState: StateSimpleListDto;
}
