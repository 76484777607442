//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { FileDto } from './Files';
import { KnowledgeArticleDto } from './KnowledgeArticles';
import { ValidationDto } from '../Validations';

export class AttachmentForTechDto extends ValidationDto implements IIdentityData
{
	@Column() public attachmentId: number;
	@Column() public tenantId: number;
	@Column() public type: number;
}

export class OrgaAttachmentDto extends ValidationDto implements IIdentityData
{
	@Column() public attachmentId: number;
	@Column() public tenantId: number;
	@Column() public type: number;
}
export class OrgaAttachmentListDto
{
	@Column() public attachmentId: number;
	@Column() public tenantId: number;
	@Column() public type: number;
	@Column() public name: string;
	@Column() public referenceId: number;
	@Column() public url: string;
	@Column() public link: string;
	@Column() public path: string;
}
export class FileAttachmentForTechDto extends AttachmentForTechDto implements IIdentityData
{
	@Column() public file: FileDto;
}
export class FileOrgaAttachmentDto extends OrgaAttachmentDto implements IIdentityData
{
	@Column() public fileId: number;
	@Column() public file: FileDto;
}
export class FilledFormOrgaAttachmentDto extends OrgaAttachmentDto implements IIdentityData
{
	@Column() public filledFormId: number;
}
export class FolderOrgaAttachmentDto extends OrgaAttachmentDto implements IIdentityData
{
	@Column() public path: string;
}
export class KnowledgeArticleOrgaAttachmentDto extends OrgaAttachmentDto implements IIdentityData
{
	@Column() public knowledgeArticleId: number;
	@Column() public knowledgeArticle: KnowledgeArticleDto;
}
export class LinkOrgaAttachmentDto extends OrgaAttachmentDto implements IIdentityData
{
	@Column() public link: string;
	@Column() public name: string;
}
