import { Injectable } from '@angular/core';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { NotificationService } from '../../notification/notification.service';

declare var cordova: any;

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    private diagnostic: Diagnostic,
    private appVersion: AppVersion,
    private notificationService: NotificationService
  ) { }

  public async isPushPresent(): Promise<boolean> {
    return this.notificationService.isPresent();
  }

  public async isPushAvailable(): Promise<boolean> {
    return this.notificationService.isAvailable();
  }

  public async requestPushAuthorization(): Promise<string> {
    return await this.notificationService.register();
  }

  public async getAppVersion(): Promise<string> {
    var nativeVersion = await new Promise<string>((resolve) => this.appVersion.getVersionNumber()
      .then((version) => resolve(version))
      .catch((error) => this.resolveNullError(error, resolve))
    );

    if (nativeVersion)
      return nativeVersion;

    var htmlVersion = document.documentElement.getAttribute("data-appversion");

    if (htmlVersion && htmlVersion != "%%VERSION%%")
      return htmlVersion;

    return null;
  }

  public async isCameraPresent(): Promise<boolean> {
    return new Promise<boolean>((resolve) => this.diagnostic.isCameraPresent()
      .then(isPresent => resolve(isPresent))
      .catch((error) => this.resolveFalseError(error, resolve))
    );
  }

  public async isCameraAuthorized(): Promise<boolean> {
    return new Promise<boolean>((resolve) => this.diagnostic.isCameraAuthorized()
      .then(isAuthorized => resolve(isAuthorized))
      .catch((error) => this.resolveFalseError(error, resolve))
    );
  }

  public async isCameraAvailable(): Promise<boolean> {
    return new Promise<boolean>((resolve) => this.diagnostic.isCameraAvailable()
      .then(isAvailable => resolve(isAvailable))
      .catch((error) => this.resolveFalseError(error, resolve))
    );
  }

  public async requestCameraAuthorization(): Promise<boolean> {
    return new Promise<boolean>((resolve) => this.diagnostic.requestCameraAuthorization()
      .then(() => resolve(this.isCameraAuthorized()))
      .catch((error) => this.resolveFalseError(error, resolve))
    );
  }

  public getDeviceName(): string {
    try {
      return cordova?.plugins?.deviceName?.name?.trim();
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private resolveFalseError(error, resolve) {
    console.error(error);
    resolve(false);
  }
  private resolveNullError(error, resolve) {
    console.error(error);
    resolve(null);
  }


}