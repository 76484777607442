import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSearchbar, NavParams} from '@ionic/angular';
import { Identity } from '../../models/client';
import { RegisterDeviceByLocationDto } from '../../models/server/DataTransferObject/Objects/Devices';
import { LocationListDto } from '../../models/server/DataTransferObject/Objects/MasterData/Locations';
import { DeviceRegisterService } from '../../services/api/auth/device-register.service';
import { ModalService } from '../../services/ui/modal.service';
import { MeDto } from '../../models/server/index-objects';
import { DeviceService } from '../../services/ui/device/device.service';

@Component({
  selector: 'app-device-register-manual',
  templateUrl: './device-register-manual.page.html',
  styleUrls: ['./device-register-manual.page.scss'],
})
export class DeviceRegisterManualPage implements OnInit {

  public locations: LocationListDto[] = [];
  public data: RegisterDeviceByLocationDto = new RegisterDeviceByLocationDto();
  public isLoading = false;

  public currentSearch: string = null;

  public searchbar: IonSearchbar;
  @ViewChild('search', {static: false}) set searchbarViewChild(content: IonSearchbar) {
    this.searchbar = content;
  }

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
    private deviceRegisterService: DeviceRegisterService,
    private deviceService: DeviceService
  ) { }

  async ngOnInit() {
    await this.load();
    this.data.deviceName = this.deviceService.getDeviceName();
  }

  clear() {
    this.data = new RegisterDeviceByLocationDto();
  }

  async exit() {
    this.clear();
    await this.modalService.dismissModal();
  }

  public async onSearch($event) {
    const value = $event.detail.value;
    this.currentSearch = value;

    await this.load(value);
  }

  async load(filter: string = null) {
    const identity: Identity = this.navParams.get('identity');
    const userInfo: MeDto = this.navParams.get('userInfo');
    this.isLoading = true;

    try {
      this.locations = await this.deviceRegisterService.getLocations(identity, filter);
      const tenantIds = [...new Set(this.locations.map(x => x.tenantId))];

      if (tenantIds.length > 1) {
        this.locations.forEach(l => {
          (<any>l).tenantName = userInfo.tenants.find(t => t.tenantId === l.tenantId).name;
        });
      }
    } catch (error) {
      await (await this.modalService.createErrorAlertWithDetail(error)).present();
    } finally {
      this.isLoading = false;
    }
  }

  async done() {
    if (!this.data.locationId) {
      await (await this.modalService.createErrorAlert('LOGIN.NOLOCATION')).present();
      return;
    }

    if (!this.data.deviceName || this.data.deviceName.trim().length <= 0) {
      await (await this.modalService.createErrorAlert('LOGIN.NODEVICE')).present();
      return;
    }

    this.data.deviceName.trim();
    await this.modalService.dismissModal(this.data);
  }
}
