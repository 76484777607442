//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class UserSimpleDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public email: string;
}
export class UserSimpleListDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public email: string;
}
