import { EnviromentTyping } from "./enviroment-typings";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnviromentTyping = {
  production: false,
  apiUrl: { //dev2.nexnox.intern
    identity: "http://localhost:64961",
    orga: "http://localhost:64963",
    data: "http://localhost:64964",
    tech: "http://localhost:64962",
    pageDefaultSize: 25,
    defaultTimeoutSec: 30,
    tokenExpireOffsetSec: 59
  },
  lang: {
    defaultLanguage: "de-DE",
    useBrowserLanguage: false
  },
  idle: {
    autoRefreshOnViewAfter: 1000 * 60 * 2,
    idleTime: 60 * 5
  },
  alert: {
    batteryStatusAlertLevel: 20
  },
  image: {
    formImageResizeMaxSize: 1366
  },
  interceptor: {
    additionalHttpApiClientInterceptorsIdentifier: "additionalHttpApiClientInterceptors",
    apiPageDefaultSizeIdentifier: "apiPageDefaultSize",
    apiDefaultTimeoutSecIdentifier: "apiDefaultTimeoutSec"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
