import { AppNotification } from "./app-notification";

export class ChatNotification extends AppNotification {
    constructor(
        public title: string = null,
        public message: string = null,
        public foreground: boolean = false,
        public chatId: number = null,
        public unreadCount: number = null
    ) {
        super(title, message, foreground);
    }
}