//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';

export class TaskJobStateReasonSetSimpleDto
{
	@Column() public taskJobStateReasonSetId: number;
	@Column() public title: string;
}
