import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PushRegisterDto } from '../../models/server/DataTransferObject/Objects/Devices';
import { Platforms } from '../../models/server/Enums';
import { AuthenticationService, PushRegisterService } from '../api';
import { NotificationService } from '../notification/notification.service';
import { PlatformService } from './device/platform.service';

@Injectable({
  providedIn: 'root'
})
export class InitService {

  private isInitiliazed: boolean = false;
  private isInitiliazedSubject = new Subject();

  constructor(
    private pushRegisterService: PushRegisterService,
    private notificationService: NotificationService,
    private authService: AuthenticationService,
    private platformService: PlatformService
  ) { }

  public async init(forceInit: boolean = false) {
    if(this.isInitiliazed == true && forceInit == false) {
      return;
    }

    await this.authService.load();
    if(this.authService.isRegistered() !== true) {
      return;
    }

    await this.refreshToken();
    await this.initPushRegister();

    this.isInitiliazed = true;
    this.isInitiliazedSubject.next();
    this.isInitiliazedSubject.complete();
  }

  public async afterInit() {
    if(!this.isInitiliazed) {
      await this.isInitiliazedSubject.asObservable().pipe(take(1)).toPromise();
    }
  }

  private async refreshToken() {
    try {
      await this.authService.loginWithRefreshToken();
    } catch(err) {
      if(this.isHttpUnauthorizedError(err) == true) {
        this.authService.logout();
      }
    }
  }

  private async initPushRegister() {
    try {
      const pushDto = new PushRegisterDto();
      pushDto.token = await this.notificationService.register();
      pushDto.hardwareId = await this.authService.getDeviceId();

      if(this.platformService.isIos()) {
        pushDto.platform = Platforms.iOS;
      }
      
      if(this.platformService.isAndroid()) {
        pushDto.platform = Platforms.Android;
      }

      if(pushDto.token && pushDto.platform) {
        await this.pushRegisterService.registerPush(pushDto);
        await this.notificationService.clearAll();
      } else {
        //todo notify user?
        console.warn("push not available");
      }

    } catch(error) {
      console.error("initPushRegister: ", error);
    }
  }
  
  private isHttpError(error: any) : boolean {
    return error instanceof HttpErrorResponse;
  }
  private isHttpUnauthorizedError(error: any) : boolean {
    return this.isHttpError(error) == true && (<HttpErrorResponse>error).status === 401;
  }
}
