import { Directive, Host, Optional, Self } from '@angular/core';
import { NgxExtendedPdfViewerComponent, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Directive({
    selector: '[PdfViewerES5Fix]',
})
export class PdfViewerES5FixDirective {

    constructor(
        @Host() @Self() @Optional() private viewer: NgxExtendedPdfViewerComponent
    ) {
        console.log("patching viewer", viewer);

        (<any>viewer).needsES5 =  () => {
            const needsES5 = true;
            console.log("patched needsES5", needsES5);

            return needsES5;
        };
    }

    private async needsES5(): Promise<boolean> {
        const isIE = !!(<any>window).MSInputMethodContext && !!(<any>document).documentMode;
        const isEdge = /Edge\/\d./i.test(navigator.userAgent);
        const isIOs13OrBelow = this.iOSVersionRequiresES5();
        let needsES5 = typeof ReadableStream === 'undefined' || typeof Promise['allSettled'] === 'undefined';
        if (needsES5 || isIE || isEdge || isIOs13OrBelow) {
            return true;
        }
        return !await this.supportsOptionalChaining();
    }

    private iOSVersionRequiresES5(): boolean {
        const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (match !== undefined && match !== null) {
            return parseInt(match[1], 10) < 14;
        }

        return false;
    }

    private supportsOptionalChaining(): Promise<boolean> {
        return new Promise((resolve) => {
            const support = (<any>window).supportsOptionalChaining;
            support !== undefined ? resolve(support) : resolve(this.addScriptOpChainingSupport());
        });
    }


    private addScriptOpChainingSupport(): Promise<boolean> {
        return new Promise((resolve) => {
            const script = this.createScriptElement(`
              const optionalChaining = {
                support: true,
              };
              
              let x = false;
              x ||= true;
              window.supportsOptionalChaining = optionalChaining?.support;
            `);

            script.onload = () => {
                script.remove();
                resolve((<any>window).supportsOptionalChaining as boolean);
            };
            script.onerror = () => {
                script.remove();
                (<any>window).supportsOptionalChaining = false;
                resolve(false);
            };

            document.body.appendChild(script);
        });
    }

    private createScriptElement(code: string): HTMLScriptElement {
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.appendChild(document.createTextNode(code));

        return script;
    }

}