import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { ResourcePickerPage } from './resource-picker.page';
import { ResourceNodeComponent } from './resource-node/resource-node.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule
  ],
  declarations: [ResourcePickerPage, ResourceNodeComponent]
})
export class ResourcePickerPageModule {}
