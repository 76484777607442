import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { PermissionPackageDto } from "../../../models/server/DataTransferObject/Objects/Authentication";
import { HttpLoginApiService } from "../http/http-login-api/http-login-api.service";
import { EnviromentService } from "../../ui/enviroment.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionPackageService {
    protected refresher$ = new BehaviorSubject<void>(undefined);

    public permissions$: Observable<PermissionPackageDto[]> = this.refresher$
    .pipe(
        switchMap(_ => this.fetch()),
        shareReplay(1),
    );

    constructor(
        protected http: HttpLoginApiService,
        protected envService: EnviromentService
    ) {
        envService.env$.subscribe(_ => {
            this.refresher$.next();
        });
    }

    protected fetch(): Observable<PermissionPackageDto[]> {
        return this.http.get<PermissionPackageDto[]>(
            'permissionpackage'
        ).pipe(
            map(response => response.body)
        );
    }
}

