import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ResourceListDto } from "../../../models/server/index-objects";
import { ResourcePageService } from "../../../services/api/process/resource.service";

export class ResourceNode {
    constructor(
        public resource: ResourceListDto,
        public children: ResourceNode[],
        public childService: ResourcePageService,
        public isComplete: boolean,
        public isLoading: boolean,
        public depth: number
    ) { }
}

@Component({
    selector: 'app-resource-node',
    templateUrl: './resource-node.component.html',
    styleUrls: ['./resource-node.component.scss']
})
export class ResourceNodeComponent {
    @Input() public node: ResourceNode;
    @Output() public choose = new EventEmitter<ResourceNode>();
    @Output() public load = new EventEmitter<ResourceNode>();
    @Output() public more = new EventEmitter<ResourceNode>();

    public depth = [];

    ngOnInit() {
        this.depth = new Array(this.node.depth || 0);
    }

    itemClick(node: ResourceNode, e = null) {
        this.stop(e);
        this.choose.next(node);
    }

    itemLoad(node: ResourceNode, e = null) {
        this.stop(e);
        this.load.next(node);
    }

    itemMore(node: ResourceNode, e = null) {
        this.stop(e);
        this.more.next(node);
    }

    private stop(e: any) {
        if (e?.stopPropagation)
            e.stopPropagation();
    }
}
