//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../../Attributes';
import { IHtmlViewData } from '../../../Objects';
import { IIdentityData } from '../../../Objects';
import { FileDto } from '../../Files';
import { ValidationDto } from '../../../Validations';

export class FormDefaultDto extends ValidationDto implements IHtmlViewData, IIdentityData
{
	@Column() public fieldValueId: number;
	@Column() public type: number;
}
export class CheckboxFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: boolean;
}
export class DateFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() @DateColumn() public value: string;
}
export class DropDownFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
	@Column() public rating: number;
	@Column() public position: number;
	@Column() public isSelected: boolean;
}
export class MultilineFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class NumberFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
}
export class PictureFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
	@Column() public file: FileDto;
}
export class SignatureFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
	@Column() public file: FileDto;
}
export class TextFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class TimeFormDefaultDto extends FormDefaultDto implements IHtmlViewData, IIdentityData
{
	@Column() @DateColumn() public value: string;
}
