import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, from } from "rxjs";
import { distinct, map, mergeMap, shareReplay, switchMap, toArray, tap, first } from "rxjs/operators";
import { TokenHelper } from "../../../models/client";
import { PermissionAreaDto } from "../../../models/server/DataTransferObject/Objects/Authentication";
import { AppPermissions } from "../../../models/server/Enums/Permissions";
import { PermissionPackageService } from "./permission-package.service";

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    protected userPermissions: AppPermissions[] = [];
    protected permissionsRefresher$ = new BehaviorSubject<void>(undefined);

    public permissions$: Observable<AppPermissions[]> = this.permissionsRefresher$
        .pipe(
            switchMap(_ => of(this.userPermissions)),
            shareReplay(1),
        );

    constructor(
        protected permissionPackageService: PermissionPackageService
    ) { }

    public async checkUserPermission(needle: AppPermissions, token: string) {
        const decodedToken: any = TokenHelper.decodeToken(token);
        const areas: PermissionAreaDto[] = JSON.parse(decodedToken.permissions);
        const userPermissions = await this.ownedPermissions(areas);
        return userPermissions.some(p => p == needle);
    }

    public hasPermission(needle: AppPermissions): boolean {
        return this.userPermissions.some(p => p == needle);
    }

    public hasPermission$(needle: AppPermissions): Observable<boolean> {
        return this.permissions$.pipe(
            map(x => x.some(p => p == needle))
        );
    }

    public async loadUserPermissionsFromToken(token: string) {
        const decodedToken: any = TokenHelper.decodeToken(token);
        const areas: PermissionAreaDto[] = JSON.parse(decodedToken.permissions);

        await this.loadUserPermissions(areas);
    }

    public async loadUserPermissions(userAreas: PermissionAreaDto[]) {
        this.userPermissions = await this.ownedPermissions(userAreas);
        this.permissionsRefresher$.next();
    }

    private async ownedPermissions(userAreas: PermissionAreaDto[]): Promise<AppPermissions[]> {
        return this.permissionPackageService.permissions$.pipe(
            first(),
            map(x => x.filter(pp => userAreas.some(a => a.permissions.some(ap => ap == pp.package)))),
            mergeMap(x => x.map(y => y.permissions as AppPermissions[])),
            mergeMap(x => x),
            distinct(),
            toArray(),
        ).toPromise();
    }
}

