import {StereotypeSimpleDto} from '../../../server/DataTransferObject/Objects/Stereotyping/Stereotypes';
import {CategoryDto} from '../categories/category';
import {TaskJobListDto} from '../../../server/DataTransferObject/Objects/TaskJobs';
import {MissionDto, MissionSimpleListDto} from '../../../server/DataTransferObject/Objects/Missions';

export enum TimelineEnum {
    TaskJob,
    Mission
}

export class TimelineSettingsDto {
    constructor(
        public categories: CategoryDto[],
        public stereotypes: StereotypeSimpleDto[],
        public isActiveMissionOnly: boolean
    ) { }
}

export class AppTimelineListDto {
    constructor(
        public date: string,
        public type: TimelineEnum,

        public taskJob: TaskJobListDto = null,

        public mission: MissionDto = null,
    ) { }
}
