//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { AddressDto } from './Address';
import { ContactDto } from './Contact';
import { ContactGroupListSimpleDto } from './ContactGroups';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { ValidationDto } from '../../Validations';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';

export class CompanyDto extends ValidationDto implements IStereotypedDto, IIdentityData
{
	@Column() @Filterable() public companyId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public parentCompanyId: number;
	@Column() public parentCompany: CompanyDto;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class CompanyListDto implements IIdentityData
{
	@Column() @Filterable() public companyId: number;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public parentCompanyId: number;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
	@Column() public stereotypeRowVersion: number[];
	@Column() public rowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
}
export class CompanyListSimpleDto implements IIdentityData
{
	@Column() public companyId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public parentCompanyId: number;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public rowVersion: number[];
}
export class CompanyListForAddressDto extends CompanyListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class CompanyListForContactDto extends CompanyListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class CompanyListForContactGroupDto extends CompanyListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class CompanyAddressDto
{
	@Column() @Filterable() public companyId: number;
	@Column() public company: CompanyDto;
	@Column() @Filterable() public addressId: number;
	@Column() public address: AddressDto;
}


export class CompanyContactDto
{
	@Column() @Filterable() public companyId: number;
	@Column() public company: CompanyDto;
	@Column() @Filterable() public contactId: number;
	@Column() public contact: ContactDto;
}
export class CompanyContactGroupDto
{
	@Column() @Filterable() public companyId: number;
	@Column() public company: CompanyListSimpleDto;
	@Column() @Filterable() public contactGroupId: number;
	@Column() public contactGroup: ContactGroupListSimpleDto;
}