import { Injectable } from "@angular/core";
import { HttpBackend } from "@angular/common/http";
import { HttpApiClient } from "../http-api-client";
import { JwtInterceptorService } from "../../auth/jwt-interceptor.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: 'root'
})
export class HttpLocationApiClient extends HttpApiClient {
    constructor(handler: HttpBackend, translateService: TranslateService, jwtInterceptor: JwtInterceptorService) {
        super(handler, translateService, [ jwtInterceptor ]);
    }
}