import { Injectable } from '@angular/core';
import { HttpApiClient } from '../http-api-client';
import { HttpApiService } from '../http-api.service';
import { EnviromentService } from '../../../ui/enviroment.service';
import { ApiProduct } from '../../../../models/client';

@Injectable({
  providedIn: 'root'
})
export class HttpLoginApiService extends HttpApiService {
  constructor(httpClient: HttpApiClient, envService: EnviromentService) {
    super(httpClient, envService, ApiProduct.Identity);
  }
}
