//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';
import { OrgaAttachmentDto } from './Attachments';
import { IPlaceDto } from './MasterData';
import { LocationRelationDto } from './MasterData/Locations';
import { LocationGroupRelationDto } from './MasterData/LocationGroups';
import { ILocationRelationDto } from './MasterData';
import { ILocationGroupRelationDto } from './MasterData';

export class KnowledgePathListDto
{
	public knowledgePathId: number;
	public title: string;
}

export class KnowledgePathDto
{
	public knowledgePathId: number;
	public title: string;
	public articles: KnowledgeArticleDto[];
}

export class KnowledgeArticleInPathDto
{
	public knowledgeArticleId: number;
	public title: string;
	public position: number;
}

export class KnowledgeArticleDto extends ValidationDto implements IIdentityData
{
	@Column() public knowledgeArticleId: number;
	@Column() public title: string;
	@Column() public tags: string;
	@Column() public content: string;
	@Column() public tenantId: number;
	@Column() public position: number;
	@Column() public attachments: OrgaAttachmentDto[];
}
export class KnowledgeArticleListDto implements IPlaceDto, IIdentityData
{
	@Column() @Filterable() public knowledgeArticleId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tags: string;
	@Column() public tenantId: number;
}
export class KnowledgeArticleLocationDto implements ILocationRelationDto
{
	@Column() public locationId: number;
	@Column() public knowledgeArticleId: number;
}
export class KnowledgeArticleLocationGroupDto implements ILocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public knowledgeArticleId: number;
}
export class KnowledgeArticleViewDto implements IPlaceDto, IIdentityData
{
	@Column() public knowledgeArticleId: number;
	@Column() public title: string;
	@Column() public tags: string;
	@Column() public content: string;
	@Column() public tenantId: number;
	@Column() public attachments: OrgaAttachmentDto[];
	@Column() public locations: LocationRelationDto[];
	@Column() public locationGroups: LocationGroupRelationDto[];
}
