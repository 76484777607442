import { Page } from 'models/client';
import { map } from 'rxjs/operators';
import { ChatService } from '.';
import { FilterDto } from '../../../models/server/DataTransferObject/Filters';
import { ChatMessageDto } from '../../../models/server/DataTransferObject/Objects/Chats/ChatMessages';
import { FilterOperators } from '../../../models/server/Enums';
import { PageableService } from '../base/pageable.service';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';

export class ChatMessageService extends PageableService<ChatMessageDto> {
  constructor(
    protected http: HttpLocationApiService,
    protected chatService: ChatService,
    protected chatId: number
  ) {
    super(http, `chat/${chatId}/message`, 20);
  }

  protected addItems(newItems: ChatMessageDto[]) {
    this.items.splice(0, 0, ...newItems.reverse());
  }

  public async getNewMessages(): Promise<ChatMessageDto[]> {
    const pageParams = this.createHttpParams(0, 99);
    const idFilter = new FilterDto();
    idFilter.property = "chatMessageId";
    idFilter.operator = FilterOperators.GreaterThan;
    idFilter.value = Math.max(...this.items.map(x => x.chatMessageId)).toString();

    const response = this.http.post<Page<ChatMessageDto>>(`${this.endpoint}/filter`, [idFilter], null, pageParams);
    return response.pipe(map(x => x.body.items)).toPromise();
  }

  public async sendMessage(message: ChatMessageDto): Promise<ChatMessageDto> {
    return this.chatService.sendMessage(message);
  }
  
  public async markAsRead(): Promise<boolean> {
    return this.chatService.markAsRead(this.chatId);
  }

}
