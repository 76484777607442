import {ContactSimpleDto} from '../../../server/DataTransferObject/Objects/MasterData/Contact';

export class Safe {
    constructor(
        public contacts: ContactSimpleDto[]
    ) { }
}

export class ProcessResource {
    constructor(
        public resourceId: number,
        public name: string,
        public safe: Safe
    ) { }
}

export class ProcessState {
    constructor(
        public stateId: number,
        public name: string
    ) { }
}


export class ProcessPriority {
    constructor(
        public priorityId: number,
        public name: string
    ) { }
}


export class ProcessStereotype {
    constructor(
        public stereotypeId: number,
        public name: string
    ) { }
}

export class ProcessStatePreview {
    constructor(
        public nextStates: ProcessState[]
    ) { }
}

export class ProcessPrioritySet {
    constructor(
        public priorities: ProcessPriority[]
    ) { }
}

