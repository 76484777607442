//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';

export class DeviceActionNotificationDto implements INotificationDto
{
	@Column() public title: string;
	@Column() public message: string;
	@Column() public type: number;
	@Column() public action: number;
}
export class INotificationDto
{
	@Column() public title: string;
	@Column() public message: string;
	@Column() public type: number;
}
export class ChatMessageNotificationDto implements INotificationDto
{
	@Column() public title: string;
	@Column() public message: string;
	@Column() public type: number;
	@Column() public chatId: number;
	@Column() public chatMessageId: number;
}
export class PingDeviceActionNotificationDto extends DeviceActionNotificationDto implements INotificationDto
{
	@Column() public pingId: number;
}
export class TaskStateNotificationDto implements INotificationDto
{
	@Column() public title: string;
	@Column() public message: string;
	@Column() public type: number;
	@Column() public taskId: number;
	@Column() public taskJobId: number;
	@Column() public taskJobState: number;
}
