import { HttpBackend, HttpClient, HttpInterceptor } from '@angular/common/http';
import { Inject, Injectable, Optional } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CustomHttpInterceptorHandler } from "helpers";
import { environment } from "../../../../environments/environment";
import { HttpApiLangInterceptorService } from "./interceptors/http-api-lang-interceptor.service";
import { HttpApiTimeoutInterceptorService } from "./interceptors/http-api-timeout-interceptor.service";

@Injectable({
    providedIn: 'root'
})
export class HttpApiClient extends HttpClient {
    
    constructor(
        handler: HttpBackend,
        translateService: TranslateService,
        @Inject(environment.interceptor.additionalHttpApiClientInterceptorsIdentifier) @Optional() additionalInterceptors?: HttpInterceptor[]
    ) {
        additionalInterceptors = additionalInterceptors || [];

        additionalInterceptors.splice(0, 0, new HttpApiLangInterceptorService(translateService));
        additionalInterceptors.splice(0, 0, new HttpApiTimeoutInterceptorService());

        const chain = additionalInterceptors.reduceRight(
            (next, interceptor) => new CustomHttpInterceptorHandler(next, interceptor),
            handler
        );

        super(chain);
    }
}
