//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';

export class ProductSubscriptionDto
{
	@Column() public productSubscriptionId: number;
	@Column() @DateColumn() public expiresIn: string;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public edition: number;
}
export class ProductSubscriptionListDto
{
	@Column() @Filterable() public productSubscriptionId: number;
	@Column() @Filterable() @DateColumn() public expiresIn: string;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() @DateColumn() public createdAt: string;
	@Column() @Filterable() public edition: number;
}
