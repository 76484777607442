import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { AuthenticationService } from "..";
import { HttpLocationApiService } from "../http/http-location-api/http-location-api.service";

export class BaseSettingService<T> {
    protected endpoint: string = "tenant/:tenantId/setting";
    protected settingRefresher$ = new BehaviorSubject<void>(undefined);

    public setting$: Observable<T> = this.settingRefresher$
        .pipe(
            switchMap(_ => this.fetch()),
            shareReplay(1),
        );

    constructor(
        protected http: HttpLocationApiService,
        protected auth: AuthenticationService,
        protected settingType: number
    ) { }


    public refresh() {
      this.settingRefresher$.next();
    }

    protected fetch(): Observable<T> {
        return this.http.get<T>(this.getEndpoint()).pipe(
            map((response) => response.body)
        );
    }

    protected getEndpoint(): string {
        const currentTenantId = this.auth.getTenantId().toString();
        return `${this.endpoint.replace(":tenantId", currentTenantId)}/${this.settingType}`;
    }
}
