import { StereotypeSimpleDto } from "../../../server/index-objects";

export class CategoryDto {
    constructor(
        public categoryId: number,
        public name: string
    ) { }
}
export class CategoryByTypeDto {
    constructor(
        public items: CategoryDto[]
    ) { }
}
export enum CategorizedType {
    Info = 1,
    KnowledgeArticle = 2,
    Task = 4,
    KnowledgePath = 8,
}

export enum CategoryEntryType {
    Category = 1,
    Stereotype = 2
}

export class CategoryEntryDto {
    constructor(
        public type: CategoryEntryType,
        public index: number = 0,
        public category: CategoryDto = null,
        public stereotype: StereotypeSimpleDto = null
    ) {}

    public static fromCategory(category: CategoryDto): CategoryEntryDto {
        const entry = new CategoryEntryDto(CategoryEntryType.Category);
        entry.category = category;

        return entry;
    }

    public static fromStereotype(stereotype: StereotypeSimpleDto): CategoryEntryDto {
        const entry = new CategoryEntryDto(CategoryEntryType.Stereotype);
        entry.stereotype = stereotype;

        return entry;
    }

    public getName() {
        switch (this.type) {
            case CategoryEntryType.Category:
                return this.category.name;

            case CategoryEntryType.Stereotype:
                return this.stereotype.name;
        }
    }
}
