import { AppNotification } from ".";
import { DeviceActionTypes } from "../../server/Enums";

export class DeviceActionNotification extends AppNotification {
    constructor(
        public title: string = null,
        public message: string = null,
        public foreground: boolean = false,
        public action: DeviceActionTypes = null
    ) {
        super(title, message, foreground);
    }
}

export class PingDeviceActionNotification extends DeviceActionNotification {
    constructor(
        public title: string = null,
        public message: string = null,
        public foreground: boolean = false,
        public action: DeviceActionTypes = null,
        public pingId: number = null
    ) {
        super(title, message, foreground);
    }
}