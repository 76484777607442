//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IPlaceDto } from '../MasterData';
import { ContactGroupSimpleDto } from '../MasterData/ContactGroups';
import { MessagePatternDto } from '../ChatPatternMessages';
import { LocationRelationDto } from '../MasterData/Locations';
import { LocationGroupRelationDto } from '../MasterData/LocationGroups';
import { FormRowCreateDto } from './Create/Rows';

export class FormDto implements IIdentityData, IPlaceDto
{
	@Column() public formId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public type: number;
	@Column() public isArchived: boolean;
	@Column() public locations: LocationRelationDto[];
	@Column() public locationGroups: LocationGroupRelationDto[];
	@Column() public rows: FormRowCreateDto[];
}
export class ChatFormDto extends FormDto implements IIdentityData, IPlaceDto
{
	@Column() public sendAsAttachment: boolean;
	@Column() public receiverId: number;
	@Column() public receiver: ContactGroupSimpleDto;
	@Column() public messagePattern: MessagePatternDto;
	@Column() public requiresAuthentication: boolean;
}
