import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChatDto, SimpleChatDto, InitChatDto } from '../../../models/server/DataTransferObject/Objects/Chats';
import { ChatMessageDto } from '../../../models/server/DataTransferObject/Objects/Chats/ChatMessages';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';
import { ChatMessageService } from './chat-message.service';
import { ContactGroupChatMemberDto } from '../../../models/server/DataTransferObject/Objects/Chats/ChatMembers';
import { ChatMemberType } from '../../../models/server/Enums';
import { HttpResponse } from '@angular/common/http';

const SERVICE_ENDPOINT = 'chat';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  protected endpoint = SERVICE_ENDPOINT;

  constructor(
    protected http: HttpLocationApiService
  ) {
  }

  public async get(id: number): Promise<ChatDto> {
    return this.http
      .get<ChatDto>(`${this.endpoint}/${id}`)
      .pipe(map(x => x.body))
      .toPromise();
  }

  public async sendMessage(message: ChatMessageDto): Promise<ChatMessageDto> {
    const response = this.http.post<ChatMessageDto>(`${this.endpoint}/${message.chatId}/addmessage`, message);
    return response.pipe(map(x => x.body)).toPromise();
  }

  public getMessageService(id: number): ChatMessageService {
    return new ChatMessageService(this.http, this, id);
  }

  public async archive(id: number, archived: boolean = true) {
    const dto = new SimpleChatDto();
    dto.chatId = id;
    dto.isArchived = archived;
    return await this.http.post(`${this.endpoint}/${id}/archive`, dto, null, null).toPromise();
  }

  public async markAsRead(id: number): Promise<boolean> {
    const response = this.http.post(`${this.endpoint}/${id}/read`, null, null, null);
    return response.pipe(
      map(_ => true),
      catchError(error => {
        console.error(error);
        return of(false);
      })
    ).toPromise();
  }

  public async newChat(title: string, functionId: number): Promise<number> {
    const memberDto = new ContactGroupChatMemberDto();
    memberDto.functionId = functionId;
    memberDto.type = ChatMemberType.Function;

    const dto = new InitChatDto();
    dto.title = title;
    dto.additionalChatMembers = [memberDto];

    return this.http
      .post<SimpleChatDto>(`${this.endpoint}/init`, dto)
      .pipe(
        map((x: HttpResponse<SimpleChatDto>) => x.body.chatId)
      )
      .toPromise();
  }
}
