//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { CustomValueDto } from './CustomValues';
import { CustomValueCompleteDto } from './CustomValues';

export class FilledCustomValueDto extends ValidationDto implements IIdentityData
{
	@Column() public filledCustomValueId: number;
	@Column() public propertyId: number;
	@Column() public tenantId: number;
	@Column() public customValues: FilledCustomValueRelationDto[];
}
export class FilledCustomValueListDto
{
	@Column() public propertyId: number;
	@Column() public customValues: FilledCustomValueRelationListDto[];
}
export class FilledCustomValueRelationDto
{
	@Column() public customValueId: number;
	@Column() public customValue: CustomValueDto;
	@Column() public filledCustomValueId: number;
}
export class FilledCustomValueRelationListDto
{
	@Column() public customValue: CustomValueCompleteDto;
}
