//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';


export class LocationModelDto
{
	@Column() public locationId: number;
	@Column() public name: string;
}
export class PriorityModelDto
{
	@Column() public priorityId: number;
	@Column() public name: string;
}
export class StereotypeModelDto
{
	@Column() public stereotypeId: number;
	@Column() public name: string;
}
export class ContactModelDto
{
	@Column() public contactId: number;
	@Column() public displayName: string;
}
export class EscalationLevelModelDto
{
	@Column() public escalationLevelId: number;
	@Column() public name: string;
}
export class ResourceModelDto
{
	@Column() public resourceId: number;
	@Column() public name: string;
	@Column() public location: LocationModelDto;
}
export class TicketModelDto
{
	@Column() public ticketId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public resource: ResourceModelDto;
	@Column() public priority: PriorityModelDto;
	@Column() public escalationLevel: EscalationLevelModelDto;
	@Column() public editor: ContactModelDto;
}
export class MissionDescriptionModelDto
{
	@Column() public title: string;
	@Column() @DateColumn() public actualStart: string;
	@Column() @DateColumn() public actualEnd: string;
	@Column() @DateColumn() public plannedEnd: string;
	@Column() @DateColumn() public plannedStart: string;
	@Column() public ticket: TicketModelDto;
	@Column() public stereotype: StereotypeModelDto;
	@Column() public stateName: string;
	@Column() public state: number;
	@Column() public parameter: string;
}