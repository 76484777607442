import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-form-field-picture-overlay',
  templateUrl: './form-field-picture-overlay.page.html',
  styleUrls: ['./form-field-picture-overlay.page.scss'],
})
export class FormFieldPictureOverlayPage implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
  }


  public takePicture() {
    const button : HTMLElement = document.querySelector(".cordova-camera-capture button");

    if(!button)
      throw new Error("Camera could not trigger a picture");

    button.click();
  }

  public close() {
    const video : HTMLElement = document.querySelector(".cordova-camera-capture");
    video.style.display = 'none';

    this.modalCtrl.dismiss(null);

    //disable camera use, picture response is not used anymore
    setTimeout(() => this.takePicture(), 2500);
  }
  
  public ionViewDidEnter() {
    document.documentElement.classList.toggle('modal-camera', true);
  }

  public ionViewDidLeave() {
    document.documentElement.classList.toggle('modal-camera', false);
  }
}
