import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Identity } from '../../../models/client';
import { AppLoginMeta, AppPlatform, LoginMeta, PasswordLoginDto, RefreshTokenDto, TokenDto } from '../../../models/server/DataTransferObject/Objects/Authentication';
import { LoginByBadgeDto, LoginByPinDto } from '../../../models/server/DataTransferObject/Objects/Permissions';
import { RegisterDeviceDto } from '../../../models/server/Nexnox/Core/Shared/DataTransferObject/Objects/Devices/RegisterDeviceDto';
import { DeviceService } from '../../ui/device/device.service';
import { PlatformService } from '../../ui/device/platform.service';
import { HttpLoginApiService } from '../http/http-login-api/http-login-api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {  

  private loginMeta: LoginMeta;

  constructor(
    private http: HttpLoginApiService,
    private deviceService : DeviceService,
    private platformService: PlatformService
  ) { }



  public async getMeta() : Promise<LoginMeta> {
    if(this.loginMeta)
      return this.loginMeta;

    const version = await this.deviceService.getAppVersion();
    const platformInfo = {
      isNative: this.platformService.isNative(),
      isIos: this.platformService.isIos(),
      isAndroid: this.platformService.isAndroid(),
      isNativer: this.platformService.isNativer(),
      isBrowser: this.platformService.isBrowser(),
    };

    let platform = AppPlatform.Unknown;

    if(platformInfo.isNative) {
      if(platformInfo.isIos) {
        platform = AppPlatform.iOs;
      }
      if(platformInfo.isAndroid) {
        platform = AppPlatform.Android;
      }
    }
    else {
      if(platformInfo.isNativer) {
        platform = AppPlatform.Natifier;
      } else if(platformInfo.isBrowser) {
        platform = AppPlatform.Browser;
      }
    }

    this.loginMeta = <AppLoginMeta> {
      type: 1,
      appVersion: version,
      appPlatform: platform
    };

    return this.loginMeta;
  }

  public async loginWithPassword(user: string, password: string) : Promise<HttpResponse<TokenDto>> {
    const body = <PasswordLoginDto> {
      email: user,
      passwordBase64: btoa(password)
    };

    return this.http.post<TokenDto>('login/password', body).toPromise();
  }

  public async loginWithDevicePin(dto: RegisterDeviceDto) : Promise<HttpResponse<TokenDto>> {
    const path = `login/RegisterDeviceByCode`;
    return this.http.post<TokenDto>(path, dto).toPromise();
  }

  public async loginWithUserPin(identity: Identity, dto: LoginByPinDto) : Promise<HttpResponse<TokenDto>> {
    const path = `login/pin`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${identity.token}`);

    return this.http.post<TokenDto>(path, dto, headers).toPromise();
  }
  public async loginWithUserBadge(identity: Identity, dto: LoginByBadgeDto) : Promise<HttpResponse<TokenDto>> {
    const path = `login/badge`;
    const headers = new HttpHeaders().set('Authorization', `Bearer ${identity.token}`);

    return this.http.post<TokenDto>(path, dto, headers).toPromise();
  }
  
  public async loginRefresh(refreshToken: string, roleIds: number[] = undefined) : Promise<HttpResponse<TokenDto>> {
    const body  = <RefreshTokenDto> {
      refreshToken: refreshToken,
      roleIds: roleIds,
      meta: await this.getMeta()
    };
    
    return this.http.post<TokenDto>('login/refresh', body).toPromise();
  }
}
