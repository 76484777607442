//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';

export class LoginChallengeDto
{
	@Column() public loginChallengeId: number;
}
export class DeviceCodeChallengeDto extends LoginChallengeDto
{
	@Column() public pin: string;
	@Column() @DateColumn() public expiryDate: string;
}
