import { Injectable } from "@angular/core";
import { HttpApiService } from "../http-api.service";
import { HttpLocationApiClient } from "./http-location-api-client";
import { EnviromentService } from "../../../ui/enviroment.service";
import { ApiProduct } from "../../../../models/client";

@Injectable({
  providedIn: 'root'
})
export class HttpLocationApiService extends HttpApiService {
  constructor(
    httpClient: HttpLocationApiClient,
    envService: EnviromentService
  ) {
    super(httpClient, envService, ApiProduct.Orga);
  }
}


@Injectable({
  providedIn: 'root'
})
export class HttpTechLocationApiService extends HttpApiService {
  constructor(
    httpClient: HttpLocationApiClient,
    envService: EnviromentService
  ) {
    super(httpClient, envService, ApiProduct.Tech);
  }
}
