//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ContactGroupSimpleDto } from './MasterData/ContactGroups';
import { TaskSimpleDto } from './Tasks/SimpleDtos';
import { ValidationDto } from '../Validations';
import { ConditionDto } from './Conditions';

export class AlertImpactConsequenceDto
{
	@Column() public impactId: number;
	@Column() public jobId: number;
}
export class ImpactCreateDto extends ValidationDto
{
	@Column() public impactId: number;
	@Column() public type: number;
	@Column() public condition: ConditionDto;
	@Column() public taskId: number;
}
export class ImpactDto
{
	@Column() public impactId: number;
	@Column() public type: number;
	@Column() public condition: ConditionDto;
}
export class StatusImpactCreateDto extends ImpactCreateDto
{
	@Column() public targetState: number;
}
export class StatusImpactDto extends ImpactDto
{
	@Column() public targetState: number;
}
export class AlertImpactCreateDto extends ImpactCreateDto
{
	@Column() public contactGroupId: number;
}
export class AlertImpactDto extends ImpactDto
{
	@Column() public contactGroup: ContactGroupSimpleDto;
}
export class SanctionImpactCreateDto extends ImpactCreateDto
{
	@Column() public sanctionId: number;
	@Column() public sanctionOffset: string;
}
export class SanctionImpactDto extends ImpactDto
{
	@Column() public sanction: TaskSimpleDto;
	@Column() public sanctionId: number;
	@Column() public sanctionOffset: string;
}
