//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { StateListDto } from '../StateMachines';
import { EscalationLevelListDto } from './Escalations';
import { MissionSimpleListDto } from '../Missions';
import { ValidationDto } from '../../Validations';
import { MailBoxDto } from '../MasterData';

export class NoteCompleteDto implements IIdentityData
{
	@Column() public noteId: number;
	@Column() @Filterable() public description: string;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public ticketId: number;
	@Column() public rowVersion: number[];
	@Column() public phoneNumber: string;
	@Column() public direction: number;
	@Column() public tenantId: number;
	@Column() public solutionItemId: number;
	@Column() public stateId: number;
	@Column() public changedToState: StateListDto;
	@Column() public offsetForCanceledLevel: string;
	@Column() public cancelLevelId: number;
	@Column() public cancelLevel: EscalationLevelListDto;
	@Column() public escalatedLevelIdByUser: number;
	@Column() public escalatedLevelByUser: EscalationLevelListDto;
	@Column() public offsetForEscalatedByEvent: string;
	@Column() public escalatedLevelIdByEvent: number;
	@Column() public escalatedLevelByEvent: EscalationLevelListDto;
	@Column() public missionState: number;
	@Column() public isChangedByEmail: boolean;
	@Column() public stateOfCausedMission: number;
	@Column() public causedMissionId: number;
	@Column() public causedMission: MissionSimpleListDto;
	@Column() public to: any[];
	@Column() public cc: any[];
	@Column() public from: any[];
}
export class NoteDto extends ValidationDto implements IIdentityData
{
	@Column() public noteId: number;
	@Column() public description: string;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public rowVersion: number[];
}
export class NoteMailBoxDto
{
	@Column() public mailBox: MailBoxDto;
	@Column() public relation: number;
	@Column() public mailBoxId: number;
	@Column() public noteMailId: number;
}
export class NoteActionDto extends NoteDto implements IIdentityData
{
}
export class NoteCallerDto extends NoteDto implements IIdentityData
{
	@Column() public phoneNumber: string;
	@Column() public direction: number;
}
export class NoteMailDto extends NoteDto implements IIdentityData
{
	@Column() public direction: number;
	@Column() public to: NoteMailBoxDto[];
	@Column() public from: NoteMailBoxDto[];
	@Column() public cc: NoteMailBoxDto[];
	@Column() public mailBoxes: NoteMailBoxDto[];
}
export class NoteTextDto extends NoteDto implements IIdentityData
{
}
