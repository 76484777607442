//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { MissionSimpleDto } from './Missions';
import { ResourceSimpleDto } from './Resources';
import { TicketSimpleDto } from './Tickets';
import { ValidationDto } from '../Validations';

export class LinkedElementDto extends ValidationDto implements IIdentityData
{
	@Column() public linkedElementId: number;
	@Column() public tenantId: number;
	@Column() public type: number;
}
export class LinkToMissionDto extends LinkedElementDto implements IIdentityData
{
	@Column() public element: MissionSimpleDto;
}
export class LinkToResourceDto extends LinkedElementDto implements IIdentityData
{
	@Column() public element: ResourceSimpleDto;
}
export class LinkToTicketDto extends LinkedElementDto implements IIdentityData
{
	@Column() public element: TicketSimpleDto;
}

export class LinkDto implements IIdentityData
{
	@Column() public linkId: number;
	@Column() @DateColumn() public lastModified: string;
	@Column() @DateColumn() public createdAt: string;
	@Column() public comment: string;
	@Column() public tenantId: number;
	@Column() public to: LinkedElementDto;
}