//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { LocationDto } from './Locations';
import { ContactDto } from './Contact';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';

export class ContactGroupDto extends ValidationDto implements IStereotypedDto, IIdentityData
{
	@Column() public functionId: number;
	@Column() public tenantId: number;
	@Column() public name: string;
	@Column() public customPropertyValues: FilledCustomValueDto[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public rowVersion: number[];
	@Column() public isArchived: boolean;
}
export class ContactGroupSimpleDto
{
	@Column() public functionId: number;
	@Column() public name: string;
}
export class ContactContactGroupDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactDto;
	@Column() public functionId: number;
	@Column() public function: ContactGroupDto;
}
export class ContactGroupListDto implements IIdentityData
{
	@Column() @Filterable() public contactGroupId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public email: string;
	@Column() @Filterable() public phone: string;
	@Column() @Filterable() public stereotypeId: number;
	@Column() @Filterable() public isArchived: boolean;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
	@Column() public rowVersion: number[];
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
}
export class ContactGroupListSimpleDto implements IIdentityData
{
	@Column() @Filterable() public contactGroupId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public email: string;
	@Column() @Filterable() public phone: string;
	@Column() @Filterable() public stereotypeId: number;
	@Column() @Filterable() public isArchived: boolean;
	@Column() public rowVersion: number[];
	@Column() public stereotypeRowVersion: number[];
}
export class ContactGroupRelationDto
{
	@Column() public contactGroupId: number;
	@Column() public contactGroup: ContactGroupSimpleDto;
}
export class ContactGroupListForCompanyDto extends ContactGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactGroupListForContactDto extends ContactGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactGroupListForLocationDto extends ContactGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactGroupLocationDto
{
	@Column() @Filterable() public contactGroupId: number;
	@Column() public contactGroup: ContactGroupDto;
	@Column() @Filterable() public locationId: number;
	@Column() public location: LocationDto;
}
