import * as moment from 'moment-timezone';

export class DateHelper {
    public static convertDatetime(datetime: string, showPastYear = false): any {
        if (!datetime) {
            return '';
        }

        const date = moment.utc(datetime).tz('Europe/Berlin');
        const now = moment.utc().tz('Europe/Berlin');

        if (showPastYear && date.year() !== now.year()) {
            return date.format('DD.MM.YYYY HH:mm');
        }

        return date.format('DD.MM HH:mm');
    }

    public static toAspFormat(timestamp: number): string {
        return moment.utc(timestamp).format('YYYY-MM-DDTHH:mm');
    }
} 
