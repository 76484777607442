import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { QRScanner } from "@ionic-native/qr-scanner/ngx";
import { ModalController } from "@ionic/angular";
import { ModalService } from "services/ui/modal.service";
import { LoginTypes } from "../../../models/client";
import { LoginByBadgeDto } from "../../../models/server/index-objects";
import { AuthenticationService } from "../../../services/api";
import { LoginService } from "../../../services/api/auth/login.service";


@Component({
  selector: 'app-badge-login',
  templateUrl: './badge-login.page.html',
  styleUrls: ['./badge-login.page.scss'],
})
export class BadgeLoginPage implements OnInit {

  public isLightOn = false;
  public isBack = true;
  public isDismissed = false;

  constructor(
    private qrScanner: QRScanner,
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private loginService: LoginService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.startScan();
  }

  ionViewWillLeave() {
    this.isDismissed = true;
    this.qrScanner.hide();
    this.qrScanner.destroy();
    this.hideCamera();
  }

  async startScan() {
    try {
      const status = await this.qrScanner.prepare();
      if (this.isDismissed)
        return;

      if (status.authorized) {
        const scanSub = this.qrScanner.scan().subscribe(async (data: any) => {
          const result = data?.result ? data.result : data;

          scanSub.unsubscribe();
          this.success(result);
        }, async (error) => {
          if (error && error.name == "SCAN_CANCELED")
            return;

          (await this.modalService.createInfoToast('SCAN.SCANFAIL')).present();
          console.error(error);
        });

        this.showCamera();
        await this.qrScanner.show();

      } else if (status.denied) {
        this.permissionDenied();
      } else {
        this.permissionDenied();
      }
    } catch (error) {
      (await this.modalService.createInfoToast('SCAN.SCANERROR')).present();
    }
  }

  public async permissionDenied() {
    (await this.modalService.createInfoToast('SCAN.PERMISSIONDENIED')).present();
  }

  public async switchLight() {
    this.isLightOn = !this.isLightOn;

    try {
      if (this.isLightOn) {
        await this.qrScanner.enableLight();
      } else {
        await this.qrScanner.disableLight();
      }
    } catch (error) {
      console.error(error);
      (await this.modalService.createInfoToast('SCAN.LIGHTERROR')).present();
    }
  }

  public async switchCamera() {
    this.isBack = !this.isBack;

    try {
      if (this.isBack) {
        await this.qrScanner.useBackCamera();
      } else {
        await this.qrScanner.useFrontCamera();
      }
    } catch (error) {
      console.error(error);
      (await this.modalService.createInfoToast('SCAN.SWITCHERROR')).present();
    }
  }

  public usePinLogin() {
    this.modalService.dismissModal({
      changeLogin: LoginTypes.Pin
    });
  }

  public exit() {
    this.modalCtrl.dismiss();
  }

  private async success(data: string) {
    const loader = await this.modalService.createLoader();
    loader.present();

    try {
      const locationIdentity = this.authService.getIdentity();
      const userResponse = await this.loginService.loginWithUserBadge(locationIdentity, <LoginByBadgeDto>{
        badge: data
      });

      loader.dismiss();
      this.modalService.dismissModal({
        token: userResponse.body
      });

    } catch (error) {
      loader.dismiss();
      if (error instanceof HttpErrorResponse && error.status == 404) {
        (await this.modalService.createInfoToast('LOGIN.FAILEDMSG')).present();
      } else if (error instanceof HttpErrorResponse && error.status == 403) {
        (await this.modalService.createInfoToast('LOGIN.FORBIDDENMSG')).present();
      } else {
        (await this.modalService.createErrorAlertWithDetail(error)).present();
      }
    }
    finally {
      this.modalService.dismissModal();
    }
  }

  private showCamera() {
    document.documentElement.classList.toggle('modal-camera', true);
  }

  private hideCamera() {
    document.documentElement.classList.toggle('modal-camera', false);

    setTimeout(() => {
      document.documentElement.style.backgroundColor = '';
      document.body.style.backgroundColor = '';
    }, 250);
  }
}
