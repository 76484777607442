//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { QueueDto } from './Queues';
import { TaskDto } from './Tasks/ViewDtos';
import { OrgaAttachmentListDto } from './Attachments';
import { TaskSimpleListDto } from './Tasks/SimpleDtos';
import { LocationSimpleDto } from './MasterData/Locations';

export class TaskJobStateReasonDto implements IIdentityData
{
	@Column() public taskJobStateReasonId: number;
	@Column() public content: string;
	@Column() public tenantId: number;
}
export class ChangeTaskJobStatusDto implements IIdentityData
{
	@Column() public taskJobId: number;
	@Column() public targetState: number;
	@Column() public tenantId: number;
	@Column() public reasonId: number;
}
export class CreateManualTaskJobDto
{
	@Column() public taskId: number;
	@Column() public explicitState: number;
}
export class FinishStatusTaskJobDto extends ChangeTaskJobStatusDto implements IIdentityData
{
}
export class FinishTaskJobQueueDto extends QueueDto
{
	@Column() public taskJobId: number;
	@Column() public targetState: number;
}
export class TaskJobDto implements IIdentityData
{
	@Column() public taskJobId: number;
	@Column() @DateColumn() public executionDate: string;
	@Column() public parentId: number;
	@Column() public task: TaskDto;
	@Column() public attachment: OrgaAttachmentListDto;
	@Column() public jobStatus: TaskJobStateDto[];
	@Column() public children: TaskJobDto[];
}
export class TaskJobListDto
{
	@Column() @Filterable() public taskJobId: number;
	@Column() @Filterable() @DateColumn() public executionDate: string;
	@Column() public task: TaskSimpleListDto;
	@Column() public jobStatus: TaskJobStateDto[];
	@Column() public currentStatus: number;
	@Column() public children: TaskJobListDto[];
	@Column() @Filterable() public taskId: number;
	@Column() @Filterable() public parentId: number;
	@Column() @Filterable() public locationId: number;
	@Column() public location: LocationSimpleDto;
	@Column() public locationName: string;
	@Column() public filledFormId: number;
	@Column(true) public attachment: OrgaAttachmentListDto;
}
export class TaskJobStateDto
{
	@Column() public taskJobStatusId: number;
	@Column() public status: number;
	@Column(true) public reason: TaskJobStateReasonDto;
	@Column(true) public createdByContactName: string;
}
export class TaskJobStateReasonSetDto implements IIdentityData
{
	@Column() public taskJobStateReasonSetId: number;
	@Column() public tenantId: number;
	@Column() public title: string;
	@Column() public reasons: TaskJobStateReasonDto[];
}
export class TaskJobStateReasonSetListDto implements IIdentityData
{
	@Column() @Filterable() public taskJobStateReasonSetId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tenantId: number;
}
