import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PushRegisterDto } from '../../../models/server/DataTransferObject/Objects/Devices';
import { AuthenticationService } from '../auth/authentication.service';
import { HttpIdentityAnonymApiService } from '../http/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class PushRegisterService {
  private endpoint: string = "pushregister";

  constructor(
    protected http: HttpIdentityAnonymApiService,
    protected authentication: AuthenticationService
  ) { }

  async registerPush(pushRegister: PushRegisterDto): Promise<boolean> {
    const locationIdentity = this.authentication.getIdentity();

    return this.http
      .addIdentity(locationIdentity)
      .post(`${this.endpoint}`, pushRegister).pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      ).toPromise();
  }

}
