//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { CustomDefaultCompleteDto } from './CustomValues';
import { ValidationDto } from '../../Validations';
import { CustomDefaultDto } from './DefaultCustomValues';

export class CustomPropertyCompleteDto
{
	@Column() public id: number;
	@Column() public customPropertyId: number;
	@Column() public name: string;
	@Column() public isRequired: boolean;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public position: number;
	@Column() public maxLength: number;
	@Column() public minLength: number;
	@Column() public minValue: number;
	@Column() public maxValue: number;
	@Column() public unit: string;
	@Column() @DateColumn() public maxTime: string;
	@Column() @DateColumn() public minTime: string;
	@Column() public minMinutes: number;
	@Column() public maxMinutes: number;
	@Column() public defaultValues: CustomDefaultCompleteDto[];
	@Column() public content: string;
}
export class CustomPropertyDto extends ValidationDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public customPropertyId: number;
	@Column() public name: string;
	@Column() public isRequired: boolean;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public position: number;
	@Column() public defaultValues: CustomDefaultDto[];
}
export class CustomPropertySetDto implements IIdentityData
{
	@Column() public customPropertySetId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public properties: CustomPropertyDto[];
}
export class CustomPropertySetListDto implements IIdentityData
{
	@Column() public customPropertySetId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public properties: CustomPropertyCompleteDto[];
}
export class CustomPropertyViewSetDto
{
	@Column() public customPropertySetId: number;
	@Column() public name: string;
	@Column() public properties: CustomPropertyDto[];
	@Column() public tenantId: number;
	@Column() public stereotypeId: number;
}
export class CustomSetReferenceDto
{
	@Column() public stereotypeId: number;
	@Column() public customPropertySetId: number;
	@Column() public customPropertySet: CustomPropertySetDto;
	@Column() public isInherited: boolean;
	@Column() public position: number;
}
export class CustomSetReferenceListDto
{
	@Column() public stereotypeId: number;
	@Column() public customPropertySetId: number;
	@Column() public customPropertySet: CustomPropertySetListDto;
	@Column() public position: number;
	@Column() public isInherited: boolean;
}
export class CustomCheckboxDto extends CustomPropertyDto implements IIdentityData
{
}
export class CustomDatePropertyDto extends CustomPropertyDto implements IIdentityData
{
	@Column() @DateColumn() public maxTime: string;
	@Column() @DateColumn() public minTime: string;
}
export class CustomDropDownDto extends CustomPropertyDto implements IIdentityData
{
}
export class CustomEmailPropertyDto extends CustomPropertyDto implements IIdentityData
{
}
export class CustomMultilinePropertyDto extends CustomPropertyDto implements IIdentityData
{
	@Column() public maxLength: number;
	@Column() public minLength: number;
}
export class CustomNumericPropertyDto extends CustomPropertyDto implements IIdentityData
{
	@Column() public maxValue: number;
	@Column() public minValue: number;
	@Column() public unit: string;
}
export class CustomPhoneDto extends CustomPropertyDto implements IIdentityData
{
}
export class CustomTextPropertyDto extends CustomPropertyDto implements IIdentityData
{
	@Column() public maxLength: number;
	@Column() public minLength: number;
}
export class CustomTimePropertyDto extends CustomPropertyDto implements IIdentityData
{
	@Column() public maxMinutes: number;
	@Column() public minMinutes: number;
}
