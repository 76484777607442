//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';

export class CustomDefaultCompleteDto implements IIdentityData
{
	@Column() public customValueId: number;
	@Column() public type: number;
	@Column() public customPropertyId: number;
	@Column() public tenantId: number;
	@Column() public isSelected: boolean;
	@Column() public position: number;
	@Column() public content: string;
	@Column() public value: any;
}
export class CustomValueCompleteDto implements IIdentityData
{
	@Column() public customValueId: number;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public isInherited: boolean;
	@Column() public ownValue: any;
	@Column() public inheritedValue: any;
}
export class CustomValueDto extends ValidationDto implements IIdentityData
{
	@Column() public customValueId: number;
	@Column() public type: number;
	@Column() public tenantId: number;
	@Column() public isInherited: boolean;
}
export class CustomCheckboxValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: boolean;
	@Column() public inheritedValue: boolean;
}
export class CustomDateValueDto extends CustomValueDto implements IIdentityData
{
	@Column() @DateColumn() public ownValue: string;
	@Column() @DateColumn() public inheritedValue: string;
}
export class CustomDropDownValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: number;
	@Column() public inheritedValue: number;
	@Column() public isSelected: boolean;
	@Column() public position: number;
}
export class CustomEmailValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: string;
	@Column() public inheritedValue: string;
}
export class CustomMultilineValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: string;
	@Column() public inheritedValue: string;
}
export class CustomNumericValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: number;
	@Column() public inheritedValue: number;
}
export class CustomPhoneValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: string;
	@Column() public inheritedValue: string;
}
export class CustomTextValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: string;
	@Column() public inheritedValue: string;
}
export class CustomTimeValueDto extends CustomValueDto implements IIdentityData
{
	@Column() public ownValue: number;
	@Column() public inheritedValue: number;
}
