import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpAnonymApiService } from '../http/http-api.service';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  private endpoint: string = "version";

  constructor(
    protected http: HttpAnonymApiService
  ) { }

  async checkApp(version: string) : Promise<boolean> {
    return this.http.post<any>(
        `${this.endpoint}/checkapp`,
        JSON.stringify(version),
        new HttpHeaders({ 'Content-Type': 'application/json' })
    ).pipe(
        switchMap(_ => of(true)),
        catchError(err => {
            if(err instanceof HttpErrorResponse && err.status == 409) {
                return of(false);
            } else {
                throwError(err);
            }
        })
    ).toPromise();
  }
}
