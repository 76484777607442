//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ContactSimpleDto } from './MasterData/Contact';
import { ContactGroupSimpleDto } from './MasterData/ContactGroups';
import { ContactGroupListSimpleDto } from './MasterData/ContactGroups';
import { ContactSimpleListDto } from './MasterData/Contact';
import { IIdentityData } from '../Objects';
import { ILocationRelationDto } from './MasterData';
import { LocationSimpleDto } from './MasterData/Locations';
import { ILocationGroupRelationDto } from './MasterData';
import { LocationGroupSimpleDto } from './MasterData/LocationGroups';
import { LocationGroupSimpleListDto } from './MasterData/LocationGroups';
import { LocationSimpleListDto } from './MasterData/Locations';

export class FolderAccessContactDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactSimpleDto;
}
export class FolderAccessContactGroupDto
{
	@Column() public contactGroupId: number;
	@Column() public contactGroup: ContactGroupSimpleDto;
}
export class FolderAccessContactGroupListDto
{
	@Column() public contactGroupId: number;
	@Column() public contactGroup: ContactGroupListSimpleDto;
}
export class FolderAccessContactListDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactSimpleListDto;
}
export class FolderAccessDto implements IIdentityData
{
	@Column() public folderAccessId: number;
	@Column() public path: string;
	@Column() public canPrint: boolean;
	@Column() public canMail: boolean;
	@Column() public canShare: boolean;
	@Column() public isInherited: boolean;
	@Column() public locations: FolderAccessLocationDto[];
	@Column() public locationGroups: FolderAccessLocationGroupDto[];
	@Column() public contacts: FolderAccessContactDto[];
	@Column() public contactGroups: FolderAccessContactGroupDto[];
	@Column() public tenantId: number;
}
export class FolderAccessListDto implements IIdentityData
{
	@Column() @Filterable() public folderAccessId: number;
	@Column() @Filterable() public path: string;
	@Column() @Filterable() public canPrint: boolean;
	@Column() @Filterable() public canMail: boolean;
	@Column() @Filterable() public canShare: boolean;
	@Column() @Filterable() public isInherited: boolean;
	@Column() public fellows: string;
	@Column() public locations: FolderAccessLocationListDto[];
	@Column() public locationGroups: FolderAccessLocationGroupListDto[];
	@Column() public contacts: FolderAccessContactListDto[];
	@Column() public contactGroups: FolderAccessContactGroupListDto[];
}
export class FolderAccessLocationDto implements ILocationRelationDto
{
	@Column() public locationId: number;
	@Column() public location: LocationSimpleDto;
}
export class FolderAccessLocationGroupDto implements ILocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupSimpleDto;
}
export class FolderAccessLocationGroupListDto implements ILocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupSimpleListDto;
}
export class FolderAccessLocationListDto implements ILocationRelationDto
{
	@Column() public locationId: number;
	@Column() public location: LocationSimpleListDto;
}
export class FolderAccessSimpleDto
{
	@Column() @Filterable() public folderAccessId: number;
	@Column() @Filterable() public path: string;
	@Column() @Filterable() public canPrint: boolean;
	@Column() @Filterable() public canMail: boolean;
	@Column() @Filterable() public canShare: boolean;
	@Column() @Filterable() public isInherited: boolean;
}
