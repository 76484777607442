//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IHtmlViewData } from '../../Objects';

export class EmailButtonTemplateDto implements IHtmlViewData
{
	@Column() public text: string;
	@Column() public url: string;
}
export class EmailTemplateDto implements IHtmlViewData
{
	@Column() public content: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public preview: string;
}
export class EmailTextLineTemplateDto implements IHtmlViewData
{
	@Column() public text: string;
}
