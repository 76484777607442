//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../Attributes';

export class ArchiveDto
{
	@Column() public entityId: number;
	@Column() public isArchived: boolean;
}
export class ChatUnreadDto
{
	@Column() public chatId: number;
	@Column() public unreadCount: number;
}
export class IArchiveDto
{
	@Column() public isArchived: boolean;
}
export class IHtmlViewData
{
}
export class IIdentityData
{
}
export class RelationSetDto implements IIdentityData
{
	@Column() public sourceId: number;
	@Column() public targetIds: number[];
}
export class SimpleIdDto
{
	@Column() public id: number;
}
