//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IPlaceDto } from '../MasterData';
import { FormDto } from '../Forms/Viewing';
import { TaskJobStateReasonSetSimpleDto } from '../TaskJobs/SimpleDtos';
import { ExecutionPlanViewDto } from '../Executions';
import { LocationRelationDto } from '../MasterData/Locations';
import { LocationGroupRelationDto } from '../MasterData/LocationGroups';
import { ImpactDto } from '../Impacts';

export class TaskDto implements IIdentityData, IPlaceDto
{
	@Column() public parentId: number;
	@Column() public taskId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public type: number;
	@Column() public requiresAuthentication: boolean;
	@Column() public reasonSet: TaskJobStateReasonSetSimpleDto;
	@Column() public generatePdf: boolean;
	@Column() public generateCsv: boolean;
	@Column() public executionPlan: ExecutionPlanViewDto;
	@Column() public locations: LocationRelationDto[];
	@Column() public locationGroups: LocationGroupRelationDto[];
	@Column() public isEnabled: boolean;
	@Column() public state: number;
	@Column() public impacts: ImpactDto[];
}
export class CollectionTaskDto extends TaskDto implements IIdentityData, IPlaceDto
{
	@Column() public children: TaskDto[];
	@Column() public hasStrictOrder: boolean;
}
export class ConfirmationTaskDto extends TaskDto implements IIdentityData, IPlaceDto
{
}
export class DocumentTaskDto extends TaskDto implements IIdentityData, IPlaceDto
{
	@Column() public form: FormDto;
	@Column() public formId: number;
	@Column() public position: number;
}
