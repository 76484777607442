//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { ICsvViewData } from '../Abstractions';
import { ShareIdentifierDto } from '../Tenants';

export class FormCsvDto implements ICsvViewData
{
	@Column() public filledFormId: number;
	@Column() public fileName: string;
	@Column() public title: string;
	@Column() public share: ShareIdentifierDto;
	@Column() public createdByLocation: string;
	@Column() public createdByUser: string;
	@Column() public createdAt: any;
	@Column() public isRated: boolean;
	@Column() public ratingInPercent: string;
	@Column() public rows: FormRowCsvDto[];
	@Column() public highestRepetition: number;
}
export class FormFieldCsvDto implements ICsvViewData
{
	@Column() public id: number;
	@Column() public title: string;
	@Column() public position: number;
	@Column() public value: string;
	@Column() public valueToDisplay: any;
}
export class FormRowCsvDto implements ICsvViewData
{
	@Column() public id: number;
	@Column() public position: number;
	@Column() public title: string;
	@Column() public fields: FormFieldCsvDto[];
}
