import { TicketSkeleton } from "./ticket-skeleton";
import {ResourceSimpleDto, StereotypeListDto, StereotypeSimpleDto} from '../../../server/index-objects';

export class TicketProcessDefintion {
    constructor(
        public ticketByProcessDefinitionId: number,
        public ticket: TicketSkeleton,
        public requiresAuthentication: boolean,
        public name: string,
        public tenantId: number
    ) { }
}
