//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { PictureSettingDto, ContactLoginMethodSettingDto } from './Settings/SettingValues';
import { HexColorSettingDto } from './Settings/SettingValues';
import { CultureSettingDto } from './Settings/SettingValues';

export enum PdfViewBehaviour {
	Internal = 0,
	External = 1
}

export class TenantSettingDto implements IIdentityData
{
	@Column() public settingId: number;
	@Column() public tenantId: number;
	@Column() public type: number;
}
export class GeneralSettingDto extends TenantSettingDto implements IIdentityData
{
	@Column() public logoId: number;
	@Column() public logo: PictureSettingDto;
	@Column() public primaryColorId: number;
	@Column() public primaryColor: HexColorSettingDto;
	@Column() public defaultLanguage: CultureSettingDto;
	@Column() public defaultLoginMethod: ContactLoginMethodSettingDto;
	@Column() public availableLoginMethod: ContactLoginMethodSettingDto;
	@Column() public isClockTileDisabled: boolean;
	@Column() public pdfViewBehaviour: PdfViewBehaviour;
}
