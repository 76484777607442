import { Injectable } from '@angular/core';
import { NativePushService } from './push/native-push.service';
import { PushService } from './push/push-service';
import { Observable, Subject } from 'rxjs';
import { AppNotification, TaskNotification, ChatNotification } from "models/client";
import { DeviceActionNotification } from '../../models/client/notification/device-action-notification';
import { InfoNotification } from '../../models/client/notification/info-notification';
import {MissionNotification} from '../../models/client/notification/mission-notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements PushService {

  private registrationId: string = null;
  private onTaskNotificationSubject: Subject<TaskNotification> = new Subject();
  private onChatNotificationSubject: Subject<ChatNotification> = new Subject();
  private onInfoNotificationSubject: Subject<InfoNotification> = new Subject();
  private onDeviceActionNotificationSubject: Subject<DeviceActionNotification> = new Subject();
  private onMissionNotificationSubject: Subject<MissionNotification> = new Subject();

  constructor(
    protected nativePushService: NativePushService
  ) {
    this.registerNotificationEvents();
  }

  public onNotification(): Observable<AppNotification> {
    return this.nativePushService.onNotification();
  }
  public onError(): Observable<Error> {
    return this.nativePushService.onError();
  }

  public async clearAll() {
    await this.nativePushService.clearAll();
  }

  public async register(): Promise<string> {
    if(this.registrationId == null) {
      this.registrationId = await this.nativePushService.register();
    }

    return this.registrationId;
  }
  public async isAvailable(): Promise<boolean> {
    return this.nativePushService.isAvailable();
  }
  public async isPresent(): Promise<boolean> {
    return this.nativePushService.isPresent();
  }

  public onTaskNotification() : Observable<TaskNotification> {
    return this.onTaskNotificationSubject.asObservable();
  }

  public onInfoNotification() : Observable<InfoNotification> {
    return this.onInfoNotificationSubject.asObservable();
  }

  public onChatNotification() : Observable<ChatNotification> {
    return this.onChatNotificationSubject.asObservable();
  }

  public onDeviceActionNotification() : Observable<DeviceActionNotification> {
    return this.onDeviceActionNotificationSubject.asObservable();
  }

  public onMissionNotification() : Observable<MissionNotification> {
    return this.onMissionNotificationSubject.asObservable();
  }

  private registerNotificationEvents() {
    this.onNotification().subscribe(notification => {
      if(notification instanceof TaskNotification) {
        this.onTaskNotificationSubject.next(notification);
      }
      if(notification instanceof ChatNotification) {
        this.onChatNotificationSubject.next(notification);
      }
      if(notification instanceof DeviceActionNotification) {
        this.onDeviceActionNotificationSubject.next(notification);
      }
      if(notification instanceof InfoNotification) {
        this.onInfoNotificationSubject.next(notification);
      }
      if(notification instanceof MissionNotification) {
        this.onMissionNotificationSubject.next(notification);
      }
    });
  }

}
