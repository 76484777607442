import { Injectable } from '@angular/core';
import { ResourceListDto } from '../../../models/server/index-objects';
import { PageableService } from '../base/pageable.service';
import { HttpTechLocationApiService } from '../http/http-location-api/http-location-api.service';
import { HttpApiService } from '../http/http-api.service';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { HttpParams } from '@angular/common/http';


const PAGESIZE = 50;


export class ResourcePageService extends PageableService<ResourceListDto>{
    constructor(
        protected http: HttpApiService,
        protected endpoint: string,
        protected pageSize?: number
    ) {
        super(http, endpoint, pageSize);
    }

    protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null): HttpParams {
        return super
            .createHttpParams(pageNumber, pageSize, sortingParams)
            .set('cols', 'hasChildren');
    }
}

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    constructor(
        protected http: HttpTechLocationApiService
    ) { }

    createService(): ResourcePageService {
        return new ResourcePageService(this.http, 'location/me/resource', PAGESIZE);
    }

    createRootService(): ResourcePageService {
        return new ResourcePageService(this.http, 'location/me/resource/root', PAGESIZE);
    }

    createChildService(parentId: number): ResourcePageService {
        return new ResourcePageService(this.http, `resource/${parentId}/children`, PAGESIZE);
    }
}
