import { from, forkJoin, of, interval } from "rxjs";
import { flatMap, map, delay } from "rxjs/operators";



export class PermissionData {
    constructor(
        public hasPermission: boolean = false,
        public needsPermission: boolean = true,
        public loadsPermission: boolean = false
    ) { }

    async fetchPermission(fetch: (() => Promise<boolean>)) : Promise<boolean> {
        if (this.needsPermission == true) {
            this.loadsPermission = true;

            try {
                this.hasPermission = await forkJoin(
                    of(fetch()),
                    of(null).pipe(delay(750))
                ).pipe(map(x => x[0])).toPromise();
            }
            catch (error) {
                this.hasPermission = false;
                console.error(this, error);
            }
            finally {
                this.loadsPermission = false;
            }

        } else {
            this.hasPermission = false;
        }

        return this.hasPermission;
    }
}