//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';

export class CustomDefaultDto extends ValidationDto implements IIdentityData
{
	@Column() public customValueId: number;
	@Column() public type: number;
	@Column() public customPropertyId: number;
	@Column() public tenantId: number;
}
export class CustomCheckboxDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: boolean;
}
export class CustomDateDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() @DateColumn() public value: string;
}
export class CustomDropDownDefaultValueDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: string;
	@Column() public isSelected: boolean;
	@Column() public position: number;
}
export class CustomEmailDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: string;
}
export class CustomMultilineDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: string;
}
export class CustomNumericDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: number;
}
export class CustomPhoneDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: string;
}
export class CustomTextDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: string;
}
export class CustomTimeDefaultDto extends CustomDefaultDto implements IIdentityData
{
	@Column() public value: number;
}
