//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { IStereotypedDto } from './Stereotyping/Stereotypes';
import { ValidationDto } from '../Validations';
import { LocationSimpleDto } from './MasterData/Locations';
import { FilledCustomValueDto } from './Stereotyping/FilledCustomValues';
import { SolutionTemplateDto } from './Tickets/Priorities';
import { EscalationRuleDto } from './Tickets/Escalations';
import { AttachmentForTechDto } from './Attachments';
import { LinkDto } from './Links';
import { LocationSimpleListDto } from './MasterData/Locations';
import { StereotypeSimpleListDto } from './Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from './Stereotyping/FilledCustomValues';

export class ResourceSimpleDto
{
	@Column() public resourceId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class ResourceDto extends ValidationDto implements IIdentityData, IStereotypedDto
{
	@Column() public resourceId: number;
	@Column() public name: string;
	@Column() public parentId: number;
	@Column() public parent: ResourceSimpleDto;
	@Column() public locationId: number;
	@Column() public location: LocationSimpleDto;
	@Column() @DateColumn() public isInProgressSince: string;
	@Column() public hasChildren: boolean;
	@Column() public tenantId: number;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public path: ResourcePathPartDto[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
	@Column() public solutionTemplates: SolutionTemplateDto[];
	@Column() public rules: EscalationRuleDto[];
	@Column() public attachments: AttachmentForTechDto[];
	@Column() public links: LinkDto[];
}
export class ResourceInTicketDto
{
	@Column() public resourceId: number;
	@Column() public name: string;
	@Column() public locationId: number;
	@Column() public location: LocationSimpleDto;
	@Column() public path: ResourcePathPartDto[];
	@Column() public tenantId: number;
}
export class ResourceListDto implements IIdentityData
{
	@Column() @Filterable() public resourceId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public parentId: number;
	@Column() @Filterable() public locationId: number;
	@Column() @Filterable() @DateColumn() public isInProgressSince: string;
	@Column() @Filterable() public location: LocationSimpleListDto;
	@Column() public pathJson: string;
	@Column() public path: ResourcePathPartDto[];
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public isArchived: boolean;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
	@Column(true) @Filterable() public hasChildren: boolean;
	@Column() @Filterable() public inventoryNumber: string;
}
export class ResourcePathPartDto
{
	@Column() public index: number;
	@Column() public id: number;
	@Column() public name: string;
}
export class ResourceSimpleListDto implements IIdentityData
{
	@Column() @Filterable() public resourceId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public inventoryNumber: string;
	@Column() @Filterable() public locationId: number;
	@Column() @Filterable() public location: LocationSimpleListDto;
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public pathJson: string;
	@Column() public path: ResourcePathPartDto[];
}
