//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ChatMemberDto } from './Chats/ChatMembers';
import { IArchiveDto } from '../Objects';
import { IIdentityData } from '../Objects';
import { ChatMemberListDto } from './Chats/ChatMembers';
import { ValidationDto } from '../Validations';

export class ChatDto
{
	@Column() public chatId: number;
	@Column() public title: string;
	@Column() public isArchived: boolean;
	@Column() public chatMembers: ChatMemberDto[];
}
export class ChatListDto implements IArchiveDto, IIdentityData
{
	@Column() @Filterable() public chatId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public unreadCount: number;
	@Column() @Filterable() public isArchived: boolean;
	@Column() @DateColumn() public lastMessageDateTime: string;
	@Column() @Filterable() public lastMessagePreview: string;
	@Column(true) public chatMembers: ChatMemberListDto[];
}
export class ChatMemberMessageDto
{
	@Column() @DateColumn() public time: string;
	@Column() public status: number;
	@Column() public member: ChatMemberListDto;
}
export class InitChatDto extends ValidationDto implements IIdentityData
{
	@Column() public chatId: number;
	@Column() public title: string;
	@Column() public initializer: ChatMemberDto;
	@Column() public additionalChatMembers: ChatMemberDto[];
	@Column() public tenantId: number;
	@Column() public externalId: string;
}
export class SimpleChatDto
{
	@Column() public chatId: number;
	@Column() public title: string;
	@Column() public isArchived: boolean;
	@Column() public externalId: string;
}
