import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import { environment } from "../../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HttpApiTimeoutInterceptorService implements HttpInterceptor {
    constructor(
        @Inject(environment.interceptor.apiDefaultTimeoutSecIdentifier) protected timeoutSeconds?: number
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(timeout((this.timeoutSeconds || 30) * 1000));
    }
}