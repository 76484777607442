import { StereotypeListDto, FilledFormFieldCreateDto } from "../../../server/index-objects";
import { CustomPropertyType } from "../../../server/Enums";

export class FilledStereotype {
    constructor(
        public stereotype: StereotypeListDto,
        public values: FilledStereotypeForm[]
    ) { }
}

export class FilledStereotypeForm {
    constructor(
        public propertyId: number,
        public type: CustomPropertyType,
        public value: FilledFormFieldCreateDto
    ) { }
}