//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { QueueDto } from './Queues';

export class TaskGenerationQueueDto extends QueueDto
{
	@Column() public taskId: number;
}
