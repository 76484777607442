//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class FileDto implements IIdentityData
{
	@Column() public fileId: number;
	@Column() public tenantId: number;
	@Column() public path: string;
	@Column() public hash: string;
	@Column() @DateColumn() public creationDateTime: string;
	@Column() public name: string;
	@Column() public mimeType: string;
	@Column() public size: number;
	@Column() public uri: string;
}
