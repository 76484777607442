//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';

export class DateTimePatternDto extends ValidationDto implements IIdentityData
{
	@Column() public dateTimePatternId: number;
	@Column() public type: number;
	@Column() public useNextOpeningDay: boolean;
	@Column() @DateColumn() public lastModified: string;
	@Column() public plannedTimes: PlannedTimeDto[];
}
export class DailyDateTimePatternDto extends DateTimePatternDto implements IIdentityData
{
	@Column() @DateColumn() public startDate: string;
	@Column() @DateColumn() public endDate: string;
	@Column() public useOpeningDays: boolean;
	@Column() public monday: boolean;
	@Column() public tuesday: boolean;
	@Column() public wednesday: boolean;
	@Column() public thursday: boolean;
	@Column() public friday: boolean;
	@Column() public saturday: boolean;
	@Column() public sunday: boolean;
}
export class MonthlyDateTimePatternDto extends DateTimePatternDto implements IIdentityData
{
	@Column() @DateColumn() public startDate: string;
	@Column() @DateColumn() public endDate: string;
	@Column() public day: number;
	@Column() public repeat: number;
}
export class MonthlyWeekdayDateTimePatternDto extends DateTimePatternDto implements IIdentityData
{
	@Column() @DateColumn() public startDate: string;
	@Column() @DateColumn() public endDate: string;
	@Column() public weekday: number;
	@Column() public weekdayCount: number;
	@Column() public repeat: number;
}
export class PlannedTimeDto extends ValidationDto
{
	@Column() public plannedTimeId: number;
	@Column() public time: string;
	@Column() public datetimePatternId: number;
}
export class SingleDateTimePatternDto extends DateTimePatternDto implements IIdentityData
{
	@Column() @DateColumn() public date: string;
}
export class WeeklyDateTimePatternDto extends DateTimePatternDto implements IIdentityData
{
	@Column() @DateColumn() public startDate: string;
	@Column() @DateColumn() public endDate: string;
	@Column() public repeat: number;
	@Column() public useOpeningDays: boolean;
	@Column() public monday: boolean;
	@Column() public tuesday: boolean;
	@Column() public wednesday: boolean;
	@Column() public thursday: boolean;
	@Column() public friday: boolean;
	@Column() public saturday: boolean;
	@Column() public sunday: boolean;
}
