//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';
import { LocationInfoDto } from './MasterData/Locations';
import { LocationGroupInfoDto } from './MasterData/LocationGroups';
import { OrgaAttachmentDto } from './Attachments';
import { IPlaceDto } from './MasterData';
import { ContactGroupSimpleDto } from './MasterData/ContactGroups';
import { LocationRelationDto } from './MasterData/Locations';
import { LocationGroupRelationDto } from './MasterData/LocationGroups';
import { OrgaAttachmentListDto } from './Attachments';
import {InfoState} from '../../Enums';

export class InfoContactDto
{
	@Column() public contactId: number;
	@Column() public infoId: number;
	@Column() @DateColumn() public createdAt: string;
}
export class InfoCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public infoId: number;
	@Column() @DateColumn() public dateTime: string;
	@Column() public content: string;
	@Column() public title: string;
	@Column() public answerEnabled: boolean;
	@Column() public requestRead: boolean;
	@Column() public contactGroupId: number;
	@Column() public tenantId: number;
	@Column() public locations: LocationInfoDto[];
	@Column() public locationGroups: LocationGroupInfoDto[];
	@Column() public attachments: OrgaAttachmentDto[];
}
export class InfoDto implements IPlaceDto, IIdentityData
{
	@Column() public infoId: number;
	@Column() @DateColumn() public dateTime: string;
	@Column() public content: string;
	@Column() public title: string;
	@Column() public answerEnabled: boolean;
	@Column() public requestRead: boolean;
	@Column() public sender: ContactGroupSimpleDto;
	@Column() public attachments: OrgaAttachmentDto[];
	@Column() public tenantId: number;
}
export class InfoListDto implements IIdentityData
{
	@Column() @Filterable() public infoId: number;
	@Column() @Filterable() @DateColumn() public dateTime: string;
	@Column(true) @Filterable() public content: string;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public answerEnabled: boolean;
	@Column() @Filterable() public requestRead: boolean;
	@Column() public sender: ContactGroupSimpleDto;
	@Column() public attachments: OrgaAttachmentListDto[];
	@Column() @Filterable() public tenantId: number;
	@Column() public readCount: number;
	@Column() public state: InfoState;
}
export class InfoForContactListDto extends InfoListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
