//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { FilterDto } from '../Filters';
import { ValidationDto } from '../Validations';

export class OverviewTaskDto implements IIdentityData
{
	@Column() @Filterable() public id: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public state: number;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public countPending: number;
	@Column() @Filterable() public countOpen: number;
	@Column() @Filterable() public countOverdue: number;
	@Column() @Filterable() public countEscalated: number;
	@Column() @Filterable() public countDone: number;
	@Column() @Filterable() public countCanceled: number;
}
export class OverviewTaskRequestDto
{
	@Column() public filter: FilterDto[];
	@Column() public restrictedLocations: number[];
	@Column() public restrictedTasks: number[];
}
export class TaskJobCountListDto
{
	@Column() @Filterable() public taskId: number;
	@Column() @Filterable() public taskTitle: string;
	@Column() @Filterable() public locationId: number;
	@Column() @Filterable() public locationName: string;
	@Column() @Filterable() public countStart: number;
	@Column() @Filterable() public countOverdue: number;
	@Column() @Filterable() public countEscalated: number;
	@Column() @Filterable() public countDone: number;
	@Column() @Filterable() public countCanceled: number;
	@Column() public identifier: string;
	@Column() @Filterable() @DateColumn() public date: string;
}
export class TaskJobGraphDto
{
	@Column() public dataPoints: TaskJobCountListDto[];
}
export class TaskJobDataRequestDto extends ValidationDto
{
	@Column() @DateColumn() public start: string;
	@Column() @DateColumn() public end: string;
	@Column() public filter: FilterDto[];
	@Column() public graphKind: number;
}
