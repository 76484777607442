import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { AppShortcutDto, CurrentAppOverviewDto } from "../../../models/client/api/shortcuts";
import { HttpLocationApiService } from "../http/http-location-api/http-location-api.service";


@Injectable({
    providedIn: 'root'
})
export class ShortcutService {
    private endpoint: string = "appoverview";

    constructor(
        protected http: HttpLocationApiService
    ) { }

    async getShortcuts(): Promise<AppShortcutDto[]> {
        return this.http.get<CurrentAppOverviewDto>(`${this.endpoint}/location/me`).pipe(
            map((response) => response.body.shortcuts.sort((a, b) => a.position - b.position))
        ).toPromise();
    }
}
