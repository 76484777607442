//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IArchiveDto } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { ContactSimpleDto } from '../MasterData/Contact';

export class PriorityDto implements IIdentityData, IArchiveDto
{
	@Column() public id: number;
	@Column() public priorityId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public isArchived: boolean;
}
export class PriorityListDto implements IIdentityData, IArchiveDto
{
	@Column() public id: number;
	@Column() @Filterable() public priorityId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public prioritySetId: number;
	@Column() @Filterable() public isArchived: boolean;
}
export class PrioritySetDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public prioritySetId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public priorities: PriorityDto[];
}
export class PrioritySetListDto implements IIdentityData
{
	@Column() public id: number;
	@Column() @Filterable() public prioritySetId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
}
export class PrioritySetSimpleDto
{
	@Column() public name: string;
	@Column() public prioritySetId: number;
	@Column() public tenantId: number;
}
export class PrioritySimpleDto
{
	@Column() public name: string;
	@Column() public priorityId: number;
	@Column() public tenantId: number;
}
export class PrioritySimpleListDto implements IIdentityData, IArchiveDto
{
	@Column() public id: number;
	@Column() @Filterable() public priorityId: number;
	@Column() @Filterable() public name: string;
	@Column() public tenantId: number;
	@Column() public isArchived: boolean;
}
export class SolutionTemplateDto extends ValidationDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public solutionTemplateId: number;
	@Column() public isInherited: boolean;
	@Column() public isSolutionOffsetInherited: boolean;
	@Column() public ownSolutionOffSet: string;
	@Column() public inheritedSolutionOffSet: string;
	@Column() public isSolutionContactInherited: boolean;
	@Column() public ownSolutionContactId: number;
	@Column() public ownSolutionContact: ContactSimpleDto;
	@Column() public inheritedSolutionContactId: number;
	@Column() public inheritedSolutionContact: ContactSimpleDto;
	@Column() public priorityId: number;
	@Column() public priority: PrioritySimpleDto;
	@Column() public tenantId: number;
}
