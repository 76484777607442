
export class SearchListDto {
    constructor(
        public tenantId: number,
        public searchListId: number,
        public title: string,
        public items: string[]
    ) { }
}

