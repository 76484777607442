//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';

export class StateDto extends ValidationDto implements IIdentityData
{
	@Column() public stateId: number;
	@Column() public color: string;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class StateListDto implements IIdentityData
{
	@Column() @Filterable() public stateId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public color: string;
	@Column() @Filterable() public tenantId: number;
}
export class StateSimpleDto implements IIdentityData
{
	@Column() public stateId: number;
	@Column() public name: string;
	@Column() public color: string;
	@Column() public tenantId: number;
}
export class StateMachineDto implements IIdentityData
{
	@Column() public stateMachineId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public stateAfterRejectedMission: StateSimpleDto;
	@Column() public stateAfterCanceledMission: StateSimpleDto;
	@Column() public stateAfterLastMissionDone: StateSimpleDto;
	@Column() public stateAfterReceivedEmail: StateSimpleDto;
	@Column() public transitions: StateTransitionDto[];
}
export class StateMachineListDto implements IIdentityData
{
	@Column() public stateMachineId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class StateMachineSimpleDto implements IIdentityData
{
	@Column() public stateMachineId: number;
	@Column() public name: string;
}
export class StateMachineSimpleListDto implements IIdentityData
{
	@Column() public stateMachineId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class StateSimpleListDto implements IIdentityData
{
	@Column() @Filterable() public stateId: number;
	@Column() @Filterable() public name: string;
	@Column() public color: string;
	@Column() public tenantId: number;
}
export class StateTransitionDto implements IIdentityData
{
	@Column() public stateTransitionId: number;
	@Column() public tenantId: number;
	@Column() public inStateId: number;
	@Column() public inState: StateDto;
	@Column() public outStateId: number;
	@Column() public outState: StateDto;
}
