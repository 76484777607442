//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class DashboardDto implements IIdentityData
{
	@Column() public dashboardId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public items: DashboardItemDto[];
}
export class DashboardItemDto
{
	@Column() public position: number;
	@Column() public type: number;
}
export class DashboardListDto implements IIdentityData
{
	@Column() @Filterable() public dashboardId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
}
