//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { FilledCustomValueDto } from './FilledCustomValues';
import { IIdentityData } from '../../Objects';
import { IArchiveDto } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { CustomSetReferenceDto } from './CustomProperties';
import { PrioritySetSimpleDto } from '../Tickets/Priorities';
import { StateMachineSimpleDto } from '../StateMachines';
import { PrioritySetListDto } from '../Tickets/Priorities';
import { StateMachineListDto } from '../StateMachines';
import { CustomSetReferenceListDto } from './CustomProperties';
import { CustomPropertyViewSetDto } from './CustomProperties';

export class AppEntityTypeDto
{
	@Column() public entityType: number;
	@Column() public stereotypes: StereotypeViewDto[];
}
export class IStereotypedDto
{
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class PreviewRequestDto
{
	@Column() public parentId: number;
	@Column() public stereotypeId: number;
	@Column() public entity: number;
}
export class StereotypedShadowDto implements IStereotypedDto
{
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class StereotypeSimpleDto implements IIdentityData, IArchiveDto
{
	@Column() public stereotypeId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public isArchived: boolean;
	@Column() public entityType: number;
	@Column() public rowVersion: number[];
}
export class StereotypeDto extends ValidationDto implements IIdentityData, IArchiveDto
{
	@Column() public stereotypeId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public isDefault: boolean;
	@Column() public isArchived: boolean;
	@Column() public entityType: number;
	@Column() public rowVersion: number[];
	@Column() public customPropertySets: CustomSetReferenceDto[];
	@Column() public parentId: number;
	@Column() public parent: StereotypeSimpleDto;
	@Column() public prioritySetId: number;
	@Column() public prioritySet: PrioritySetSimpleDto;
	@Column() public stateMachineId: number;
	@Column() public stateMachine: StateMachineSimpleDto;
	@Column() public hasChildren: boolean;
}
export class StereotypeListDto implements IIdentityData, IArchiveDto
{
	@Column() @Filterable() public stereotypeId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public entityType: number;
	@Column() public rowVersion: number[];
	@Column(true) public hasChildren: boolean;
	@Column() @Filterable() public isArchived: boolean;
	@Column() @Filterable() public isDefault: boolean;
	@Column() @Filterable() public prioritySetId: number;
	@Column() public prioritySet: PrioritySetListDto;
	@Column() @Filterable() public stateMachineId: number;
	@Column() public stateMachine: StateMachineListDto;
	@Column(true) public customPropertySets: CustomSetReferenceListDto[];
}
export class StereotypeSimpleListDto implements IIdentityData, IArchiveDto
{
	@Column() @Filterable() public stereotypeId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
	@Column() public rowVersion: number[];
	@Column() @Filterable() public isArchived: boolean;
}
export class StereotypeViewDto
{
	@Column() public stereotypeId: number;
	@Column() public customPropertySets: CustomPropertyViewSetDto[];
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public entityType: number;
}
