import { ProcessState, ProcessResource, ProcessPriority } from "./entities";
import { FilledCustomValueDto, ChatDto } from "../../../server/index-objects";

export class TicketProcessStarter {
    constructor(
        public processId: number,
        public tenantId: number,
        public ticket: TicketByProcess
    ) { }
}

export class InitializedChat {
    constructor(
        public chatId: number,
        public title: string,
    ) { }
}

export class CreateTicketByProcessResponse {
    constructor(
        public missionId: number,
        public ticketId: number,
        public chat: InitializedChat
    ) { }
}

export class TicketByProcess {
    constructor(
        public title: string,
        public description: string,
        public currentState: ProcessState,
        public resource: ProcessResource,
        public priority: ProcessPriority,
        public stereotypeId: number,
        public stereotypeRowVersion: number[],
        public customPropertyValues: FilledCustomValueDto[] = []
    ) { }
}
