export class ChatStarters {
    constructor(
        public starters: ChatStarter[]
    ) { }
}

export class ChatStarter {
    constructor(
        public type: ChatStarterType,
        public name: string,
        public processId: number = null,
        public formId: number = null,
        public definitionId: number = null
    ) { }
}

export enum ChatStarterType { 
	Form = 1,
	Process = 2,
    Definition = 3
}