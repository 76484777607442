//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IHtmlViewData } from '../../Objects';

export class EmailChatMessageDto implements IEmailTemplateDto, IHtmlViewData
{
	@Column() public message: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
}
export class EmailImportDto implements IEmailTemplateDto, IHtmlViewData
{
	@Column() public message: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public messages: EmailImportMessageDto[];
	@Column() public messagesAsTemplate: string;
}
export class EmailImportMessageDto implements IHtmlViewData
{
	@Column() public message: string;
	@Column() public level: number;
}
export class EmailResetPasswordDto implements IEmailTemplateDto, IHtmlViewData
{
	@Column() public message: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public resetButtonHtml: string;
}
export class EmailSolutionNoteMailDto implements IEmailTemplateDto, IHtmlViewData
{
	@Column() public description: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
}
export class EmailTaskJobChangedStatusAlertDto implements IEmailTaskJobDto, IEmailTemplateDto, IHtmlViewData
{
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public locationName: string;
	@Column() public taskName: string;
	@Column() public targetState: string;
}
export class EmailTaskJobPercentageDto implements IEmailTaskJobDto, IEmailTemplateDto, IHtmlViewData
{
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public locationName: string;
	@Column() public taskName: string;
	@Column() public percentage: number;
}
export class EmailTaskJobTimeAlertDto implements IEmailTaskJobDto, IEmailTemplateDto, IHtmlViewData
{
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
	@Column() public locationName: string;
	@Column() public taskName: string;
	@Column() public minutes: number;
}
export class IEmailTaskJobDto implements IEmailTemplateDto, IHtmlViewData
{
	@Column() public locationName: string;
	@Column() public taskName: string;
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
}
export class IEmailTemplateDto implements IHtmlViewData
{
	@Column() public tenantName: string;
	@Column() public domain: string;
	@Column() public senderName: string;
	@Column() public subject: string;
}
