//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ILocationRelationDto } from './MasterData';
import { ILocationGroupRelationDto } from './MasterData';

export class FormLocationDto implements ILocationRelationDto
{
	@Column() public locationId: number;
	@Column() public formId: number;
}
export class FormLocationGroupDto implements ILocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public formId: number;
}
export class FormSimpleDto
{
	@Column() public formId: number;
	@Column() public title: string;
}
