import {Component, OnInit} from '@angular/core';
import {ProcessResource, ResourceTicketSkeletonSelector, ResourceTicketSkeletonSelectorType} from '../../models/client';
import {FilterDto, ResourceListDto, ResourcePathPartDto} from '../../models/server/index-objects';
import {ResourcePageService, ResourceService} from '../../services/api/process/resource.service';
import {ModalService} from '../../services/ui/modal.service';
import {ResourceNode} from './resource-node/resource-node.component';
import {NavParams} from '@ionic/angular';

@Component({
  selector: 'app-resource-picker',
  templateUrl: './resource-picker.page.html',
  styleUrls: ['./resource-picker.page.scss']
})
export class ResourcePickerPage implements OnInit {

  public nodes: ResourceNode[];
  public rootService: ResourcePageService;
  public isComplete: boolean;
  public selector: ResourceTicketSkeletonSelector = null;
  public filter: string;
  public isLoading:boolean = false;
  public isOutdated:boolean = false;
  public isSearchable:boolean = false;

  constructor(
    protected modalService: ModalService,
    protected resourceService: ResourceService,
    protected navParams: NavParams
  ) {
    this.rootService = resourceService.createRootService();
    this.selector = navParams.get('selector');
    this.isSearchable =
        this.selector.type === ResourceTicketSkeletonSelectorType.All ||
        this.selector.type === ResourceTicketSkeletonSelectorType.RestrictedByType;
  }

  async ngOnInit() {
    await this.loadRoot();
  }

  clear() {
  }

  exit() {
    this.clear();
    this.modalService.dismissModal();
  }

  choose(node: ResourceNode) {
    this.done(node);
  }

  async loadMore(node: ResourceNode = null) {
    if (node) {
      await this.openChilds(node);
    } else {
      await this.loadRoot();
    }
  }

  async openChilds(node: ResourceNode) {
    try {
      node.isLoading = true;
      this.isLoading = true;

      const response = await this.fetchNode(node.childService, node.depth);
      node.children = response.children;
      node.isComplete = response.isComplete;

      node.isLoading = false;
      this.isLoading = false;
    } catch (error) {
      node.isLoading = false;
      this.isLoading = false;
      await (await this.modalService.createErrorAlertWithDetail(error)).present();
    }
  }

  async loadRoot() {
    try {
      if(this.isLoading == true) {
        // user searched again
        this.isOutdated = true;
        return;
      }

      this.isLoading = true;

      const response = await this.fetchNode(this.rootService);
      this.nodes = response.children;
      this.isComplete = response.isComplete;

      this.isLoading = false;
      if(this.isOutdated === true) {
        this.isOutdated = false;
        await this.loadRoot();
      }
    } catch (error) {
      this.isLoading = false;
      this.isOutdated = false;
      await (await this.modalService.createErrorAlertWithDetail(error)).present();
    }
  }

  async fetchNode(service: ResourcePageService, depth: number = -1) {
    if (this.selector?.type === ResourceTicketSkeletonSelectorType.Whitelisted) {
      if (depth > -1) {
        return <ResourceNode> {
          isComplete: true
        };
      }

      const whitelistedNodes = this.selector.whitelist.map(x => this.mapResourceNode(<ResourceListDto> {
        ...x,
        path: [<ResourcePathPartDto> {
          index: 0,
          id: x.resourceId,
          name: x.name
        }]
      }, 0));

      return <ResourceNode> {
        isComplete: true,
        children: whitelistedNodes
      };
    } else {
      service.setFilters();

      if (this.selector?.type === ResourceTicketSkeletonSelectorType.RestrictedByType) {
        service.addFilters(<FilterDto><any>{
          type: 2,
          combinedAs: 2,
          children: this.selector.stereotypes.map(x =>
              <FilterDto>{
                property: 'stereotypeId',
                value: x.stereotypeId.toString()
              }
          )
        });
      }

      if (this.filter) {
        service.addFilters(<FilterDto>{
          property: 'name',
          value: this.filter
        });
      }

      await service.next();

      const nodes = service.items.map(x => this.mapResourceNode(x, depth + 1));

      for (let i = 0; i < nodes.length; i++) {
        const resource = nodes[i].resource;

        resource.path = resource.path
          .filter(p => p.id !== resource.resourceId)
          .sort(p => p.index);
      }

      return <ResourceNode> {
        isComplete: service.hasNextPage() === false,
        children: nodes
      };
    }
  }

  private mapResourceNode(resource: ResourceListDto, depth: number): ResourceNode {
    return <ResourceNode>{
      resource: resource,
      childService: this.resourceService.createChildService(resource.resourceId),
      depth: depth
    };
  }

  done(node: ResourceNode = null) {
    this.modalService.dismissModal(<ProcessResource>{
      name: node?.resource?.name,
      resourceId: node?.resource?.resourceId
    });
  }

  async onSearch($event: any) {
    this.filter = $event.detail.value;

    if (this.filter) {
      this.rootService = this.resourceService.createService();
    } else {
      this.filter = null;
      this.rootService = this.resourceService.createRootService();
    }

    await this.loadRoot();
  }
}
