import { AppNotification } from "./app-notification";

export class InfoNotification extends AppNotification {
    constructor(
        public title: string = null,
        public message: string = null,
        public foreground: boolean = false,
        public infoId: number = 0
    ) {
        super(title, message, foreground);
    }
}