//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { AppRoleDto } from './Permissions';
import { ValidationDto } from '../Validations';
import { AppRoleListDto } from './Permissions';

export class SimpleLoginProviderDto
{
	@Column() public loginProviderId: number;
	@Column() public name: string;
}
export class AzureLoginProviderListDto
{
	@Column() public loginProviderId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public defaultRoleId: number;
	@Column() public defaultRole: AppRoleDto;
	@Column() @Filterable() public provider: number;
	@Column() @Filterable() public azureTenantId: string;
}
export class LoginProviderDto extends ValidationDto
{
	@Column() public loginProviderId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public defaultRoleId: number;
	@Column() public defaultRole: AppRoleDto;
	@Column() @Filterable() public type: number;
	@Column() public tenantId: number;
}
export class LoginProviderListDto
{
	@Column() public loginProviderId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public defaultRoleId: number;
	@Column() public defaultRole: AppRoleDto;
	@Column() @Filterable() public provider: number;
}
export class LoginProviderRoleDto extends ValidationDto
{
	@Column() public loginProviderRoleId: number;
	@Column() public roleId: number;
	@Column() public role: AppRoleDto;
	@Column() public loginProviderId: number;
	@Column() public loginProvider: SimpleLoginProviderDto;
	@Column() public tenantId: number;
	@Column() public rowVersion: number[];
}
export class LoginProviderRoleListDto
{
	@Column() public loginProviderRoleId: number;
	@Column() @Filterable() public roleId: number;
	@Column() public role: AppRoleListDto;
	@Column() @Filterable() public loginProviderId: number;
	@Column() public rowVersion: number[];
}
export class AzureLoginProviderDto extends LoginProviderDto
{
	@Column() public azureTenantId: string;
}
export class AzureLoginProviderRoleDto extends LoginProviderRoleDto
{
	@Column() public azureGroupName: string;
}
export class AzureLoginProviderRoleListDto extends LoginProviderRoleListDto
{
	@Column() @Filterable() public azureGroupName: string;
}
