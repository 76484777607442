import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IdentityResult, LoginTypes, TokenHelper } from 'models/client';
import { ForbiddenError } from '../../../models/client/errors/forbidden-error';
import { TokenDto } from '../../../models/server/DataTransferObject/Objects/Authentication';
import { GeneralSettingDto } from '../../../models/server/index-objects';
import { BadgeLoginPage } from '../../../pages/login/badge-login/badge-login.page';
import { PasswordLoginPage } from '../../../pages/login/password-login/password-login.page';
import { PinLoginPage } from '../../../pages/login/pin-login/pin-login.page';
import { AuthenticationService } from '../../api';
import { LoginService } from '../../api/auth/login.service';
import { GeneralSettingService } from '../../api/setting/general-setting.service';

@Injectable({
  providedIn: 'root'
})
export class LoginDialogService {

  public generalSetting: GeneralSettingDto;

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthenticationService,
    private loginService: LoginService,
    private generalSettingService: GeneralSettingService
  ) {
    this.generalSettingService.cache().subscribe(x => this.generalSetting = x);
  }

  public async getIdentityFromUserLogin(type: LoginTypes = null): Promise<IdentityResult> {
    if (!type) {
      type = this.generalSetting?.defaultLoginMethod?.value ?? LoginTypes.Pin;
    }

    const modalConfig = type == LoginTypes.Badge
      ? { component: BadgeLoginPage, cssClass: "modal-fullscreen" }
      : { component: PinLoginPage } ;
      
    const loginModal = await (await this.modalCtrl.create(modalConfig));
    loginModal.present();

    const loginResponse = await loginModal.onDidDismiss();
    if (!loginResponse || !loginResponse.data) {
      return null;
    }

    if (loginResponse.data.changeLogin) {
      return this.getIdentityFromUserLogin(loginResponse.data.changeLogin);
    }

    const appToken: TokenDto = <TokenDto>loginResponse.data.token;
    if (appToken) {
      const userIdentity = TokenHelper.getIdentityFromAppToken(appToken);

      if (userIdentity != null) {
        return userIdentity;
      }
    }

    throw new Error("identity not found");
  }

  public async getIdentityFromPasswordLogin(): Promise<IdentityResult> {
    const loginModal = await (await this.modalCtrl.create({ component: PasswordLoginPage }));
    loginModal.present();

    const loginResponse = await loginModal.onDidDismiss();
    if (!loginResponse || !loginResponse.data) {
      return null;
    }

    let appToken: TokenDto = <TokenDto>loginResponse.data;
    const currentTenantId = this.authService.getTenantId();

    if (!appToken)
      throw new Error("identity not found"); //TODO

    if (!appToken.info.tenants || appToken.info.tenants.some(x => x.tenantId == currentTenantId) == false)
      throw new ForbiddenError();

    if (appToken.info.tenants.length > 1) {
      const tenantRoleIds = appToken.info.tenants
        .find(x => x.tenantId == currentTenantId)
        .roles
        .map(x => x.roleId);

      const refreshResponse = await this.loginService.loginRefresh(appToken.refreshToken, tenantRoleIds);
      appToken = refreshResponse.body;
    }

    if (!appToken)
      throw new Error("identity not found"); //TODO

    const userIdentity = TokenHelper.getIdentityFromAppToken(appToken, false);

    if (userIdentity != null) {
      return userIdentity;
    }

  }
}
