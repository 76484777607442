//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { LocationSimpleListDto } from './MasterData/Locations';

export class BookAGuestCardDto
{
	@Column() public tag: string;
	@Column() public count: number;
	@Column() public locationId: number;
	@Column() public contactInfo: string;
	@Column() public tableInfo: string;
}
export class GuestCardDto implements IIdentityData
{
	@Column() public guestCardId: number;
	@Column() public tag: string;
	@Column() public tenantId: number;
}
export class GuestCardSimpleListDto
{
	@Column() public guestCardId: number;
	@Column() public tag: string;
}
export class GuestCardTagDto
{
	@Column() public tag: string;
}

export class GuestCardBookingDto implements IIdentityData
{
	@Column() public guestCardBookingId: number;
	@Column() @DateColumn() public createdAt: string;
	@Column() @DateColumn() public endDate: string;
	@Column() public count: number;
	@Column() public guestCard: GuestCardDto;
	@Column() public contactInfo: string;
	@Column() public tableInfo: string;
	@Column() public tenantId: number;
}
export class GuestCardBookingListDto
{
	@Column() @Filterable() public guestCardBookingId: number;
	@Column() @Filterable() public createdByUserId: number;
	@Column() @Filterable() public count: number;
	@Column() @Filterable() @DateColumn() public createdAt: string;
	@Column() @Filterable() @DateColumn() public endDate: string;
	@Column() @Filterable() public tableInfo: string;
	@Column() @Filterable() public contactInfo: string;
	@Column() @Filterable() public guestCardId: number;
	@Column() public guestCard: GuestCardSimpleListDto;
	@Column() @Filterable() public locationId: number;
	@Column() public location: LocationSimpleListDto;
}