//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ValidationDto } from '../Validations';

export class ConditionDto extends ValidationDto
{
	@Column() public conditionId: number;
	@Column() public type: number;
}
export class ChangedStatusConditionDto extends ConditionDto
{
	@Column() public targetState: number;
}
export class PercentageConditionDto extends ConditionDto
{
	@Column() public percent: number;
}
export class TimeConditionDto extends ConditionDto
{
	@Column() public conditionOffset: string;
}
