//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class AuditListDto implements IIdentityData
{
	@Column() @Filterable() public auditId: number;
	@Column() @Filterable() public kind: number;
	@Column() @Filterable() @DateColumn() public createdAt: string;
	@Column() @Filterable() public entityType: number;
	@Column() @Filterable() public entityId: number;
	@Column() @Filterable() public createdByUserName: string;
	@Column() @Filterable() public createdByUserId: number;
	@Column() @Filterable() public tenantId: number;
}
