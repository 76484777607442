//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../../Attributes';
import { IIdentityData } from '../../../Objects';
import { ValidationDto } from '../../../Validations';
import { FormFieldCreateDto } from './Fields';

export class FormRowCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public formRowId: number;
	@Column() public title: string;
	@Column() public position: number;
	@Column() public type: number;
	@Column() public fields: FormFieldCreateDto[];
}
export class RepeatableFormRowCreateDto extends FormRowCreateDto implements IIdentityData
{
	@Column() public maxRepeat: number;
	@Column() public minRepeat: number;
	@Column() public repeatLabel: string;
}
export class SingleFormRowCreateDto extends FormRowCreateDto implements IIdentityData
{
}
