import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { LoginService } from 'services/api/auth/login.service';
import { LoginTypes } from '../../../models/client';
import { LoginByPinDto } from '../../../models/server/DataTransferObject/Objects/Permissions';
import { AuthenticationService } from '../../../services/api/auth/authentication.service';
import { ModalService } from '../../../services/ui/modal.service';
import { AppPermissions } from '../../../models/server/index-enums';
import { Observable } from 'rxjs';
import { PermissionService } from '../../../services/api/permission/permission.service';
import { GeneralSettingService } from '../../../services/api/setting/general-setting.service';
import { GeneralSettingDto } from '../../../models/server/index-objects';

@Component({
  selector: 'app-pin-login',
  templateUrl: './pin-login.page.html',
  styleUrls: ['./pin-login.page.scss'],
})
export class PinLoginPage {

  public pinChars: string[] = [];
  public pinChar1: string = "";
  public pinChar2: string = "";
  public pinChar3: string = "";
  public pinChar4: string = "";
  public pinChar5: string = "";

  public displayBadgeLogin: boolean;

  constructor(
    private modalService: ModalService,
    private loginService: LoginService,
    private authService: AuthenticationService,
    private permissionService: PermissionService,
    private generalSettingService: GeneralSettingService
  ) {
    this.generalSettingService.cache().subscribe(x => this.updateSettings(x));
  }

  private updateSettings(settings: GeneralSettingDto): void {
    const setting = settings?.availableLoginMethod?.value ?? (LoginTypes.Pin | LoginTypes.Badge);
    this.displayBadgeLogin = this.permissionService.hasPermission(AppPermissions.UseBadgeLogin) && (setting & LoginTypes.Badge) == LoginTypes.Badge;
  }

  async addPin(char: number) {
    this.pinChars.push(char.toString());

    this.pinChar1 = this.pinChars[0];
    this.pinChar2 = this.pinChars[1];
    this.pinChar3 = this.pinChars[2];
    this.pinChar4 = this.pinChars[3];
    this.pinChar5 = this.pinChars[4];

    if (this.pinChars.length >= 5) {
      this.done();
    }
  }

  useBadgeLogin() {
    this.clear();
    this.modalService.dismissModal({
      changeLogin: LoginTypes.Badge
    });
  }

  clear() {
    this.pinChars = [];
    this.pinChar1 = this.pinChar2 = this.pinChar3 = this.pinChar4 = this.pinChar5 = "";
  }

  exit() {
    this.clear();
    this.modalService.dismissModal();
  }

  async done() {
    const pin = this.pinChars.join('');
    const loader = await this.modalService.createLoader();
    loader.present();

    try {
      const dto = new LoginByPinDto();
      dto.pin = pin;
      const locationIdentity = this.authService.getIdentity();
      const userResponse = await this.loginService.loginWithUserPin(locationIdentity, dto);

      this.modalService.dismissModal({
        token: userResponse.body
      });

    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status == 404) {
        (await this.modalService.createConfirmationAlert('LOGIN.FAILEDHEADER', 'LOGIN.FAILEDMSG')).present();
      } else if (error instanceof HttpErrorResponse && error.status == 403) {
        (await this.modalService.createConfirmationAlert('LOGIN.FAILEDHEADER', 'LOGIN.FORBIDDENMSG')).present();
      } else {
        (await this.modalService.createErrorAlertWithDetail(error)).present();
      }
    }
    finally {
      loader.dismiss();
      this.clear();
    }
  }

}
