import {Injectable} from '@angular/core';

declare var YT;

@Injectable({
    providedIn: 'root'
})
export class VideoPlayerService {

    private youtubePlayers: any[] = [];
    private directPlayers: any[] = [];
    public isPlaying = false;


    public init() {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/player_api';
        document.head.appendChild(script);
    }

    private onStateChange() {
        const youtubeStates = this.youtubePlayers.map(x => x?.getPlayerState());
        const directStates = this.directPlayers.map(x => this.isVideoPlaying(x));

        const isYoutubePlaying = youtubeStates.some(x => x === 1);
        const isDirectPlaying = directStates.some(x => x === true);

        this.isPlaying = isYoutubePlaying || isDirectPlaying;
    }

    public detectPlayers() {
        this.detectYoutube();
        this.detectDirect();
    }

    private isVideoPlaying(video) {
        return !!(!video.paused && !video.ended && video.readyState > 2);
    }
    private detectDirect() {
        setTimeout(() => {
            this.directPlayers = Array.from(window.document.querySelectorAll('video'));

            this.directPlayers.forEach(player => {
                player?.addEventListener('play', () => this.onStateChange());
                player?.addEventListener('pause', () => this.onStateChange());
            });

        }, 1000);
    }

    private detectYoutube() {
        setTimeout(() => {
            const elements = Array.from(window.document.querySelectorAll('iframe[src^="https://www.youtube.com/embed/"]'));
            this.youtubePlayers = elements.map(e => new YT.Player(e));

            this.youtubePlayers.forEach(player => {
                player?.addEventListener('onStateChange', () => this.onStateChange());
            });

        }, 1000);
    }
}
