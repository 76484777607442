import {MissionState} from '../../../server/Enums';
import {Column} from '../../../server/Attributes';
import {FilledCustomValueDto} from '../../../server/DataTransferObject/Objects/Stereotyping/FilledCustomValues';

export class MissionReportDto {
    constructor(
        public tenantId: number,
        public missionReportId: number,
        public missionId: number,
        public total: number,
        public stereotypeId: number,
        public stereotypeRowVersion: number[],
        public customPropertyValues: FilledCustomValueDto[],
        public groups: MissionReportGroup[],
    ) { }
}

export class MissionReportGroup {
    constructor(
        public tenantId: number,
        public missionReportGroupId: number,
        public total: number,
        public position: number,
        public name: string,
        public usedArticles: MissionReportItem[]
    ) { }
}

export class MissionReportItem {
    constructor(
        public tenantId: number,
        public articleUsageId: number,
        public article: ArticleDto,
        public total: number,
        public position: number,
        public count: number,
        public note: string,
        public price: number,
        public unit: string
    ) { }
}

export class ArticleDto {
    constructor(
        public tenantId: number,
        public articleId: number,
        public name: string
    ) { }
}

export class ArticleKindDto {
    constructor(
        public articleKindId: number,
        public name: string
    ) { }
}

export class ArticleListDto {
    constructor(
        public tenantId: number,
        public articleId: number,
        public name: string,
        public unit: string,
        public sellingPrice: number,
        public kind: ArticleKindDto
    ) { }
}

