//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../../Attributes';
import { IHtmlViewData } from '../../../Objects';
import { AttachmentFormFieldDto } from '../../Forms/Create/Fields';
import { CheckboxFormFieldDto } from '../../Forms/Create/Fields';
import { CheckboxFormValueDto } from '../../Forms/Create/Values';
import { DateFormFieldDto } from '../../Forms/Create/Fields';
import { DateFormValueDto } from '../../Forms/Create/Values';
import { DropDownFormFieldDto } from '../../Forms/Create/Fields';
import { DropDownFormValueForCompleteDto } from '../../Forms/Create/Values';
import { InfoFormFieldDto } from '../../Forms/Create/Fields';
import { InfoFormValueDto } from '../../Forms/Create/Values';
import { MultilineFormFieldDto } from '../../Forms/Create/Fields';
import { MultilineFormValueDto } from '../../Forms/Create/Values';
import { NumberFormFieldDto } from '../../Forms/Create/Fields';
import { NumberFormValueDto } from '../../Forms/Create/Values';
import { PictureFormFieldDto } from '../../Forms/Create/Fields';
import { PictureFormValueDto } from '../../Forms/Create/Values';
import { SignatureFormFieldDto } from '../../Forms/Create/Fields';
import { SignatureFormValueDto } from '../../Forms/Create/Values';
import { TextFormFieldDto } from '../../Forms/Create/Fields';
import { TextFormValueDto } from '../../Forms/Create/Values';
import { TimeFormFieldDto } from '../../Forms/Create/Fields';
import { TimeFormValueDto } from '../../Forms/Create/Values';

export class CompleteFormFieldViewDto implements IHtmlViewData
{
}
export class CompleteAttachmentFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: AttachmentFormFieldDto;
}
export class CompleteCheckboxFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: CheckboxFormFieldDto;
	@Column() public value: CheckboxFormValueDto;
}
export class CompleteDateFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: DateFormFieldDto;
	@Column() public value: DateFormValueDto;
}
export class CompleteDropDownFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: DropDownFormFieldDto;
	@Column() public value: DropDownFormValueForCompleteDto;
}
export class CompleteInfoFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: InfoFormFieldDto;
	@Column() public value: InfoFormValueDto;
}
export class CompleteMultilineFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: MultilineFormFieldDto;
	@Column() public value: MultilineFormValueDto;
}
export class CompleteNumberFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: NumberFormFieldDto;
	@Column() public value: NumberFormValueDto;
}
export class CompletePictureFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: PictureFormFieldDto;
	@Column() public value: PictureFormValueDto;
}
export class CompleteSignatureFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: SignatureFormFieldDto;
	@Column() public value: SignatureFormValueDto;
}
export class CompleteTextFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: TextFormFieldDto;
	@Column() public value: TextFormValueDto;
}
export class CompleteTimeFieldViewDto extends CompleteFormFieldViewDto implements IHtmlViewData
{
	@Column() public field: TimeFormFieldDto;
	@Column() public value: TimeFormValueDto;
}
