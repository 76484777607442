//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { ValidationDto } from '../Validations';
import { DeviceCodeChallengeDto } from './LoginChallenge';
import { LocationSimpleDto } from './MasterData/Locations';

export class DeviceListDto
{
	@Column() @Filterable() public id: number;
	@Column() @Filterable() public locationId: number;
	@Column() public location: LocationSimpleDto;
	@Column() public locationName: string;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public hardwareId: string;
}
export class PushRegisterDto
{
	@Column() public token: string;
	@Column() public hardwareId: string;
	@Column() public platform: number;
}
export class DeviceDto extends ValidationDto
{
	@Column() public id: number;
	@Column() public locationId: number;
	@Column() public name: string;
	@Column() public challenges: DeviceCodeChallengeDto[];
	@Column() public tenantId: number;
	@Column() public rowVersion: number[];
}
export class RegisterDeviceByLocationDto extends ValidationDto
{
	@Column() public hardwareId: string;
	@Column() public deviceName: string;
	@Column() public locationId: number;
}
