//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { MissionDescriptionContextDto } from './Templating/Contexts';
import { IIdentityData } from '../Objects';

export class PreviewTemplateDto
{
	@Column() public template: string;
	@Column() public context: number;
}
export class RenderedTemplateDto
{
	@Column() public content: string;
}
export class RenderTemplateDto
{
	@Column() public type: number;
	@Column() public context: MissionDescriptionContextDto;
}
export class TemplateDto implements IIdentityData
{
	@Column() public templateId: number;
	@Column() public title: string;
	@Column() public content: string;
	@Column() public context: number;
	@Column() public tenantId: number;
}
export class TemplateListDto implements IIdentityData
{
	@Column() @Filterable() public templateId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public context: number;
	@Column() public tenantId: number;
}
