import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from 'services/api/auth/login.service';
import { ModalService } from '../../../services/ui/modal.service';
import { IonInput } from '@ionic/angular';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.page.html',
  styleUrls: ['./password-login.page.scss'],
})
export class PasswordLoginPage implements OnInit {

  @ViewChild(IonInput, {static: false}) element: IonInput;
  
  public loginForm: FormGroup;
  
  constructor(
    private modalService: ModalService,
    private loginService: LoginService,
    private formBuilder: FormBuilder
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.clear();
  }

  inputChange(control, e) {
    this.loginForm.controls[control].setValue(e.target.value);
  }

  exit() {
    this.clear();
    this.modalService.dismissModal();
  }

  clear() {
    this.loginForm.reset();
  }

  async done() {
    const loader = await this.modalService.createLoader();
    loader.present();

    try {
      const userResponse = await this.loginService.loginWithPassword(this.loginForm.value.username, this.loginForm.value.password);
      this.modalService.dismissModal(userResponse.body);
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status == 404) {
        (await this.modalService.createConfirmationAlert('LOGIN.FAILEDHEADER', 'LOGIN.FAILEDMSG')).present();
      } else {
        (await this.modalService.createErrorAlertWithDetail(error)).present();
      }
    }
    finally {
      loader.dismiss();
      this.clear();
    }
  }

}
