import {Component, Input, OnInit} from '@angular/core';
import { ModalController } from "@ionic/angular";
import {FileDto} from '../../models/server/DataTransferObject/Objects/Files';


@Component({
  selector: 'app-photo-view',
  templateUrl: './photo-view.page.html',
  styleUrls: ['./photo-view.page.scss'],
})
export class PhotoViewPage implements OnInit {

  @Input()
  public file: FileDto;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  public exit() {
    this.modalCtrl.dismiss();
  }

}
