//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IArchiveDto } from '../../Objects';
import { IIdentityData } from '../../Objects';

export class FormListDto implements IArchiveDto, IIdentityData
{
	@Column() @Filterable() public formId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public description: string;
	@Column() @Filterable() public isArchived: boolean;
	@Column() @Filterable() public type: number;
}
