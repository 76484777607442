import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController, ToastController } from '@ionic/angular';
import { SpinnerTypes } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  protected readonly loadingSpinner: SpinnerTypes = "dots";
  protected readonly toastDuration: number = 3000;
  protected readonly toastColor: string = "secondary";

  constructor(
    protected loadingCtrl: LoadingController,
    protected alertCtrl: AlertController,
    protected toastCtrl: ToastController,
    protected modalCtrl: ModalController,
    protected translate: TranslateService
  ) { }

  public async dismissModal(data?: any, role?: string, id?: string) {
    await this.modalCtrl.dismiss(data, role, id);
  }

  public async createLoader() {
    const loadingMsg = await this.translate.get('LOADING').toPromise();
    return this.loadingCtrl.create({ spinner: this.loadingSpinner, message: loadingMsg });
  }

  public async createErrorAlert(messageKey: string = 'ERRORMSG', ...replaceVerbs: { [key: string]: string }[]): Promise<HTMLIonAlertElement> {
    const errorTitle: string = await this.translate.get('ERROR').toPromise();

    return this.createConfirmationAlert(errorTitle, messageKey, ...replaceVerbs);
  }

  public async createErrorAlertWithDetail(error: Error, messageKey: string = 'ERRORMSG', ...replaceVerbs: { [key: string]: string }[]): Promise<HTMLIonAlertElement> {
    const title: string = await this.translate.get('ERROR').toPromise();
    const msgPlain: string = await this.translate.get(messageKey).toPromise();
    const msgWithVerbs: string = this.replaceVerbs(msgPlain, ...replaceVerbs);
    const detailsBtnText = await this.translate.get('DETAILS').toPromise();

    return this.alertCtrl.create({
      header: title,
      message: msgWithVerbs,
      buttons: [
        {
          text: detailsBtnText,
          handler: async () => {
            let errorMsg = error.message;
            
            if(error instanceof HttpErrorResponse && error.status == 400) {
              if(error.error && error.error.Errors && error.error.Errors.length > 0) {
                errorMsg = error.error.Errors.map(x => x.Description).join('<br />');
              }
              if(error.error && isArray(error.error) && error.error.length > 0) {
                errorMsg = error.error.map(x => x.Description).join('<br />');
              }
            }

            const errorDetailAlert = await this.createErrorAlert(errorMsg);
            errorDetailAlert.present();
          }
        },
        'OK'
      ]
    });
  }

  public async presentDialog(titleKey: string, messageKey: string, ...replaceVerbs: { [key: string]: string }[]): Promise<boolean> {
    const title: string = await this.translate.get(titleKey).toPromise();
    const msgPlain: string = await this.translate.get(messageKey).toPromise();
    const msgWithVerbs: string = this.replaceVerbs(msgPlain, ...replaceVerbs);

    return new Promise<boolean>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: msgWithVerbs,
        buttons: [
          {
            text: await this.translate.get('CANCEL').toPromise(),
            handler: async () => {
              resolve(false);
            }
          },
          {
            text: await this.translate.get('OK').toPromise(),
            handler: async () => {
              resolve(true);
            }
          },
        ]
      });
      alert.onDidDismiss().then(x => resolve(false));
      await alert.present();
    });
  }

  public async createConfirmationAlert(titleKey: string, messageKey: string, ...replaceVerbs: { [key: string]: string }[]): Promise<HTMLIonAlertElement> {
    const title: string = await this.translate.get(titleKey).toPromise();
    const msgPlain: string = await this.translate.get(messageKey).toPromise();
    const msgWithVerbs: string = this.replaceVerbs(msgPlain, ...replaceVerbs);

    return this.alertCtrl.create({
      header: title,
      message: msgWithVerbs,
      buttons: ['OK']
    });
  }

  /**
   * Creates a confirmation dialog with a custom confirmation button
   */
  public async createConfirmationDialog(
    titleKey: string,
    messageKey: string,
    confirmKey: string,
    ...replaceVerbs: { [key: string]: string }[]
  ): Promise<boolean> {
    const title: string = await this.translate.get(titleKey).toPromise();
    const messagePlain: string = await this.translate.get(messageKey).toPromise();
    const messageWithVerbs: string = this.replaceVerbs(messagePlain, ...replaceVerbs);
    const confirmButtonText = await this.translate.get(confirmKey).toPromise();
    const cancelButtonText = await this.translate.get('CANCEL').toPromise();

    return new Promise<boolean>(async resolve => {
      const alert = await this.alertCtrl.create({
        header: title,
        message: messageWithVerbs,
        buttons: [
          {
            text: cancelButtonText,
            handler: async () => resolve(false)
          },
          {
            text: confirmButtonText,
            handler: async () => resolve(true)
          }
        ]
      });

      alert.onDidDismiss().then(() => resolve(false));
      await alert.present();
    });
  }

  public async createInfoToast(messageKey: string, ...replaceVerbs: { [key: string]: string }[]): Promise<HTMLIonToastElement> {
    const msgPlain: string = await this.translate.get(messageKey).toPromise();
    const msgWithVerbs: string = this.replaceVerbs(msgPlain, ...replaceVerbs);

    return this.toastCtrl.create({
      message: msgWithVerbs,
      duration: this.toastDuration,
      color: this.toastColor
    });
  }

  protected replaceVerbs(input: string, ...replaceVerbs: { [key: string]: string }[]): string {
    replaceVerbs = replaceVerbs || [];

    for (let i = 0; i < replaceVerbs.length; i++) {
      const key = Object.keys(replaceVerbs[i])[0];
      input = input.replace(`{${key}}`, replaceVerbs[i][key]);
    }
    return input;
  }
}
