import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Page, Identity } from '../../../models/client';
import { FilterDto } from '../../../models/server/DataTransferObject/Filters';
import { TaskListDto } from '../../../models/server/DataTransferObject/Objects/Tasks/ListDtos';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';
import { TaskJobState, TaskState, FilterOperators } from '../../../models/server/Enums';
import { HttpApiBaseService } from '../http/http-api-base.service';
import { TaskJobDto } from '../../../models/server/DataTransferObject/Objects/TaskJobs';

@Injectable({
  providedIn: 'root'
})
export class TaskManualService {
  protected endpoint = "manualtask";

  constructor(
    protected http: HttpLocationApiService
  ) {
  }

  public addIdentity(identity: Identity): TaskManualService {
    return new TaskManualService(this.http.addIdentity(identity) as HttpApiBaseService as HttpLocationApiService);
  }

  public async getManualTasks(): Promise<TaskListDto[]> {
    const filters = [
      <FilterDto>{
        property: "isEnabled",
        value: "true"
      }
    ];

    return this.http.post<Page<TaskListDto>>(`${this.endpoint}/filter?pageSize=99&sortField=Title`, filters).pipe(
      map((response: HttpResponse<Page<TaskListDto>>) => <TaskListDto[]>response.body.items)
    ).toPromise();
  }
  public async createJob(taskId: number): Promise<TaskJobDto> {
    return this.http
      .post<TaskJobDto>(`${this.endpoint}/${taskId}`, null)
      .pipe(map(x => x.body))
      .toPromise();
  }
  public async createJobInState(taskId: number, state: TaskJobState): Promise<TaskJobDto> {
    return this.http
      .post<TaskJobDto>(`${this.endpoint}/${taskId}/state/${state}`, null)
      .pipe(map(x => x.body))
      .toPromise();
  }
}
