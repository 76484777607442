//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';

export class AppPermissionDto
{
	@Column() public permission: number;
	@Column() public group: string;
	@Column() public availableModes: string[];
}
export class AppRoleDto extends ValidationDto implements IIdentityData
{
	@Column() public appRoleId: number;
	@Column() public title: string;
	@Column() public kind: number;
	@Column() public isDefault: boolean;
	@Column() public term: number;
	@Column() public tenantId: number;
	@Column() public concurrencyStamp: string;
	@Column() public isSystemRole: boolean;
	@Column() public packages: PermissionFunctionDto[];
}
export class AppRoleListDto implements IIdentityData
{
	@Column() @Filterable() public appRoleId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tenantId: number;
	@Column() public concurrencyStamp: string;
	@Column() @Filterable() public isDefault: boolean;
	@Column() @Filterable() public isSystemRole: boolean;
	@Column() @Filterable() public kind: number;
	@Column() @Filterable() public term: number;
}
export class AppRolePermissionDto
{
	@Column() public permission: number;
}
export class AppTenantTokenDto
{
	@Column() public tenantName: string;
	@Column() public accessToken: string;
	@Column() public tenantId: number;
}
export class AppTokenDto
{
	@Column() public accessTokens: { [key:string]: string };
	@Column() public tokens: AppTenantTokenDto[];
	@Column() public refreshToken: string;
	@Column() public token: string;
}
export class PermissionFunctionDto
{
	@Column() public package: number;
}
export class UserRoleDto
{
	@Column() public userId: number;
	@Column() public roleId: number;
	@Column() public contactId: number;
}
export class AppRoleListForContactDto extends AppRoleListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LoginByPinDto extends ValidationDto
{
	@Column() public pin: string;
}
export class LoginByBadgeDto extends ValidationDto
{
	@Column() public badge: string;
}

