//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { UserSimpleDto } from '../Users';
import { NoteDto } from './Notes';
import { TicketSimpleListDto } from '../Tickets';
import { MissionSimpleListDto } from '../Missions';
import { UserSimpleListDto } from '../Users';
import { NoteCompleteDto } from './Notes';

export class MarkItemAsSolutionDto
{
	@Column() public itemId: number;
	@Column() public isSolution: boolean;
}
export class SolutionDto implements IIdentityData
{
	@Column() public solutionId: number;
	@Column() public tenantId: number;
	@Column() public members: SolutionMemberDto[];
}
export class SolutionItemDto extends ValidationDto implements IIdentityData
{
	@Column() public solutionItemId: number;
	@Column() public tenantId: number;
	@Column() public solutionId: number;
	@Column() @DateColumn() public lastModified: string;
	@Column() @DateColumn() public createdAt: string;
	@Column() public ticketId: number;
	@Column() public missionId: number;
	@Column() public isSolution: boolean;
	@Column() public createdByUser: UserSimpleDto;
	@Column() public note: NoteDto;
}
export class SolutionItemListDto implements IIdentityData
{
	@Column() @Filterable() public solutionItemId: number;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public solutionId: number;
	@Column() @Filterable() @DateColumn() public lastModified: string;
	@Column() @Filterable() @DateColumn() public createdAt: string;
	@Column() @Filterable() public isSolution: boolean;
	@Column() @Filterable() public ticketId: number;
	@Column() public ticket: TicketSimpleListDto;
	@Column() @Filterable() public missionId: number;
	@Column() public mission: MissionSimpleListDto;
	@Column() public createdByUser: UserSimpleListDto;
	@Column() public note: NoteCompleteDto;
}
export class SolutionMemberDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public title: string;
	@Column() public type: number;
}
