//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { FileDto } from '../Files';

export class SettingValueDto implements IIdentityData
{
	@Column() public settingValueId: number;
	@Column() public type: number;
	@Column() public tenantId: number;
}
export class TextSettingDto extends SettingValueDto implements IIdentityData
{
}
export class CultureSettingDto extends SettingValueDto implements IIdentityData
{
	@Column() public value: string;
}
export class HexColorSettingDto extends SettingValueDto implements IIdentityData
{
	@Column() public value: string;
}
export class PictureSettingDto extends SettingValueDto implements IIdentityData
{
	@Column() public value: number;
	@Column() public file: FileDto;
}
export class ContactLoginMethodSettingDto extends SettingValueDto implements IIdentityData
{
	@Column() public value: number;
}
