//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IHtmlViewData } from '../../Objects';
import { FormFieldCreateDto } from '../Forms/Create/Fields';
import { FormValueDto } from '../Forms/Create/Values';

export class CompleteFormDto implements IHtmlViewData
{
	@Column() public title: string;
	@Column() public rows: CompleteFormRowDto[];
	@Column() public content: string;
	@Column() public createdAt: string;
	@Column() public createdBy: string;
	@Column() public ratingInPercent: string;
	@Column() public ratingInPercentContent: string;
}
export class CompleteFormFieldDto implements IHtmlViewData
{
	@Column() public formField: FormFieldCreateDto;
	@Column() public title: string;
	@Column() public value: FormValueDto[];
	@Column() public type: number;
	@Column() public content: string;
}
export class CompleteFormRowDto implements IHtmlViewData
{
	@Column() public title: string;
	@Column() public type: number;
	@Column() public fields: CompleteFormFieldDto[];
	@Column() public content: string;
}
