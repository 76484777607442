import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-popover-more',
  templateUrl: './popover-more.page.html',
  styleUrls: ['./popover-more.page.scss'],
})
export class PopoverMorePage implements OnInit {

  public lang: string = environment.lang.defaultLanguage;

  constructor(
    private translateService: TranslateService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.lang = this.translateService.currentLang || environment.lang.defaultLanguage;
  }

  async switchLanguage(lang: string) {
    await this.translateService.use(lang);
    this.popoverCtrl.dismiss();
  }

}
