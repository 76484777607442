import { Injectable } from "@angular/core";
import { environment as environmentDev } from "../../../environments/environment.dev";
import { environment as environmentProd } from "../../../environments/environment.prod";
import { environment as environmentTest } from "../../../environments/environment.test";
import { environment as environmentLocal } from "../../../environments/environment.local";
import { environment as environmentDefault } from "../../../environments/environment";
import { Observable, BehaviorSubject, of } from "rxjs";
import { EnviromentTyping } from "../../../environments/enviroment-typings";
import { switchMap, shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class EnviromentService {
    protected enviroment: EnviromentTyping = environmentDefault;
    protected refresher$ = new BehaviorSubject<void>(undefined);

    public enviroments = {
        dev: environmentDev,
        prod: environmentProd,
        test: environmentTest,
        local: environmentLocal,
        default: environmentDefault,
    };

    public env$: Observable<EnviromentTyping> = this.refresher$
        .pipe(
            switchMap(_ => this.fetch()),
            shareReplay(1),
        );

    protected fetch(): Observable<EnviromentTyping> {
        return of(this.enviroment);
    }

    public switch(enviroment: EnviromentTyping) {
        this.enviroment = enviroment;
        this.refresher$.next();
    }
}