//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { PrioritySimpleDto } from './Priorities';
import { StateSimpleDto } from '../StateMachines';
import { PrioritySimpleListDto } from './Priorities';
import { StateSimpleListDto } from '../StateMachines';

export class EscalationLevelDto implements IIdentityData
{
	@Column() public id: number;
	@Column() public escalationLevelId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class EscalationLevelListDto implements IIdentityData
{
	@Column() public id: number;
	@Column() @Filterable() public escalationLevelId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public tenantId: number;
}
export class EscalationLevelSimpleDto
{
	@Column() public escalationLevelId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class EscalationLevelSimpleListDto
{
	@Column() @Filterable() public escalationLevelId: number;
	@Column() @Filterable() public name: string;
	@Column() public tenantId: number;
}
export class EscalationRuleDto extends ValidationDto implements IIdentityData
{
	@Column() public escalationRuleId: number;
	@Column() public isArchived: boolean;
	@Column() public isInherited: boolean;
	@Column() public isOffsetInherited: boolean;
	@Column() public ownOffset: string;
	@Column() public inheritedOffset: string;
	@Column() public levelId: number;
	@Column() public level: EscalationLevelSimpleDto;
	@Column() public priorityId: number;
	@Column() public priority: PrioritySimpleDto;
	@Column() public stateId: number;
	@Column() public state: StateSimpleDto;
	@Column() public tenantId: number;
}
export class EscalationRulesInEventListDto implements IIdentityData
{
	@Column() public escalationRuleId: number;
	@Column() public isArchived: boolean;
	@Column() public isOffsetInherited: boolean;
	@Column() public ownOffset: string;
	@Column() public inheritedOffset: string;
	@Column() public levelId: number;
	@Column() public level: EscalationLevelSimpleListDto;
	@Column() public priorityId: number;
	@Column() public priority: PrioritySimpleListDto;
	@Column() public stateId: number;
	@Column() public state: StateSimpleListDto;
}
