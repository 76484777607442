import { PreSelected } from "./pre-selected";
import { ProcessResource, ProcessState, ProcessPriority, ProcessStereotype } from "./entities";
import {ResourceSimpleDto} from '../../../server/DataTransferObject/Objects/Resources';
import {StereotypeSimpleDto} from '../../../server/DataTransferObject/Objects/Stereotyping/Stereotypes';

export enum ResourceTicketSkeletonSelectorType {
    Base = 0,
    All = 1,
    RestrictedByType = 2,
    Whitelisted = 3,
    Fixed = 4,
}

export class ResourceTicketSkeletonSelector {
    constructor(
        public type: ResourceTicketSkeletonSelectorType,
        public resource: ProcessResource,
        public stereotypes: StereotypeSimpleDto[],
        public whitelist: ResourceSimpleDto[]
    ) { }
}


export class TicketSkeleton {
    constructor(
        public ticketSkeletonId: number,
        public title: PreSelected<string>,
        public description: PreSelected<string>,
        public resource: PreSelected<ProcessResource>,
        public state: PreSelected<ProcessState>,
        public priority: PreSelected<ProcessPriority>,
        public stereotype: PreSelected<ProcessStereotype>,
        public resourceSelector: ResourceTicketSkeletonSelector
    ) { }
}

