//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { MessagePatternDto } from '../ChatPatternMessages';
import { ValidationDto } from '../../Validations';
import { FormRowCreateDto } from './Create/Rows';
import { FormLocationDto } from '../Forms';
import { FormLocationGroupDto } from '../Forms';

export class FormCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public formId: number;
	@Column() public type: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public tenantId: number;
	@Column() public rows: FormRowCreateDto[];
	@Column() public locations: FormLocationDto[];
	@Column() public locationGroups: FormLocationGroupDto[];
}
export class ChatFormCreateDto extends FormCreateDto implements IIdentityData
{
	@Column() public sendAsAttachment: boolean;
	@Column() public receiverId: number;
	@Column() public requiresAuthentication: boolean;
	@Column() public messagePattern: MessagePatternDto;
}
export class TaskFormCreateDto extends FormCreateDto implements IIdentityData
{
}
