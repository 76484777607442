//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { ValidationDto } from '../../Validations';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { LocationListDto } from './Locations';
import { LocationDto } from './Locations';
import { LocationGroupDto } from './LocationGroups';

export class ContactSimpleDto
{
	@Column() @Filterable() public contactId: number;
	@Column() @Filterable() public displayName: string;
	@Column() public userId: number;
}
export class ContactSimpleListDto
{
	@Column() @Filterable() public contactId: number;
	@Column() @Filterable() public displayName: string;
}
export class ContactDto extends ValidationDto implements IStereotypedDto, IIdentityData
{
	@Column() public contactId: number;
	@Column() public tenantId: number;
	@Column() public firstName: string;
	@Column() public isLockedOut: boolean;
	@Column() @DateColumn() public lastLoggedIn: string;
	@Column() public lastName: string;
	@Column() public displayName: string;
	@Column() public position: string;
	@Column() public emailAddress: string;
	@Column() public phone: string;
	@Column() public mobile: string;
	@Column() public rowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
	@Column() public stereotypeRowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public sendPassword: boolean;
}
export class ContactListDto implements IIdentityData
{
	@Column() @Filterable() public contactId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public firstName: string;
	@Column() @Filterable() public lastName: string;
	@Column() @Filterable() public displayName: string;
	@Column() @Filterable() public position: string;
	@Column() public emailAddress: string;
	@Column() @Filterable() public phone: string;
	@Column() @Filterable() public mobile: string;
	@Column() @Filterable() public pin: string;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
	@Column(true) @Filterable() public locations: LocationListDto[];
}
export class ContactLocationDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactDto;
	@Column() public locationId: number;
	@Column() public location: LocationDto;
}
export class ContactLocationGroupDto
{
	@Column() @Filterable() public contactId: number;
	@Column() public contact: ContactDto;
	@Column() @Filterable() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupDto;
}
export class ContactOrgaDto extends ValidationDto implements IIdentityData
{
	@Column() public contactId: number;
	@Column() public tenantId: number;
	@Column() public isLockedOut: boolean;
	@Column() @DateColumn() public lastLoggedIn: string;
	@Column() public firstName: string;
	@Column() public lastName: string;
	@Column() public displayName: string;
	@Column() public position: string;
	@Column() public emailAddress: string;
	@Column() public phone: string;
	@Column() public mobile: string;
	@Column() public rowVersion: number[];
	@Column() public pin: string;
	@Column() public isChatMailEnabled: boolean;
	@Column() public sendPassword: boolean;
}
export class ContactRelationDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactSimpleDto;
}
export class ResetPasswordQueueDto
{
	@Column() public token: string;
	@Column() public email: string;
	@Column() public link: string;
	@Column() public senderName: string;
	@Column() public contactId: number;
}
export class ShortContactDto extends ValidationDto
{
	@Column() public contactId: number;
	@Column() public tenantId: number;
	@Column() public firstName: string;
	@Column() public lastName: string;
	@Column() public displayName: string;
	@Column() public emailAddress: string;
	@Column() public password: string;
}
export class ContactListForCompanyDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactListForContactGroupDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactListForInfoDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactListForLocationDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactListForLocationGroupDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class ContactListForRoleDto extends ContactListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
