//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { FileDto } from './Files';
import { ValidationDto } from '../Validations';

export class EmailAddressDto
{
	@Column() public address: string;
	@Column() public name: string;
	@Column() public tenantId: number;
}
export class MailDto
{
	@Column() public from: EmailAddressDto;
	@Column() public to: EmailAddressDto[];
	@Column() public cc: EmailAddressDto[];
	@Column() public subject: string;
	@Column() public text: string;
	@Column() public html: string;
	@Column() public attachments: FileDto[];
}
export class SendGridMailDto extends ValidationDto
{
	@Column() public email: string;
	@Column() public subject: string;
	@Column() public dkim: string;
	@Column() public envelope: string;
	@Column() public charsets: string;
	@Column() public spf: string;
	@Column() public to: string;
	@Column() public from: string;
	@Column() public sender_Ip: string;
	@Column() public spam_Report: string;
	@Column() public spam_Score: number;
}
