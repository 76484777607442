//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { DashboardItemDto } from '../Presentation';
import { ValidationDto } from '../../Validations';

export class DataTableColumnDto implements IIdentityData
{
	@Column() public dataTableColumnId: number;
	@Column() public sortOrder: number;
	@Column() public position: number;
	@Column() public title: string;
	@Column() public type: number;
	@Column() public tenantId: number;
}
export class DataTableFilterDto implements IIdentityData
{
	@Column() public dataTableFilterId: number;
	@Column() public type: number;
	@Column() public operator: number;
	@Column() public value: string;
	@Column() public tenantId: number;
}
export class DataTableDto extends ValidationDto implements IIdentityData
{
	@Column() public dataTableId: number;
	@Column() public pageOperation: number;
	@Column() public pageSize: number;
	@Column() public name: string;
	@Column() public description: string;
	@Column() public tenantId: number;
	@Column() public columns: DataTableColumnDto[];
	@Column() public filters: DataTableFilterDto[];
}
export class DashboardTableReferenceDto extends DashboardItemDto
{
	@Column() public dashboardId: number;
	@Column() public dataTableId: number;
	@Column() public dataTable: DataTableDto;
}
export class DataTableListDto implements IIdentityData
{
	@Column() @Filterable() public dataTableId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public description: string;
	@Column() @Filterable() public pageOperation: number;
	@Column() @Filterable() public tenantId: number;
}
export class DataTableTransferColumnDto extends DataTableColumnDto implements IIdentityData
{
	@Column() public property: string;
}
export class DataTableCustomColumnDto extends DataTableColumnDto implements IIdentityData
{
	@Column() public customPropertyId: number;
}
export class DataTableCustomFilterDto extends DataTableFilterDto implements IIdentityData
{
	@Column() public customPropertyId: number;
}
export class DataTableTransferFilterDto extends DataTableFilterDto implements IIdentityData
{
	@Column() public property: string;
}
