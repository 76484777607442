//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';
import { ExecutionPlanDto } from '../Executions';
import { LocationTaskDto } from '../MasterData/Locations';
import { LocationGroupTaskDto } from '../MasterData/LocationGroups';
import { ImpactCreateDto } from '../Impacts';

export class TaskCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public taskId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public type: number;
	@Column() public requiresAuthentication: boolean;
	@Column() public generatePdf: boolean;
	@Column() public generateCsv: boolean;
	@Column() public isEnabled: boolean;
	@Column() public reasonSetId: number;
	@Column() public executionPlan: ExecutionPlanDto;
	@Column() public locations: LocationTaskDto[];
	@Column() public locationGroups: LocationGroupTaskDto[];
	@Column() public impacts: ImpactCreateDto[];
	@Column() public tenantId: number;
}
export class CollectionTaskCreateDto extends TaskCreateDto implements IIdentityData
{
	@Column() public children: TaskCreateDto[];
	@Column() public hasStrictOrder: boolean;
}
export class ConfirmationTaskCreateDto extends TaskCreateDto implements IIdentityData
{
}
export class DocumentTaskCreateDto extends TaskCreateDto implements IIdentityData
{
	@Column() public formId: number;
	@Column() public parentId: number;
	@Column() public position: number;
}
