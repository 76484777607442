//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ChatMemberDto } from './ChatMembers';
import { OrgaAttachmentDto } from '../Attachments';
import { ChatMemberListDto } from './ChatMembers';
import { OrgaAttachmentListDto } from '../Attachments';
import { ChatMemberMessageDto } from '../Chats';

export class ChatMessageDto implements IIdentityData
{
	@Column() public chatMessageId: number;
	@Column() public message: string;
	@Column() public sender: ChatMemberDto;
	@Column() public attachment: OrgaAttachmentDto;
	@Column() public attachments: OrgaAttachmentDto[];
	@Column() public tenantId: number;
	@Column() public chatId: number;
}
export class ChatMessageListDto
{
	@Column() @Filterable() public chatMessageId: number;
	@Column() @Filterable() public message: string;
	@Column() @Filterable() public chatId: number;
	@Column() @DateColumn() public time: string;
	@Column() public sender: ChatMemberListDto;
	@Column() public attachment: OrgaAttachmentListDto;
	@Column() public members: ChatMemberMessageDto[];
}
