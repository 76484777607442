import { HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { ApiProduct, Identity } from "models/client";
import { Observable } from "rxjs";
import { EnviromentService } from "../../../ui/enviroment.service";
import { HttpApiBaseService } from "../http-api-base.service";
import { HttpApiClient } from "../http-api-client";


export class HttpUserApiService extends HttpApiBaseService {

  constructor(protected httpClient: HttpApiClient, protected identity: Identity, protected envService: EnviromentService, protected product: ApiProduct = ApiProduct.Orga) {
    super(httpClient, envService, product);

    if(!this.identity || !this.identity.token) {
      throw new Error('no identity given');
    }
  }
  
  public get<T>(path: string, headers: HttpHeaders = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    headers = this.addIdentityHeader(headers || new HttpHeaders());
    return super.get(path, headers, params);
  }

  public post<T>(path: string, body: any, headers: HttpHeaders = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    headers = this.addIdentityHeader(headers || new HttpHeaders());
    return super.post(path, body, headers, params);
  }

  public delete<T>(path: string, id: string, headers: HttpHeaders = null): Observable<HttpResponse<T>> {
    headers = this.addIdentityHeader(headers || new HttpHeaders());
    return super.delete(path, id, headers);
  }

  public update<T>(path: string, object: T, headers: HttpHeaders = null): Observable<HttpResponse<T>> {
    headers = this.addIdentityHeader(headers || new HttpHeaders());
    return super.update(path, object, headers);
  }

  public getIdentity() : Identity {
    return this.identity;
  }

  protected addIdentityHeader(headers: HttpHeaders) : HttpHeaders {
    return headers.set('Authorization', `Bearer ${this.identity.token}`);
  }
}
