//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class ILocationGroupRelationDto
{
	@Column() public locationGroupId: number;
}
export class ILocationRelationDto
{
	@Column() public locationId: number;
}
export class IPlaceDto
{
}
export class MailBoxDto implements IIdentityData
{
	@Column() public mailBoxId: number;
	@Column() public displayName: string;
	@Column() public address: string;
	@Column() public tenantId: number;
}
export class MailBoxListDto implements IIdentityData
{
	@Column() public mailBoxId: number;
	@Column() public displayName: string;
	@Column() public address: string;
	@Column() public tenantId: number;
}
