import { Injectable } from "@angular/core";
import { ApiProduct, Identity } from "models/client";
import { EnviromentService } from "../../ui/enviroment.service";
import { HttpApiBaseService } from "./http-api-base.service";
import { HttpApiClient } from "./http-api-client";
import { HttpUserApiService } from "./http-user-api/http-user-api.service";


export abstract class HttpApiService extends HttpApiBaseService {
  constructor(protected httpClient: HttpApiClient, protected envService: EnviromentService, protected product: ApiProduct = ApiProduct.Orga) {
    super(httpClient, envService, product);
  }

  public addIdentity(identity: Identity): HttpUserApiService {
    return new HttpUserApiService(this.httpClient, identity, this.envService, this.product);
  }
}


@Injectable({
  providedIn: 'root'
})
export class HttpAnonymApiService extends HttpApiService {
  constructor(
    httpClient: HttpApiClient,
    envService: EnviromentService
  ) {
    super(httpClient, envService, ApiProduct.Orga);
  }
}


@Injectable({
  providedIn: 'root'
})
export class HttpIdentityAnonymApiService extends HttpApiService {
  constructor(
    httpClient: HttpApiClient,
    envService: EnviromentService
  ) {
    super(httpClient, envService, ApiProduct.Identity);
  }
}