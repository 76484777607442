import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OverviewDto } from '../../../models/server/DataTransferObject/Objects/Overviews';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  private endpoint: string = "overview";

  constructor(
    protected http: HttpLocationApiService
  ) { }

  async getOverviewInfo() : Promise<OverviewDto> {
    return this.http.get<OverviewDto>(`${this.endpoint}`).pipe(
        map((response) => response.body)
    ).toPromise();
  }
}
