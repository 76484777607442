import { HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from 'rxjs';
import { HttpApiClient } from './http-api-client';
import { EnviromentService } from "../../ui/enviroment.service";
import { ApiProduct } from "../../../models/client";
import { EnviromentTyping } from "../../../../environments/enviroment-typings";

export abstract class HttpApiBaseService {
  protected baseUrl: string;

  constructor(protected httpClient: HttpApiClient, protected envService: EnviromentService, protected product: ApiProduct = ApiProduct.Orga) {
    this.setEnviroment();
  }

  protected setEnviroment() {
    this.envService.env$.subscribe(e => this.baseUrl = this.getProductUrl(e, this.product));
  }

  public get<T>(path: string, headers: HttpHeaders = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    return this.httpClient.get<T>([this.baseUrl, path].join('/'), { headers: headers, observe: "response", params: params });
  }

  public post<T>(path: string, body: any, headers: HttpHeaders = null, params: HttpParams = null): Observable<HttpResponse<T>> {
    let postUrl = [this.baseUrl, path].join('/');

    return this.httpClient.post<T>(postUrl, body, { headers: headers, observe: "response", params: params});
  }

  public delete<T>(path: string, id: string, headers: HttpHeaders = null): Observable<HttpResponse<T>> {
    return this.httpClient.delete<T>([this.baseUrl, path, id].join('/'), { headers: headers, observe: "response" });
  }

  public update<T>(path: string, object: T, headers: HttpHeaders = null): Observable<HttpResponse<T>> {
    return this.httpClient.put<T>([this.baseUrl, path].join('/'), object, { headers: headers, observe: "response" });
  }

  protected getProductUrl(env: EnviromentTyping, product: ApiProduct) {
    switch(product) {
      case ApiProduct.Identity:
        return env.apiUrl.identity;
      case ApiProduct.Orga:
        return env.apiUrl.orga;
      case ApiProduct.Tech:
        return env.apiUrl.tech;
    }

    throw new Error("unknown product endpoint");
  }
}
