import { AppNotification } from "./app-notification";

export class TaskNotification extends AppNotification {
    constructor(
        public title: string = null,
        public message: string = null,
        public foreground: boolean = false,
        public taskId: number = 0
    ) {
        super(title, message, foreground);
    }
}