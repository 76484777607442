//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ExecutionPlanViewDto } from '../Executions';

export class TaskListDto implements IIdentityData
{
	@Column() @Filterable() public taskId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public description: string;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public isEnabled: boolean;
	@Column() @Filterable() public state: number;
	@Column() @Filterable() public parentId: number;
	@Column() public executionPlan: ExecutionPlanViewDto;
	@Column() @Filterable() public executionPlanId: number;
	@Column() public requiresAuthentication: boolean;
	@Column() public reasonSetId: number;
	@Column() public tenantId: number;
}
export class ConfirmationTaskListDto extends TaskListDto implements IIdentityData
{
}
export class DocumentTaskListDto extends TaskListDto implements IIdentityData
{
	@Column() public position: number;
}
