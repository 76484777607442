//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { ValidationDto } from '../../Validations';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { ILocationGroupRelationDto } from '../MasterData';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { LocationDto } from './Locations';

export class LocationGroupSimpleDto
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
}
export class LocationGroupSimpleListDto
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
}
export class DisplayLocationGroupListDto
{
	@Column() public locationGroupId: number;
	@Column() public isSystem: boolean;
	@Column() public name: string;
}
export class LocationGroupDto extends ValidationDto implements IIdentityData, IStereotypedDto
{
	@Column() public locationGroupId: number;
	@Column() public name: string;
	@Column() public type: number;
	@Column() public rowVersion: number[];
	@Column() public tenantId: number;
	@Column() public externalId: string;
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class LocationGroupInfoDto implements ILocationGroupRelationDto
{
	@Column() public infoId: number;
	@Column() public locationGroupId: number;
}
export class LocationGroupListDto implements IIdentityData
{
	@Column() @Filterable() public locationGroupId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public type: number;
	@Column() @Filterable() public isSystem: boolean;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public externalId: string;
	@Column() public rowVersion: number[];
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
}
export class LocationGroupLocationDto
{
	@Column() public locationId: number;
	@Column() public location: LocationDto;
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupDto;
}
export class LocationGroupRelationDto
{
	@Column() public locationGroupId: number;
	@Column() public locationGroup: LocationGroupSimpleDto;
}
export class LocationGroupTaskDto implements ILocationGroupRelationDto
{
	@Column() public taskId: number;
	@Column() public locationGroupId: number;
}
export class DisplayLocationGroupDto extends LocationGroupDto implements IIdentityData, IStereotypedDto
{
}
export class LocationGroupListForContactDto extends LocationGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationGroupListForLocationDto extends LocationGroupListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
