//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';

export class OverviewDto
{
	@Column() public infosTodayCount: number;
	@Column() public infosWeekCount: number;
	@Column() public tasksOpenCount: number;
	@Column() public tasksOverdueCount: number;
	@Column() public tasksEscalatedCount: number;
	@Column() public chatUnreadCount: number;
	@Column() public chatsTodayCount: number;
	@Column() public locationName: string;
	@Column() public locationId: number;
	@Column() public missionsOpenCount: number;
}
