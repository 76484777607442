//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { StateSimpleDto } from './StateMachines';
import { IStereotypedDto } from './Stereotyping/Stereotypes';
import { IIdentityData } from '../Objects';
import { IArchiveDto } from '../Objects';
import { ValidationDto } from '../Validations';
import { ContactSimpleDto } from './MasterData/Contact';
import { ResourceInTicketDto } from './Resources';
import { EscalationLevelSimpleDto } from './Tickets/Escalations';
import { PrioritySimpleDto } from './Tickets/Priorities';
import { LinkDto } from './Links';
import { AttachmentForTechDto } from './Attachments';
import { FilledCustomValueDto } from './Stereotyping/FilledCustomValues';
import { StateSimpleListDto } from './StateMachines';
import { PrioritySimpleListDto } from './Tickets/Priorities';
import { ResourceSimpleListDto } from './Resources';
import { EscalationLevelSimpleListDto } from './Tickets/Escalations';
import { StereotypeSimpleListDto } from './Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from './Stereotyping/FilledCustomValues';

export class StateChangeRequestDto
{
	@Column() public ticketId: number;
	@Column() public stateId: number;
	@Column() public reason: string;
}
export class StatePreviewRequestDto
{
	@Column() public stateMachineId: number;
	@Column() public stateId: number;
}
export class StatePreviewResponseDto
{
	@Column() public nextStates: StateSimpleDto[];
}
export class TicketDto extends ValidationDto implements IStereotypedDto, IIdentityData, IArchiveDto
{
	@Column() public id: number;
	@Column() public ticketId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public tenantId: number;
	@Column() public rowVersion: number[];
	@Column() @DateColumn() public lastModified: string;
	@Column() @DateColumn() public createdAt: string;
	@Column() public createdByUserId: number;
	@Column() public isArchived: boolean;
	@Column() public solutionId: number;
	@Column() public currentStateId: number;
	@Column() public currentState: StateSimpleDto;
	@Column() public editorId: number;
	@Column() public editor: ContactSimpleDto;
	@Column() public resourceId: number;
	@Column() public resource: ResourceInTicketDto;
	@Column() public escalationLevelId: number;
	@Column() public escalationLevel: EscalationLevelSimpleDto;
	@Column() public priorityId: number;
	@Column() public priority: PrioritySimpleDto;
	@Column() public links: LinkDto[];
	@Column() public attachments: AttachmentForTechDto[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class TicketInMissionDto implements IIdentityData
{
	@Column() public ticketId: number;
	@Column() public title: string;
	@Column() public resource: any;
}
export class TicketListDto implements IIdentityData
{
	@Column() @Filterable() public ticketId: number;
	@Column() @Filterable() public title: string;
	@Column(true) @Filterable() public description: string;
	@Column() @Filterable() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public isArchived: boolean;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public resourceId: number;
	@Column() @Filterable() public currentStateId: number;
	@Column() @Filterable() public currentState: StateSimpleListDto;
	@Column() @Filterable() public editor: ContactSimpleDto;
	@Column() @Filterable() public priority: PrioritySimpleListDto;
	@Column() @Filterable() public resource: ResourceSimpleListDto;
	@Column() @Filterable() public escalationLevelId: number;
	@Column() @Filterable() public escalationLevel: EscalationLevelSimpleListDto;
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
}
export class TicketSimpleDto implements IIdentityData
{
	@Column() public ticketId: number;
	@Column() public title: string;
	@Column() public isArchived: boolean;
}
export class TicketSimpleListDto implements IIdentityData
{
	@Column() public ticketId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public isArchived: boolean;
}
