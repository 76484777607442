import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { TaskJobListDto, TaskJobDto, ChangeTaskJobStatusDto, TaskJobStateDto } from '../../../models/server/DataTransferObject/Objects/TaskJobs';
import { Orders, TaskJobState } from '../../../models/server/Enums';
import { PageableService } from '../base/pageable.service';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';
import { FilterDto } from '../../../models/server/DataTransferObject/Filters';
import { Identity } from '../../../models/client';
import { HttpApiBaseService } from '../http/http-api-base.service';
import { map, filter, tap } from 'rxjs/operators';
import { from, Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TaskJobService extends PageableService<TaskJobListDto> {

  public stateChanged = new BehaviorSubject<{ taskJobId: number, state: TaskJobState }>(undefined);
  public stateChanged$: Observable<{ taskJobId: number, state: TaskJobState }> = this.stateChanged.asObservable();

  public pageChanged = new Subject();
  public pageChanged$: Observable<any> = this.pageChanged.asObservable();

  constructor(
    protected http: HttpLocationApiService
  ) {
    super(http, 'taskjob');
  }

  public addIdentity(identity: Identity): TaskJobService {
    var service = new TaskJobService(this.http.addIdentity(identity) as HttpApiBaseService as HttpLocationApiService);

    service.stateChanged$ = this.stateChanged$;
    service.pageChanged$ = this.pageChanged$;
    service.stateChanged = this.stateChanged;
    service.pageChanged = this.pageChanged;

    return service;
  }

  public async next(): Promise<PageableService<TaskJobListDto>> {
    this.setFilters(
      ...this.currentFilters,
      <FilterDto>{
        property: ""
      }
    );

    return super.next();
  }

  protected addItems(newItems: TaskJobListDto[]) {
    super.addItems(newItems);

    from(this.items).pipe(
      filter(x => x.task && x.task.children && x.task.children.length > 0),
      tap(x => x.children = x.children.sort((a, b) => a.task.position - b.task.position))
    ).toPromise();
  }

  public async get(jobId: number) {
    return this.http
      .get<TaskJobDto>(`${this.endpoint}/${jobId}`)
      .pipe(map(x => x.body))
      .toPromise();
  }

  protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null): HttpParams {
    return super
      .createHttpParams(pageNumber, pageSize, sortingParams)
      .set('sort', Orders.Descending.toString())
      .set('sortField', 'CurrentStatus')
      .set('sortThen', Orders.Ascending.toString())
      .set('sortThenField', 'ExecutionDate');
  }

  public async setJobState(job: TaskJobListDto, state: TaskJobState, reasonId: number = 0, publishStateChange: boolean = true) {
    const body = new ChangeTaskJobStatusDto();
    body.targetState = state;
    body.reasonId = reasonId;

    const response = this.http.post(`${this.endpoint}/${job.taskJobId}/status`, body).toPromise();
    job.currentStatus = state;
    job.jobStatus = job.jobStatus || [];
    job.jobStatus.push(<TaskJobStateDto>{
      status: state
    });

    if (publishStateChange) {
      this.stateChanged.next({
        taskJobId: job.taskJobId,
        state: state
      });
    }

    return response;
  }
}
