//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';
import { ShortContactDto } from './MasterData/Contact';

export class AppTenantDto implements IIdentityData
{
	@Column() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() public domain: string;
	@Column() @Filterable() public displayName: string;
}
export class CreateAppTenantDto extends ValidationDto
{
	@Column() public tenantId: number;
	@Column() public name: string;
	@Column() public domain: string;
	@Column() public displayName: string;
	@Column() public admin: ShortContactDto;
	@Column() public seed: number;
}
export class ShareIdentifierDto
{
	@Column() public identifier: string;
	@Column() public shareIdentifier: string;
}
