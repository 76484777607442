import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerPage } from './pdf-viewer.page';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerES5FixDirective } from './fix/pdf-viewer-es5fix';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    NgxExtendedPdfViewerModule 
  ],
  declarations: [PdfViewerPage, PdfViewerES5FixDirective]
})
export class PdfViewerPageModule {}
