//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';
import { FileDto } from './Files';

export class AppliedTemplateDto
{
	@Column() public uri: string;
}
export class DocumentTemplateDto extends ValidationDto implements IIdentityData
{
	@Column() public documentTemplateId: number;
	@Column() public title: string;
	@Column() public template: FileDto;
	@Column() public tenantId: number;
	@Column() public isDefault: boolean;
	@Column() public culture: string;
	@Column() public contextType: number;
}
export class DocumentTemplateListDto implements IIdentityData
{
	@Column() @Filterable() public documentTemplateId: number;
	@Column() @Filterable() public title: string;
	@Column() @Filterable() public tenantId: number;
}
export class PreviewDocumentTemplateDto
{
	@Column() public contextType: number;
	@Column() public fileId: number;
}
export class RenderDocumentTemplateDto
{
	@Column() public templateId: number;
}
