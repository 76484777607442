import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/app/tabs/overview', pathMatch: 'full' },
  { path: 'device-register', loadChildren: './pages/device-register/device-register.module#DeviceRegisterPageModule' },
  { path: 'version-error', loadChildren: './pages/version-error/version-error.module#VersionErrorPageModule' },
  { path: 'app', canActivate: [AuthGuard], runGuardsAndResolvers: 'always', children:
    [
      { path: 'tabs', loadChildren: './pages/tabs/tabs.module#TabsPageModule' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
