//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { DateTimePatternDto } from './DateTimePatterns';
import { ValidationDto } from '../Validations';

export class ExecutionPlanDto extends ValidationDto implements IIdentityData
{
	@Column() public executionId: number;
	@Column() public type: number;
}
export class ExecutionPlanViewDto
{
	@Column() public executionId: number;
	@Column() public type: number;
}
export class ManualExecutionDto extends ExecutionPlanDto implements IIdentityData
{
}
export class ManualExecutionPlanViewDto extends ExecutionPlanViewDto
{
}
export class PlannedExecutionDto extends ExecutionPlanDto implements IIdentityData
{
	@Column() public startMinuteOffset: string;
	@Column() public patterns: DateTimePatternDto[];
}
export class PlannedExecutionPlanViewDto extends ExecutionPlanViewDto
{
	@Column() public startMinuteOffset: string;
	@Column() public patterns: DateTimePatternDto[];
}
