//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../../Attributes';
import { IIdentityData } from '../../../Objects';
import { OrgaAttachmentDto } from '../../Attachments';
import { ValidationDto } from '../../../Validations';
import { FormDefaultDto } from './Defaults';

export class FormFieldCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public formFieldId: number;
	@Column() public title: string;
	@Column() public width: number;
	@Column() public isRequired: boolean;
	@Column() public type: number;
	@Column() public position: number;
	@Column() public defaultValues: FormDefaultDto[];
	@Column() public localFieldId: string;
}
export class AttachmentFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public attachmentId: number;
	@Column() public attachment: OrgaAttachmentDto;
}
export class CheckboxFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
}
export class DateFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() @DateColumn() public maxValue: string;
	@Column() @DateColumn() public minValue: string;
}
export class DropDownFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public isRateable: boolean;
	@Column() public style: number;
}
export class InfoFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public content: string;
	@Column() public contentHtml: string;
}
export class MultilineFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public minLength: number;
	@Column() public maxLength: number;
}
export class NumberFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public maxValue: number;
	@Column() public minValue: number;
	@Column() public positiveTolerance: number;
	@Column() public negativeTolerance: number;
	@Column() public setPointMin: number;
	@Column() public setPointMax: number;
	@Column() public isRateable: boolean;
	@Column() public unit: string;
}
export class PictureFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
}
export class SignatureFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
}
export class TextFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public maxLength: number;
	@Column() public minLength: number;
}
export class TimeFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() @DateColumn() public minValue: string;
	@Column() @DateColumn() public maxValue: string;
}

export class SearchListFormFieldDto extends FormFieldCreateDto implements IIdentityData
{
	@Column() public searchListId: number;
}
