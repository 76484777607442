
import { HttpParams } from '@angular/common/http';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { OrgaFolderItemDto } from '../../../models/server/DataTransferObject/Objects/Documents';
import { PageableService } from '../base/pageable.service';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';
import {Orders} from '../../../models/server/Enums';


export class DocumentsService extends PageableService<OrgaFolderItemDto> {

  protected path = '';

  constructor(http: HttpLocationApiService, path: string = '', protected searchText: string = null) {
    super(http, !searchText ? 'document' : 'document/search');
    this.path = path || '';
  }

  protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null) : HttpParams {
    return super
      .createHttpParams(pageNumber, pageSize, sortingParams)
      .set('path', this.path)
      .set('searchText', this.searchText)
      .set('sortField', 'Type')
      .set('sort', Orders.Descending.toString())
      .set('sortThenField', 'Name')
      .set('sortThen', Orders.Ascending.toString());
  }
}
