//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { ValidationDto } from '../../Validations';

export class MissionDescriptionContextDto extends ValidationDto implements IIdentityData
{
	@Column() public missionId: number;
	@Column() public tenantId: number;
	@Column() public title: string;
	@Column() @DateColumn() public actualStart: string;
	@Column() @DateColumn() public actualEnd: string;
	@Column() @DateColumn() public plannedEnd: string;
	@Column() @DateColumn() public plannedStart: string;
	@Column() public ticketId: number;
	@Column() public stereotypeId: number;
	@Column() public stateName: string;
	@Column() public state: number;
}
