import { HttpParams } from '@angular/common/http';
import { Inject } from '@angular/core';
import { Page, PageHeader } from 'models/client';
import { environment } from '../../../../environments/environment';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { FilterDto } from '../../../models/server/DataTransferObject/Filters';
import { HttpApiService } from '../http/http-api.service';

export class PageableService<T> {

  public currentPage: PageHeader;
  public currentSorting: SortingParams;
  public currentFilters: FilterDto[];

  public items: T[];

  constructor(
    protected http: HttpApiService,
    protected endpoint: string,
    @Inject(environment.interceptor.apiPageDefaultSizeIdentifier) protected pageSize?: number
  ) {
    this.reset();
  }

  public async next(): Promise<PageableService<T>> {
    this.currentPage.pageNumber++;
    let newPage: Page<T> = null;
    const httpParams = this.createHttpParams();

    if (this.currentFilters.length > 0) {
      const response = await this.http.post<Page<T>>(`${this.endpoint}/filter`, this.currentFilters, null, httpParams).toPromise();
      newPage = response.body;
    } else {
      const response = await this.http.get<Page<T>>(`${this.endpoint}`, null, httpParams).toPromise();
      newPage = response.body;
    }

    this.addItems(newPage.items);
    this.currentPage = newPage.paging;

    return this;
  }

  protected addItems(newItems: T[]): void {
    // we need to distinct the items because sometimes different logics calls next() in parallel which needs to be fixed here
    // by locking next() or even their callers in UI, this could have been avoided

    const items = this.items
        .concat(newItems)
        .map(x => JSON.stringify(x));

    const itemsDistinct = Array
        .from(new Set(items))
        .map(x => JSON.parse(x));

    this.items = itemsDistinct;
  }

  protected removeItem(index: number): void {
    this.items = this.items.splice(index, 1);
  }

  public reset() {
    this.currentPage = new PageHeader(this.pageSize || environment.apiUrl.pageDefaultSize);
    this.currentSorting = new SortingParams();
    this.currentFilters = [];
    this.items = [];
  }

  public hasNextPage() {
    return this.currentPage.pageNumber < this.currentPage.totalPages;
  }

  public getFilters(): FilterDto[] {
    return this.currentFilters;
  }

  public setFilters(...filters: FilterDto[]) {
    this.currentFilters = filters;
  }

  public addFilters(...filters: FilterDto[]): void {
    this.currentFilters.push(...filters);
  }

  protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null) : HttpParams {

    let httpParams = new HttpParams()
      .set('pageNumber', (pageNumber || this.currentPage.pageNumber).toString())
      .set('pageSize', (pageSize || this.currentPage.pageSize).toString());

    if(this.currentSorting.sortField) {
      httpParams = httpParams
        .set('sort', (sortingParams || this.currentSorting).sort.toString())
        .set('sortField', (sortingParams || this.currentSorting).sortField);
    }

    return httpParams;
  }

}
