//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { MeDto } from './MeObjects';


export enum AppPlatform {
	Unknown = 0,
	iOs = 1,
	Android = 2,
	Natifier = 3,
	Browser = 4
}
export class LoginMeta
{
	public type: number;
}
export class AppLoginMeta extends LoginMeta
{
	public appPlatform: AppPlatform;
	public appVersion: string;
}

export class AllowedContextDto
{
	@Column() public tnt: number;
	@Column() public src: number;
	@Column() public type: number;
}
export class DecodedTokenDto
{
	@Column() public permissions: PermissionAreaDto[];
}
export class ExternalAppLoginDto
{
	@Column() public externalApp: number;
	@Column() public redirectUrl: string;
}
export class ExternalTokenDto
{
	@Column() public token: string;
	@Column() public redirectUrl: string;
}
export class PasswordLoginDto
{
	@Column() public password: string;
	@Column() public newPassword: string;
	@Column() public passwordBase64: string;
	@Column() public newPasswordBase64: string;
	@Column() public email: string;
}
export class PermissionAreaDto
{
	@Column() public type: number;
	@Column() public permissions: number[];
	@Column() public contexts: AllowedContextDto[];
}
export class PermissionPackageDto
{
	@Column() public package: number;
	@Column() public permissions: number[];
}
export class RefreshTokenDto
{
	@Column() public refreshToken: string;
	@Column() public roleIds: number[];
	@Column() public locationIds: number[];
	@Column() public meta: AppLoginMeta;
}
export class ResetPasswordDto
{
	@Column() public token: string;
	@Column() public newPassword: string;
	@Column() public newPasswordBase64: string;
	@Column() public email: string;
}
export class TokenDto
{
	@Column() public refreshToken: string;
	@Column() public token: string;
	@Column() public restrictedRoleIds: number[];
	@Column() public restrictedLocationIds: number[];
	@Column() public info: MeDto;
}

