import { EnviromentTyping } from "./enviroment-typings";

export const environment: EnviromentTyping = {
  production: true,
  apiUrl: {
    identity: "https://identity.api.semco.app",
    orga: "https://orga.api.semco.app",
    data: "https://data.api.semco.app",
    tech: "https://tech.api.semco.app",
    pageDefaultSize: 25,
    defaultTimeoutSec: 30,
    tokenExpireOffsetSec: 59
  },
  lang: {
    defaultLanguage: "de-DE",
    useBrowserLanguage: false
  },
  idle: {
    autoRefreshOnViewAfter: 1000 * 60 * 2,
    idleTime: 60 * 5
  },
  alert: {
    batteryStatusAlertLevel: 20
  },
  image: {
    formImageResizeMaxSize: 1366
  },
  interceptor: {
    additionalHttpApiClientInterceptorsIdentifier: "additionalHttpApiClientInterceptors",
    apiPageDefaultSizeIdentifier: "apiPageDefaultSize",
    apiDefaultTimeoutSecIdentifier: "apiDefaultTimeoutSec"
  }
};
