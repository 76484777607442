import { Platform } from "@ionic/angular";
import { Injectable } from "@angular/core";

declare var cordova:any;

@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    constructor(
        private platform: Platform
    ) { }

    public isNative() {
        return this.isIos() || this.isAndroid();
    }
    
    public isNativer() {
        return navigator.userAgent.indexOf('semcoorga-nativefier') > -1;
    }

    public isAndroid() {
        return this.getId() == 'ANDROID';
    }
    
    public isIos() {
        return this.getId() == 'IOS';
    }

    public isBrowser() {
        return this.getId() == 'BROWSER';
    }

    public isTablet() {
        return this.platform.is('tablet');
    }

    public isMobile() {
        return this.platform.is('mobile');
    }

    public getId() {
        return cordova?.platformId?.toUpperCase();
    }
}