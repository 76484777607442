//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { ContactDto } from '../MasterData/Contact';
import { ContactGroupSimpleDto } from '../MasterData/ContactGroups';
import { LocationSimpleDto } from '../MasterData/Locations';

export class ChatMemberDto
{
	@Column() public chatMemberId: number;
	@Column() public type: number;
	@Column() public concreteMemberId: number;
	@Column() public concreteMemberName: string;
}
export class ChatMemberListDto
{
	@Column() public chatMemberId: number;
	@Column() public type: number;
	@Column() public name: string;
	@Column() public locationName: string;
	@Column() public contactName: string;
	@Column() public contactGroupName: string;
	@Column() public externalName: string;
	@Column() public concreteId: number;
	@Column() public locationId: number;
	@Column() public contactId: number;
	@Column() public contactGroupId: number;
}
export class ContactChatMemberDto extends ChatMemberDto
{
	@Column() public contactId: number;
	@Column() public contact: ContactDto;
}
export class ContactGroupChatMemberDto extends ChatMemberDto
{
	@Column() public functionId: number;
	@Column() public function: ContactGroupSimpleDto;
}
export class ExternalChatMemberDto extends ChatMemberDto
{
	@Column() public emailAddress: string;
}
export class LocationChatMemberDto extends ChatMemberDto
{
	@Column() public locationId: number;
	@Column() public location: LocationSimpleDto;
}
