import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { InformedMember, UninformedMember } from "../../../models/client/infos/info-member";
import { HttpLocationApiService } from "../http/http-location-api/http-location-api.service";
import { Page } from "../../../models/client";
import { FilterDto } from "../../../models/server/index-objects";
import { AuthenticationService } from "../auth/authentication.service";

const SERVICE_ENDPOINT = 'v2/info';

@Injectable({
  providedIn: 'root'
})
export class InfoReadsService {
    constructor(
        protected http: HttpLocationApiService,
        protected authService: AuthenticationService
    ) {}

    async getReadsAll(infoId: number): Promise<InformedMember[]> {
      return this.http
        .get<Page<InformedMember>>(`${SERVICE_ENDPOINT}/${infoId}/contact?pageSize=1000`)
        .pipe(map(x => x.body.items))
        .toPromise();
  }

    async getReads(infoId: number): Promise<InformedMember[]> {
        const filter = <FilterDto> {
          property: "location.locationId",
          value: this.authService.getLocationId().toString()
        };

        return this.http
          .post<Page<InformedMember>>(`${SERVICE_ENDPOINT}/${infoId}/contact/locations/filter?pageSize=1000`, [filter])
          .pipe(map(x => x.body.items))
          .toPromise();
    }

    async getUnReads(infoId: number): Promise<UninformedMember[]> {
        return this.http
          .get<Page<InformedMember>>(`${SERVICE_ENDPOINT}/${infoId}/contact/unread?pageSize=1000`)
          .pipe(map(x => x.body.items))
          .toPromise();
    }
}