//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { FileDto } from './Files';

export class OrgaDocumentAccessDto
{
	@Column() public canAccessPdfMail: boolean;
	@Column() public canAccessPdfPrint: boolean;
	@Column() public canAccessPdfShare: boolean;
}
export class OrgaFolderItemDto
{
	@Column() public name: string;
	@Column() public path: string;
	@Column() public type: number;
}
export class OrgaDocumentDto extends OrgaFolderItemDto
{
	@Column() public file: FileDto;
	@Column() public access: OrgaDocumentAccessDto;
}
export class OrgaFolderDto extends OrgaFolderItemDto
{
}
