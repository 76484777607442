import { EnviromentTyping } from "./enviroment-typings";

export const environment: EnviromentTyping = {
    production: true,
    apiUrl: {
      identity: "https://identity.test.api.semco.app",
      orga: "https://orga.test.api.semco.app",
      data: "https://data.test.api.semco.app",
      tech: "https://tech.test.api.semco.app",
      pageDefaultSize: 25,
      defaultTimeoutSec: 30,
      tokenExpireOffsetSec: 59
    },
    lang: {
      defaultLanguage: "de-DE",
      useBrowserLanguage: false
    },
    idle: {
      autoRefreshOnViewAfter: 1000 * 60 * 2,
      idleTime: 60 * 5
    },
    alert: {
      batteryStatusAlertLevel: 20
    },
    image: {
      formImageResizeMaxSize: 1366
    },
    interceptor: {
      additionalHttpApiClientInterceptorsIdentifier: "additionalHttpApiClientInterceptors",
      apiPageDefaultSizeIdentifier: "apiPageDefaultSize",
      apiDefaultTimeoutSecIdentifier: "apiDefaultTimeoutSec"
    }
  };
