import {Component, OnInit} from '@angular/core';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';
import {FileTransfer} from '@ionic-native/file-transfer/ngx';
import {File} from '@ionic-native/file/ngx';
import {NavParams} from '@ionic/angular';
import {NgxExtendedPdfViewerService, PagesLoadedEvent} from 'ngx-extended-pdf-viewer';
import {FileDto, GeneralSettingDto, PdfViewBehaviour} from '../../models/server/index-objects';
import {PlatformService} from '../../services/ui/device/platform.service';
import {ModalService} from '../../services/ui/modal.service';
import {GeneralSettingService} from '../../services/api/setting/general-setting.service';
import {LoginDialogService} from '../../services/ui/auth/login-dialog.service';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.page.html',
  styleUrls: ['./pdf-viewer.page.scss'],
  providers: [FileOpener, FileTransfer, File]
})
export class PdfViewerPage implements OnInit {

  public file: FileDto;
  public isSidebarVisible = false;
  public currentPage = 1;
  public pagesCount = 1;
  public zoomPercentage = 100;
  public zoomLevel: string;
  public isSearch = false;
  public loader: HTMLIonLoadingElement;
  public generalSetting: GeneralSettingDto;
  public pdfViewBehaviour: typeof PdfViewBehaviour = PdfViewBehaviour;
  public isPlatformNative = true;
  public isLocked: boolean;

  constructor(
    protected modalService: ModalService,
    protected navParams: NavParams,
    protected pdfService: NgxExtendedPdfViewerService,
    protected fileOpener: FileOpener,
    protected transfer: FileTransfer,
    protected fileService: File,
    protected platformService: PlatformService,
    protected generalSettingService: GeneralSettingService,
    protected loginDialogService: LoginDialogService
  ) {
    this.file = navParams.get('file');
    this.generalSettingService.cache().subscribe(x => this.generalSetting = x);
  }

  async ngOnInit() {
    if (this.generalSetting?.pdfViewBehaviour === PdfViewBehaviour.External) {
      await this.openExternal(true);
      this.exit();
    } else {
      this.isPlatformNative = this.platformService.isNative();
      this.loader = await this.modalService.createLoader();
      await this.loader.present();
    }
  }

  async lock() {
    const userIdentity = await this.loginDialogService.getIdentityFromUserLogin();
    const isSuccess = userIdentity && userIdentity.identity;

    if (isSuccess) {
      this.isLocked = true;
      (await this.modalService.createInfoToast('PDF.LOCKED')).present();
    }
  }

  async unlock() {
    const userIdentity = await this.loginDialogService.getIdentityFromUserLogin();
    const isSuccess = userIdentity && userIdentity.identity;

    if (isSuccess) {
      this.isLocked = false;
      (await this.modalService.createInfoToast('PDF.UNLOCKED')).present();
    }
  }

  zoomChange($event) {
    this.zoomPercentage = Math.round($event * 100);

    if (!this.zoomLevel) {
      this.zoomLevel = this.zoomPercentage.toString();
    }
  }

  pagesLoaded(event: PagesLoadedEvent) {
    this.pagesCount = event.pagesCount;
    this.loader?.dismiss();
  }

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  shiftPage(offset: number) {
    this.currentPage += offset;
    this.currentPage = Math.max(1, Math.min(this.currentPage, this.pagesCount));
  }
  shiftZoom(offset: number) {
    const increase = this.zoomPercentage * 0.10 * offset;
    this.zoomLevel = (this.zoomPercentage + increase).toString();
    this.zoomLevel = Math.max(10, Math.min(+this.zoomLevel, 1000)).toString();
  }

  onSearch($event) {
    const value = $event.detail.value;
    this.isSearch = !!value;
    this.pdfService.find(value);
  }

  searchNext() {
    this.pdfService.findNext();
  }
  searchPrev() {
    this.pdfService.findPrevious();
  }

  async openExternal(useDefault: boolean) {
    const loader = await this.modalService.createLoader();
    await loader.present();

    try {
      if (this.platformService.isNative()) {

        const fileTransfer = this.transfer.create();
        const fileName = this.fileService.cacheDirectory + this.file.hash;

        await fileTransfer.download(
          this.file.uri,
          fileName
        );

        if (useDefault === true) {
          await this.fileOpener.open(fileName, 'application/pdf');
        } else {
          await this.fileOpener.showOpenWithDialog(fileName, 'application/pdf');
        }

      } else {
        const tab = window.open();

        if (!tab?.location) {
          throw new Error('Device does not support PDF opening');
        }

        tab.location.href = this.file.uri;
      }


      await loader.dismiss();
    } catch (error) {
      console.error(event);
      await loader.dismiss();
      await (await this.modalService.createErrorAlertWithDetail(error)).present();
    }

  }

  exit() {
    this.zoomLevel = this.zoomPercentage.toString(); // needed to save this zoom level if user did not touch the zoom
    this.modalService.dismissModal();
  }

  public async pdfLoadingFailed(event) {
    console.error(event);

    if (this.loader) {
      await this.loader.dismiss();
    }

    await (await this.modalService.createErrorAlertWithDetail(event)).present();
  }
}
