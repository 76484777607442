//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { ValidationDto } from '../../Validations';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { StereotypeSimpleDto } from '../Stereotyping/Stereotypes';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { LocationDto } from './Locations';

export class AddressDto extends ValidationDto implements IIdentityData, IStereotypedDto
{
	@Column() public addressId: number;
	@Column() public tenantId: number;
	@Column() public street: string;
	@Column() public houseNumber: string;
	@Column() public city: string;
	@Column() public state: string;
	@Column() public zipcode: string;
	@Column() public countryCode: string;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class AddressInMissionDto implements IIdentityData
{
	@Column() public addressId: number;
	@Column() public street: string;
	@Column() public houseNumber: string;
	@Column() public city: string;
	@Column() public state: string;
	@Column() public stereotype: StereotypeSimpleDto;
}
export class AddressListDto implements IIdentityData
{
	@Column() public addressId: number;
	@Column() public tenantId: number;
	@Column() @Filterable() public street: string;
	@Column() @Filterable() public houseNumber: string;
	@Column() @Filterable() public city: string;
	@Column() @Filterable() public state: string;
	@Column() @Filterable() public zipcode: string;
	@Column() @Filterable() public countryCode: string;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
}
export class AddressLocationDto
{
	@Column() @Filterable() public addressId: number;
	@Column() public address: AddressDto;
	@Column() @Filterable() public locationId: number;
	@Column() public location: LocationDto;
}
export class AddressListForCompanyDto extends AddressListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class AddressListForLocationDto extends AddressListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
