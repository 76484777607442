import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { BatteryStatus } from '@ionic-native/battery-status/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChatReceiversPageModule } from 'pages/chat-receivers/chat-receivers.module';
import { ChatReceiversPage } from 'pages/chat-receivers/chat-receivers.page';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FailedChunkErrorHandler } from './helpers/failed-chunk-error-handler';
import { DeviceRegisterManualPageModule } from './pages/device-register-manual/device-register-manual.module';
import { DeviceRegisterManualPage } from './pages/device-register-manual/device-register-manual.page';
import { FormFieldPictureOverlayPageModule } from './pages/form/form-field-picture-overlay/form-field-picture-overlay.module';
import { FormFieldPictureOverlayPage } from './pages/form/form-field-picture-overlay/form-field-picture-overlay.page';
import { InfoReadsPageModule } from './pages/info-reads/info-reads.module';
import { InfoReadsPage } from './pages/info-reads/info-reads.page';
import { BadgeLoginPageModule } from './pages/login/badge-login/badge-login.module';
import { PasswordLoginPageModule } from './pages/login/password-login/password-login.module';
import { PasswordLoginPage } from './pages/login/password-login/password-login.page';
import { PinLoginPageModule } from './pages/login/pin-login/pin-login.module';
import { PinLoginPage } from './pages/login/pin-login/pin-login.page';
import { PdfViewerPageModule } from './pages/pdf-viewer/pdf-viewer.module';
import { PdfViewerPage } from './pages/pdf-viewer/pdf-viewer.page';
import { PopoverMorePageModule } from './pages/popover-more/popover-more.module';
import { PopoverMorePage } from './pages/popover-more/popover-more.page';
import { PopoverSettingsPageModule } from './pages/popover-settings/popover-settings.module';
import { PopoverSettingsPage } from './pages/popover-settings/popover-settings.page';
import { ResourcePickerPageModule } from './pages/resource-picker/resource-picker.module';
import { ResourcePickerPage } from './pages/resource-picker/resource-picker.page';
import { TaskCancelPageModule } from './pages/task-cancel/task-cancel.module';
import { TaskCancelPage } from './pages/task-cancel/task-cancel.page';
import { AuthenticationService } from './services/api/auth/authentication.service';
import { ResourceService } from './services/api/process/resource.service';
import {PhotoViewPageModule} from './pages/photo-view/photo-view.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [ChatReceiversPage, ResourcePickerPage, InfoReadsPage, PdfViewerPage, PinLoginPage, PasswordLoginPage, DeviceRegisterManualPage, PopoverMorePage, PopoverSettingsPage, TaskCancelPage, FormFieldPictureOverlayPage],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      spinner: 'dots'
    }),
    IonicStorageModule.forRoot({ name: 'orga', driverOrder: AppModule.resolveStorageOrder() }),
    AppRoutingModule,
    HttpClientModule,
    ResourcePickerPageModule,
    InfoReadsPageModule,
    PdfViewerPageModule,
    PinLoginPageModule,
    BadgeLoginPageModule,
    PasswordLoginPageModule,
    DeviceRegisterManualPageModule,
    ChatReceiversPageModule,
    PopoverMorePageModule,
    PopoverSettingsPageModule,
    TaskCancelPageModule,
    FormFieldPictureOverlayPageModule,
    PhotoViewPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthenticationService,
    ResourceService,
    Diagnostic,
    AppVersion,
    Platform,
    File,
    FileTransfer,
    DocumentViewer,
    BatteryStatus,
    Deeplinks,
    InAppBrowser,
    { provide: environment.interceptor.additionalHttpApiClientInterceptorsIdentifier, useValue: [] },
    { provide: environment.interceptor.apiPageDefaultSizeIdentifier, useValue: environment.apiUrl.pageDefaultSize },
    { provide: environment.interceptor.apiDefaultTimeoutSecIdentifier, useValue: environment.apiUrl.defaultTimeoutSec },
    { provide: ErrorHandler, useClass: FailedChunkErrorHandler }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  private static resolveStorageOrder(): string[] {
    const cordova = window && (<any>window).cordova;
    
    if(cordova && cordova.platformId && cordova.platformId.toLocaleLowerCase() == 'browser') {
      return ['indexeddb', 'WebSQL', 'localstorage'];
    }
    
    return ['sqlite', 'indexeddb', 'WebSQL', 'localstorage'];
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
