//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';

export class HolidayDto
{
	@Column() public holidayId: number;
	@Column() public name: string;
	@Column() @DateColumn() public date: string;
}
export class HolidaySetDto extends ValidationDto implements IIdentityData
{
	@Column() public holidaySetId: number;
	@Column() public name: string;
	@Column() public tenantId: number;
	@Column() public holidays: HolidayDto[];
}
export class HolidaySetSimpleDto implements IIdentityData
{
	@Column() @Filterable() public holidaySetId: number;
	@Column() @Filterable() public name: string;
}
