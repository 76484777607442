//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';
import { ValidationDto } from '../Validations';
import { FormDto } from './Forms/Viewing';
import { FileDto } from './Files';
import { FormValueDto } from './Forms/Create/Values';

export class FilledFormCreateDto extends ValidationDto implements IIdentityData
{
	@Column() public filledFormId: number;
	@Column() public formId: number;
	@Column() public tenantId: number;
	@Column() public fields: FilledFormFieldCreateDto[];
}
export class FilledFormDto implements IIdentityData
{
	@Column() public filledFormId: number;
	@Column() public form: FormDto;
	@Column() public ratingInPercent: number;
	@Column() public isArchived: boolean;
	@Column() public tenantId: number;
	@Column() public file: FileDto;
	@Column() public fields: FilledFormFieldCreateDto[];
}
export class FilledFormFieldCreateDto implements IIdentityData
{
	@Column() public filledFormFieldId: number;
	@Column() public fieldId: number;
	@Column() public rowIndex: number;
	@Column() public values: FormValueDto[];
}
export class TaskFormFilledFormCreateDto extends FilledFormCreateDto implements IIdentityData
{
	@Column() public taskJobId: number;
	@Column() public taskId: number;
}
