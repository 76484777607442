//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';
import { IIdentityData } from '../../Objects';
import { StereotypeSimpleDto } from '../Stereotyping/Stereotypes';
import { AddressInMissionDto } from './Address';
import { IStereotypedDto } from '../Stereotyping/Stereotypes';
import { FilledCustomValueDto } from '../Stereotyping/FilledCustomValues';
import { ILocationRelationDto } from '../MasterData';
import { StereotypeSimpleListDto } from '../Stereotyping/Stereotypes';
import { FilledCustomValueListDto } from '../Stereotyping/FilledCustomValues';
import { HolidaySetSimpleDto } from '../HolidaySets';

export class LocationInMissionDto implements IIdentityData
{
	@Column() public locationId: number;
	@Column() public name: string;
	@Column() public phoneNumber: string;
	@Column() public info: string;
	@Column() public stereotype: StereotypeSimpleDto;
	@Column() public addresses: AddressInMissionDto[];
}
export class LocationSimpleDto implements IIdentityData
{
	@Column() public locationId: number;
	@Column() public name: string;
	@Column() public locationNumber: string;
}
export class LocationDto implements IStereotypedDto, IIdentityData
{
	@Column() public locationId: number;
	@Column() public tenantId: number;
	@Column() public name: string;
	@Column() public geoLatitude: string;
	@Column() public geoLongitude: string;
	@Column() public emailAddress: string;
	@Column() public info: string;
	@Column() public phoneNumber: string;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public externalId: string;
	@Column() public stereotypeRowVersion: number[];
	@Column() public customPropertyValues: FilledCustomValueDto[];
}
export class LocationInfoDto implements ILocationRelationDto
{
	@Column() public locationId: number;
	@Column() public infoId: number;
}
export class LocationListDto implements IIdentityData
{
	@Column() @Filterable() public locationId: number;
	@Column() @Filterable() public tenantId: number;
	@Column() @Filterable() public name: string;
	@Column() @Filterable() public externalId: string;
	@Column() @Filterable() public geoLatitude: string;
	@Column() @Filterable() public geoLongitude: string;
	@Column() public tenantName: string;
	@Column() public rowVersion: number[];
	@Column() public stereotypeId: number;
	@Column() public stereotypeRowVersion: number[];
	@Column() @Filterable() public stereotype: StereotypeSimpleListDto;
	@Column() public customPropertyValues: FilledCustomValueListDto[];
}
export class LocationOrgaDto implements IIdentityData
{
	@Column() public locationId: number;
	@Column() public tenantId: number;
	@Column() public name: string;
	@Column() public maximalGuestCount: number;
	@Column() public emailAddress: string;
	@Column() public geoLatitude: string;
	@Column() public geoLongitude: string;
	@Column() public rowVersion: number[];
	@Column() public monday: boolean;
	@Column() public tuesday: boolean;
	@Column() public wednesday: boolean;
	@Column() public thursday: boolean;
	@Column() public friday: boolean;
	@Column() public saturday: boolean;
	@Column() public sunday: boolean;
	@Column() public holidaySetId: number;
	@Column() public holidaySet: HolidaySetSimpleDto;
	@Column() public isIncomingEmailEnabled: boolean;
	@Column() public externalId: string;
}
export class LocationRelationDto
{
	@Column() public locationId: number;
	@Column() public location: LocationSimpleDto;
}
export class LocationSimpleListDto
{
	@Column() @Filterable() public locationId: number;
	@Column() @Filterable() public name: string;
}
export class LocationTaskDto implements ILocationRelationDto
{
	@Column() public taskId: number;
	@Column() public locationId: number;
}
export class LocationListForAddressDto extends LocationListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationListForContactDto extends LocationListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationListForContactGroupsDto extends LocationListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
export class LocationListForLocationGroupDto extends LocationListDto implements IIdentityData
{
	@Column() public isInRelation: boolean;
}
