import { Injectable } from "@angular/core";
import { AuthenticationService } from "../auth/authentication.service";
import { HttpLocationApiService } from "../http/http-location-api/http-location-api.service";
import { BaseSettingService } from "./base-setting.service";
import { GeneralSettingDto } from "../../../models/server/DataTransferObject/Objects/Settings";
import { StorageKeys } from "../../../models/client";
import { from, of, merge, Observable } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { Storage } from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class GeneralSettingService extends BaseSettingService<GeneralSettingDto> {

    constructor(
        protected http: HttpLocationApiService,
        protected auth: AuthenticationService,
        protected storage: Storage
    ) {
        super(http, auth, 1);
    }

    public cache(): Observable<GeneralSettingDto> {
        const settingsStorageKey = StorageKeys.GeneralSettings + this.auth.getTenantId().toString();

        const storedSetting = from(this.storage.get(settingsStorageKey)).pipe(
            filter(x => x !== null),
            switchMap(x => of(JSON.parse(x) as GeneralSettingDto))
        );

        const cache = merge(storedSetting, this.setting$);

        cache.subscribe((x) => this.storage.set(settingsStorageKey, JSON.stringify(x)));

        return cache;
    }
}

