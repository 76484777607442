import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { Identity } from '../../models/client';
import { RegisterDeviceByLocationDto } from '../../models/server/DataTransferObject/Objects/Devices';
import { LocationListDto, LocationSimpleDto } from '../../models/server/DataTransferObject/Objects/MasterData/Locations';
import { DeviceRegisterService } from '../../services/api/auth/device-register.service';
import { ModalService } from '../../services/ui/modal.service';
import { MeDto } from '../../models/server/index-objects';
import { DeviceService } from '../../services/ui/device/device.service';

@Component({
  selector: 'app-chat-receivers',
  templateUrl: './chat-receivers.page.html',
  styleUrls: ['./chat-receivers.page.scss'],
})
export class ChatReceiversPage implements OnInit {

  public locations: LocationSimpleDto[] = [];

  constructor(
    private modalService: ModalService,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.locations = this.navParams.get('locations');
  }

  clear() {
    this.locations.forEach((x: any) => x.isSelected = false);
  }

  exit() {
    this.clear();
    this.modalService.dismissModal();
  }

  async done() {
    const selected = this.locations.filter((x: any) => x.isSelected);

    if(selected.length <= 0)
    {
      (await this.modalService.createInfoToast('CHATS.RECEIVERS.PICKVALIDATION')).present();
      return;
    }

    this.modalService.dismissModal({
      selected
    });
  }
}
