//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../Attributes';

export class TaskSimpleDto
{
	@Column() public taskId: number;
	@Column() public title: string;
	@Column() public type: number;
	@Column() public requiresAuthentication: boolean;
}
export class TaskSimpleListDto
{
	@Column() public taskId: number;
	@Column() public title: string;
	@Column() public description: string;
	@Column() public type: number;
	@Column() public children: TaskSimpleListDto[];
	@Column() public formId: number;
	@Column() public requiresAuthentication: boolean;
	@Column() public hasStrictOrder: boolean;
	@Column() public position: number;
	@Column() public reasonSetId: number;
}
export class CollectionTaskSimpleDto extends TaskSimpleDto
{
	@Column() public children: TaskSimpleDto[];
}
export class ConfirmationTaskSimpleDto extends TaskSimpleDto
{
}
export class DocumentTaskSimpleDto extends TaskSimpleDto
{
	@Column() public formId: number;
}
