//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { LocationRelationDto } from './MasterData/Locations';
import { LocationGroupRelationDto } from './MasterData/LocationGroups';
import { ContactRelationDto } from './MasterData/Contact';
import { ContactGroupRelationDto } from './MasterData/ContactGroups';
import { ContactSimpleDto } from './MasterData/Contact';

export class AppRoleInfoDto
{
	@Column() public roleId: number;
	@Column() public name: string;
	@Column() public kind: number;
	@Column() public term: number;
	@Column() public locations: LocationRelationDto[];
	@Column() public locationGroup: LocationGroupRelationDto[];
	@Column() public contacts: ContactRelationDto[];
	@Column() public contactGroups: ContactGroupRelationDto[];
}
export class MeDto
{
	@Column() public userId: number;
	@Column() public tenants: TenantInfoDto[];
}
export class TenantInfoDto
{
	@Column() public tenantId: number;
	@Column() public name: string;
	@Column() public domain: string;
	@Column() public displayName: string;
	@Column() public roles: AppRoleInfoDto[];
	@Column() public names: ContactSimpleDto[];
}
