import { Component, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { Identity } from '../../models/client';
import { ForbiddenError } from '../../models/client/errors/forbidden-error';
import { RegisterDeviceByLocationDto } from '../../models/server/DataTransferObject/Objects/Devices';
import { AppPermissions } from '../../models/server/Enums/Permissions';
import { AuthenticationService } from '../../services/api';
import { PermissionService } from '../../services/api/permission/permission.service';
import { LoginDialogService } from '../../services/ui/auth/login-dialog.service';
import { ModalService } from '../../services/ui/modal.service';
import { DeviceRegisterManualPage } from '../device-register-manual/device-register-manual.page';

@Component({
  selector: 'app-popover-settings',
  templateUrl: './popover-settings.page.html',
  styleUrls: ['./popover-settings.page.scss'],
})
export class PopoverSettingsPage implements OnInit {

  public lang: string = environment.lang.defaultLanguage;

  constructor(
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private modalService: ModalService,
    private loginDialogService: LoginDialogService,
    private auth: AuthenticationService,
    private permissionService: PermissionService
  ) { }

  ngOnInit() {
  }

  public async changeLocation() {
    this.popoverCtrl.dismiss();

    try {
      const loader = await this.modalService.createLoader();
      const identity = await this.loginDialogService.getIdentityFromPasswordLogin();

      if(!identity)
        return;

      if(await this.permissionService.checkUserPermission(AppPermissions.UseDeviceRegister, identity.identity.token) == false)
        throw new ForbiddenError();
        
      await this.tryRegister(identity.identity, loader);

    } catch(err) {
      if (err instanceof ForbiddenError) {
        (await this.modalService.createErrorAlert('DEVICEREGISTER.FORBIDDEN')).present();
      } else {
        console.error(err);
        (await this.modalService.createErrorAlertWithDetail(err, 'ERRORMSG')).present();
      }
    }
  }

  public async logout() {
    this.popoverCtrl.dismiss();

    try {
      const identity = await this.loginDialogService.getIdentityFromPasswordLogin();

      if(!identity)
        return;

      if(await this.permissionService.checkUserPermission(AppPermissions.UseDeviceRegister, identity.identity.token) == false)
        throw new ForbiddenError();
        
      await this.auth.logout(true);
      document.location.href = '/'

    } catch(err) {
      if (err instanceof ForbiddenError) {
        (await this.modalService.createErrorAlert('DEVICEREGISTER.FORBIDDEN')).present();
      } else {
        console.error(err);
        (await this.modalService.createErrorAlertWithDetail(err, 'ERRORMSG')).present();
      }
    }
  }

  async tryRegister(registratorUserIdentity: Identity, loader: HTMLIonLoadingElement) {
    try {
      const loginModal = await (await this.modalCtrl.create({ 
        component: DeviceRegisterManualPage,
        componentProps: { identity: registratorUserIdentity }
      }));
      loader.dismiss();
      loginModal.present();
  
      const response = await loginModal.onDidDismiss();
      const data = <RegisterDeviceByLocationDto>response.data;
      if(!response || !response.data) {
        return false;
      }
      loader.present();

      await this.auth.logout(false);
      await this.auth.loginWithDeviceRegister(registratorUserIdentity, data.deviceName, data.locationId);
      
      document.location.href = '/'
      loader.dismiss();
      
    } catch (error) {
      loader.dismiss();
      
      if (error instanceof ForbiddenError) {
        (await this.modalService.createErrorAlert('DEVICEREGISTER.FORBIDDEN')).present();
      } else {
        console.error(error);
        (await this.modalService.createErrorAlertWithDetail(error, 'DEVICEREGISTER.NOTREGISTERED')).present();
      }
      return false;
    }
  }
}
