//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { EmailAddressDto } from './Emails';
import { IHtmlViewData } from '../Objects';
import { FileDto } from './Files';
import { INotificationDto } from './Notifications';

export class QueueDto
{
}
export class MailInQueueDto extends QueueDto
{
	@Column() public domain: string;
	@Column() public subject: string;
	@Column() public from: string;
	@Column() public to: string;
	@Column() public fileId: number;
	@Column() public filePath: string;
}
export class MailOutQueueDto extends QueueDto
{
	@Column() public domain: string;
	@Column() public subject: string;
	@Column() public from: EmailAddressDto;
	@Column() public to: EmailAddressDto[];
	@Column() public cc: EmailAddressDto[];
	@Column() public templateName: string;
	@Column() public plainTextMessage: string;
	@Column() public templateContext: IHtmlViewData;
	@Column() public attachments: FileDto[];
}
export class MailPathReferenceQueueDto extends QueueDto
{
	@Column() public filePath: string;
}
export class PushNotificationQueueDto extends QueueDto
{
	@Column() public receiverIdentifier: string;
	@Column() public platform: number;
	@Column() public notification: INotificationDto;
}
