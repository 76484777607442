import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, Subject, throwError } from 'rxjs';
import {catchError, switchMap, take, timeout, timeoutWith} from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { TokenHelper } from '../../../models/client';

const InterceptorRefreshTimeout = 25;

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {

  refreshInProgressSince = null;
  tokenRefreshedSubject = new Subject();

  constructor(
    private authService: AuthenticationService
  ) { }

  private addAuthHeader(request: HttpRequest<any>, keepHeader: boolean = true) {
    if(request.headers.has('Authorization') && keepHeader == true)
      return request;

    let token = this.authService.getIdentity();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token.token}`
      }
    });
  }


  private setRefreshInProgress(active: boolean) {
    if (active) {
      this.refreshInProgressSince = new Date();
    } else {
      this.refreshInProgressSince = null;
    }
  }

  private isRefreshInProgress(): boolean {
    const timeoutOffset = 2;
    const current = this.refreshInProgressSince;

    if (!current) {
      return false;
    }

    const waitingFor = (new Date().getTime() - current.getTime()) / 1000;
    if (waitingFor >= InterceptorRefreshTimeout + timeoutOffset) {
      this.setRefreshInProgress(false);
      return false;
    }

    return true;
  }

  private async refreshToken() {
    if (this.isRefreshInProgress()) {
      const refreshResponse = await this.tokenRefreshedSubject.asObservable().pipe(
          timeoutWith(InterceptorRefreshTimeout * 1000, throwError(new Error('Timeout for waiting for token refresh'))),
          take(1)
      ).toPromise();

      if (refreshResponse !== true) {
        throw refreshResponse;
      }
    } else {
      this.setRefreshInProgress(true);

      try {
        await from(this.authService.loginWithRefreshToken())
            .pipe(timeoutWith(InterceptorRefreshTimeout * 1000, throwError(new Error('Timeout for refreshing token'))))
            .toPromise();

        this.setRefreshInProgress(false);
        this.tokenRefreshedSubject.next(true);

      } catch (refreshError) {
        this.setRefreshInProgress(false);
        this.tokenRefreshedSubject.next(refreshError);
        throw refreshError;
      }

    }
  }

  private isHttpError(error: any) : boolean {
    return error instanceof HttpErrorResponse;
  }

  private isHttpUnauthorizedError(error: any) : boolean {
    return this.isHttpError(error) == true && (<HttpErrorResponse>error).status === 401;
  }

  private isHttpInvalidTokenError(error: any): boolean {
    if(this.isHttpUnauthorizedError(error) && error.error && error.error.length > 0) {
      const errorCode = error.error[0].Code;
      return errorCode === 'InvalidRefreshToken';
    }

    return false;
  }

  private interceptIfNotExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = request.headers.get('Authorization');

    if(!token || TokenHelper.isTokenExpired(token)) {
      return throwError(new HttpErrorResponse({ status: 401 }));
    } else {
      return next.handle(request);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addAuthHeader(request, true);
    
    return this.interceptIfNotExpired(request, next).pipe(catchError(httpError => {
      if (this.isHttpUnauthorizedError(httpError) == true) {

        return from(this.refreshToken())
        .pipe(
          switchMap(() => {
            request = this.addAuthHeader(request, false);
            return next.handle(request);
          }),
          catchError((refreshError) => {
            if(this.isHttpInvalidTokenError(refreshError) == true) {
              this.authService.logout();
            }
            
            return throwError(refreshError);
          })
        );

      } else {
        return throwError(httpError);
      }

    }));
  }
}
