//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';
import { IIdentityData } from '../Objects';

export class MessagePatternDto implements IIdentityData
{
	@Column() public messagePatternId: number;
	@Column() public content: string;
	@Column() public elements: MessagePatternElementDto[];
}
export class MessagePatternElementDto implements IIdentityData
{
	@Column() public messagePatternElementId: number;
	@Column() public position: number;
	@Column() public type: number;
}
export class MessagePatternFieldDto extends MessagePatternElementDto implements IIdentityData
{
	@Column() public localFieldId: string;
	@Column() public fieldId: number;
}
export class MessagePatternConstantDto extends MessagePatternElementDto implements IIdentityData
{
	@Column() public content: string;
}
