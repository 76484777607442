//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import 'reflect-metadata';
const metadataColumnKey = Symbol("Column");
const metadataFilterableColumnKey = Symbol("FilterableColumn");
const metadataDateColumnKey = Symbol("DateColumn");

export function Column(optional : boolean = false): (target: any, propertyKey: string | symbol) => void {
    return function (target: object, propertyKey: string): void {
        let properties = Reflect.getMetadata(metadataColumnKey, target) || [];
        properties[propertyKey] = optional;
        Reflect.defineMetadata(metadataColumnKey, properties, target);
    };
}
export function Filterable(): (target: any, propertyKey: string | symbol) => void {
    return function (target: object, propertyKey: string): void {
        let properties = Reflect.getMetadata(metadataFilterableColumnKey, target) || [];
        properties[propertyKey] = true;
        Reflect.defineMetadata(metadataFilterableColumnKey, properties, target);
    };
}

export function DateColumn(): (target: any, propertyKey: string | symbol) => void {
    return function (target: object, propertyKey: string): void {
        let properties = Reflect.getMetadata(metadataDateColumnKey, target) || [];
        properties[propertyKey] = true;
        Reflect.defineMetadata(metadataDateColumnKey, properties, target);
    };
}

export function getColumns(origin: object): object {
    return  Reflect.getMetadata(metadataColumnKey, origin) || [];
}
export function getFilterableColumns(origin: object): object {
    return  Reflect.getMetadata(metadataFilterableColumnKey, origin) || [];
}
export function getDateColumns(origin: object): object {
    return  Reflect.getMetadata(metadataDateColumnKey, origin) || [];
}

