import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { InfoMember } from '../../models/client/infos/info-member';
import { InfoReadsService } from '../../services/api/info/info-reads.service';
import { ModalService } from '../../services/ui/modal.service';

@Component({
  selector: 'app-info-reads',
  templateUrl: './info-reads.page.html',
  styleUrls: ['./info-reads.page.scss'],
})
export class InfoReadsPage implements OnInit {

  public members: InfoMember[] = null;
  public infoId: number;
  public isLoading: boolean = true;

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
    private infoReadsService: InfoReadsService
  ) { }

  ngOnInit() {
    this.infoId = this.navParams.get('infoId');
    this.load();
  }

  clear() {
  }

  change(event) {
    this.load(event?.detail?.value === 'read');
  }

  async load(reads: boolean = true) {
    this.isLoading = true;

    try {
      if(reads) {
        this.members = await this.infoReadsService.getReads(this.infoId);
      } else {
        this.members = await this.infoReadsService.getUnReads(this.infoId);
      }
      
      this.isLoading = false;
    } catch(error) {
      this.isLoading = false;
      console.error(error);
      (await this.modalService.createInfoToast('ERRORMSGDETAIL', { detail: error.message })).present();
    }
     
  }

  exit() {
    this.clear();
    this.modalService.dismissModal();
  }

  async done() {
   
    this.modalService.dismissModal({
    });
  }
}
