//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../Attributes';

export class PingDto
{
	@Column() public pingId: number;
	@Column() public appVersion: string;
	@Column() public deviceId: number;
	@Column() public hardwareId: string;
	@Column() public tenantId: number;
	@Column() @DateColumn() public sendAt: string;
	@Column() @DateColumn() public arrivedAt: string;
}
export class PingListDto
{
	@Column() @Filterable() public pingId: number;
	@Column() @Filterable() public deviceId: number;
	@Column() @Filterable() @DateColumn() public sendAt: string;
	@Column() @Filterable() @DateColumn() public arrivedAt: string;
	@Column() @Filterable() public appVersion: string;
}
