import { Injectable } from '@angular/core';
import { ChatListDto } from '../../../models/server/DataTransferObject/Objects/Chats';
import { PageableService } from '../base/pageable.service';
import { HttpLocationApiService } from '../http/http-location-api/http-location-api.service';
import { SortingParams } from '../../../models/client/api/sorting-params';
import { HttpParams } from '@angular/common/http';

const SERVICE_ENDPOINT = 'mychat';

@Injectable({
  providedIn: 'root'
})
export class MyChatService extends PageableService<ChatListDto> {
  protected search: string = null;

  constructor(
    protected http: HttpLocationApiService
  ) {
    super(http, SERVICE_ENDPOINT, 50);
  }

  public setSearch(search: string) {
    if (search && search.trim().length > 0) {
      this.endpoint = `${SERVICE_ENDPOINT}/search`;
      this.search = search.trim();
    } else {
      this.endpoint = SERVICE_ENDPOINT;
      this.search = null;
    }
  }
  
  protected createHttpParams(pageNumber: number = null, pageSize: number = null, sortingParams: SortingParams = null): HttpParams {
    let params = super.createHttpParams();

    if (this.search && this.search.trim().length > 0) {
      params = params.set('q', this.search);
    }

    return params;
  }
}
