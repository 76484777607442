import {MissionState} from '../../../server/Enums';
import {FileDto} from '../../../server/DataTransferObject/Objects/Files';
import {SafeHtml} from '@angular/platform-browser';

export class ChangeStateOfMissionDto {
    constructor(
        public missionId: number,
        public wantedState: MissionState,
        public isSendingMail = false,
        public startDate: string = null,
        public endDate: string = null,
    ) { }
}

export class RenderedValue {
    constructor(
        public value: any,
        public isHtml: boolean,
        public file: FileDto = null
    ) { }

    public static ofText(value: string): RenderedValue {
        return new RenderedValue(value, false);
    }

    public static ofImage(html: string, file: FileDto): RenderedValue {
        return new RenderedValue(html, true, file);
    }

    public static ofSafeHtml(safeHtml: SafeHtml): RenderedValue {
        return new RenderedValue(safeHtml, true);
    }
}

