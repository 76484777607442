//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

import { Column, Filterable, DateColumn } from '../../../../Attributes';
import { IHtmlViewData } from '../../../Objects';
import { IIdentityData } from '../../../Objects';
import { FileDto } from '../../Files';
import { ValidationDto } from '../../../Validations';

export class FormValueDto extends ValidationDto implements IHtmlViewData, IIdentityData
{
	@Column() public fieldValueId: number;
	@Column() public type: number;
}
export class CheckboxFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: boolean;
}
export class DateFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() @DateColumn() public value: string;
}
export class DropDownFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
}
export class DropDownFormValueForCompleteDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class InfoFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class MultilineFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class NumberFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
}
export class PictureFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
	@Column() public file: FileDto;
}
export class SignatureFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: number;
	@Column() public file: FileDto;
}
export class TextFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() public value: string;
}
export class TimeFormValueDto extends FormValueDto implements IHtmlViewData, IIdentityData
{
	@Column() @DateColumn() public value: string;
}
